import styled from "styled-components";

export const CatalogueStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  .topBanner h1 {
    font-size: 1rem;
  }
  .topBanner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      background-color: #9747ff;
      border: none;
      color: white;
      padding: 0.7rem;
      border-radius: 5px;
    }
  }
  .catalogue-container {
    padding: 20px;
    background: #fff;
  }

  .catalogue-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .search-bar {
    max-width: 200px;
  }

  .specialization-dropdown {
    width: 200px;
    margin-left: 16px;
  }

  .sort-dropdown {
    width: 120px;
    margin-left: 16px;
  }

  .doctor-name-cell {
    display: flex;
    align-items: center;
  }

  .doctor-avatar {
    width: 40px;
    height: 40px;
    background-color: #eaeaea;
    border-radius: 50%;
    margin-right: 12px;
  }

  .doctor-name {
    font-weight: bold;
    color: #5e7ce0;
  }

  .clear-all {
    margin-left: 16px;
    cursor: pointer;
  }

  .ant-table-thead > tr > th {
    background-color: #fafafa;
    font-weight: bold;
  }

  .ant-table-thead th.ant-table-cell {
    text-align: left;
  }

  .ant-table-tbody > tr > td {
    vertical-align: middle;
  }

  .ant-btn {
    border: 1px solid #d9d9d9;
    background-color: #fafafa;
  }

  .ant-btn-link {
    color: #1890ff;
    padding: 0;
  }

  .ant-input-prefix {
    margin-right: 8px;
  }
`;
