import React, { useState } from "react";
import { Table, Input, Button, AutoComplete, Select, Checkbox } from "antd";
import { FiSearch, FiMoreVertical } from "react-icons/fi";
import { CatalogueStyled } from "./Catalogue.styled";
import "antd/dist/antd.css";

const { Search } = Input;
const { Option } = Select;

const Catalogue = () => {
  const specializationOptions = [
    { label: "General Physician", value: "General Physician" },
    { label: "Pediatrics", value: "Pediatrics" },
    { label: "Opthalmology", value: "Opthalmology" },
    { label: "Urology", value: "Urology" },
    { label: "Neurology", value: "Neurology" },
    { label: "Cardiology", value: "Cardiology" },
    { label: "Dental Surgery", value: "Dental Surgery" },
    { label: "General Surgery", value: "General Surgery" },
    { label: "Psychiatry", value: "Psychiatry" },
    { label: "Orthopedics", value: "Orthopedics" },
    { label: "Anesthesiology", value: "Anesthesiology" },
  ];

  const [filteredSpecializationOptions, setFilteredSpecializationOptions] =
    useState(specializationOptions);

  const handleSearch = (value: any) => {
    setFilteredSpecializationOptions(
      specializationOptions.filter((option) =>
        option.value.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const columns = [
    {
      title: <Checkbox />,
      dataIndex: "checkbox",
      key: "checkbox",
      render: () => <Checkbox />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <div className="doctor-name-cell">
          <img
            src="/path/to/your/image.png"
            alt="Doctor Avatar"
            className="doctor-avatar"
          />
          <div>
            <div className="doctor-name">{record.name}</div>
            <div className="doctor-id">{record.id}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Specialization",
      dataIndex: "specialization",
      key: "specialization",
    },
    {
      title: "Consult Fee",
      dataIndex: "consultFee",
      key: "consultFee",
    },
    {
      title: "Actions",
      key: "actions",
      render: () => <Button icon={<FiMoreVertical />} />,
    },
  ];

  const data = [
    {
      key: "1",
      name: "Dr. Name",
      id: "245dghs6452",
      specialization: "Cardiology",
      consultFee: "₹150",
    },
    {
      key: "2",
      name: "Dr. Name",
      id: "SKU001",
      specialization: "Neurology",
      consultFee: "₹150",
    },
    {
      key: "3",
      name: "Dr. Name",
      id: "SKU001",
      specialization: "General Physician",
      consultFee: "₹150",
    },
  ];

  return (
    <CatalogueStyled>
      <div className="topBanner">
        <h1>Doctors</h1>
        <button>Add Doctor</button>
      </div>
      <div className="catalogue-container">
        <div className="catalogue-header">
          <Search
            placeholder="Search by Name"
            className="search-bar"
            onSearch={handleSearch}
          />
          <AutoComplete
            options={filteredSpecializationOptions}
            placeholder="Specialization"
            className="specialization-dropdown"
            filterOption={(inputValue: any, option: any) =>
              option.value.toLowerCase().includes(inputValue.toLowerCase())
            }
          />
          <Select
            placeholder="Sort By"
            className="sort-dropdown"
            style={{ width: 150 }}
          >
            <Option value="name">Name</Option>
            <Option value="specialization">Specialization</Option>
            <Option value="fee">Consult Fee</Option>
          </Select>
          <span className="clear-all">
            Clear All
          </span>
        </div>
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
    </CatalogueStyled>
  );
};

export default Catalogue;
