import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import axios from "axios"; // Assuming you're using axios for the HTTP request

import {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  SERVER_IP,
} from "./config";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseDb = getFirestore(app);

export const messaging = getMessaging(app);
export { firebaseDb };
export const auth = getAuth();
export default app;

export const generateToken = async () => {
  try {
    const hasFirebaseMessagingSupport = await isSupported();
    console.log("hasFirebaseMessagingSupport : ", hasFirebaseMessagingSupport);
    if (hasFirebaseMessagingSupport) {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const fcmToken = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });

        if (fcmToken) {
          // Retrieve the user object from localStorage and parse it
          const user = JSON.parse(localStorage.getItem("user"));

          // Extract the accessToken from the user object
          const authToken = user?.accessToken;

          if (authToken) {
            await axios.put(
              `${SERVER_IP}/api/v1/chat/fcm`,
              { fcm: fcmToken },
              {
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
              }
            );

            console.log(
              "FCM token and auth token sent to server successfully."
            );
          } else {
            console.error("Auth token not found in localStorage.");
          }
        }
      }
    } else {
      console.error("Firebase Messaging is not supported in this browser.");
      return;
    }
  } catch (error) {
    console.error("Error generating or sending FCM token: ", error);
  }
};
