import styled from "styled-components";

export const TableComponentStyled = styled.div`
  .table-container {
    padding: 20px;
    background-color: #f8f9fa;
  }

  .custom-table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
  }

  .custom-table thead {
    background-color: #e9ecef;
    font-weight: bold;
  }

  .custom-table th,
  .custom-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  .custom-table tr:hover {
    background-color: #f1f1f1;
  }

  .custom-table th {
    color: #495057;
  }

  .input-group {
    /* max-width: 500px; */
    margin-bottom: 20px;
  }

  .input-group .form-control {
    border-radius: 20px;
  }

  .input-group .btn {
    border-radius: 20px;
    margin-left: 10px;
    background-color: #007bff;
    border: none;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
`;
