import styled from "styled-components";

export const RemainderStyled = styled.div`
  .remainder-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f9fafc;
    border-radius: 8px;
  }

  .search-bar {
    display: flex;
    align-items: center;
    padding: 5px;
    width: 210px;
    border-radius: 5px;
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
  }

  .search-bar svg {
    margin-right: 5px;
  }

  .search-bar input {
    border: none;
    outline: none;
    padding: 5px;
    background: transparent;
    color: #ccc;
    font-size: 14px;
    font-weight: 400;
  }

  .clear-all {
    margin-left: 10px;
    color: var(--dummy, #808080);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: transparent;
  }

  .filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 10px 30px;

    border-radius: 5px;
    border: 1px solid #f4e6e6;
    background: var(--white, #fff);
  }

  .select-type {
    width: 150px;

    color: var(--dummy, #808080);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 5px;
    background: var(--white, #fff);
  }

  .select__control {
    border-radius: 8px;
  }

  .send-reminder-button {
    color: var(--white, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 20px;
    font-weight: 500;
    border: none;
    border-radius: 2px;
    background: #9474ff;
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  }
  .rem-detail {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    background-color: #f9fafc;
    border-radius: 8px;
  }

  .patient-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .patient-info {
    display: flex;
    align-items: center;
  }

  .patient-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .info-text h3 {
    margin: 0;
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .info-text p {
    margin: 5px 0;
    color: var(--dummy, #808080);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .actions {
    display: flex;
    gap: 10px;
  }

  .action-button {
    border-radius: 8px;
    padding: 0 15px;
  }
  .action-button.remainder {
    color: #9474ff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 2px !important;
    background: var(--white, #fff) !important;
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1) !important;
    border: none;
    padding: 8px 15px;
  }

  .action-button.whatsapp {
    color: var(--dummy, #808080);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 2px !important;
    background: var(--white, #fff) !important;
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1) !important;
    border: none;
    padding: 8px 15px;
  }

  .action-button.email {
    color: var(--dummy, #808080);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 2px !important;
    background: var(--white, #fff) !important;
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1) !important;
    border: none;
    padding: 8px 15px;
  }

  @media (max-width: 768px) {
    .remainder-search{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 6px;
    }
    .remainder-search button, .select-type{
        margin-top:12px;
    }
    .actions{
        display: grid;
    }
  }
`;
