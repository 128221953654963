import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from './Login.Styled';

const ProgressSummary = () => {
  const history = useHistory();

  const goToProfileDetails = () => {
    history.push("/register"); // Change this to the correct route for the Login component
  };

  const goToIdentityProof = () => {
    history.push("/register/identity-proof"); // Change this to the correct route for the IdentityProof component
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <div className="progress-summary">
              <h2>Great Progress</h2>
              <p>Your profile is only a few steps away from being live!</p>
              <p className="secclass">Section A: Profile Details</p>
              <p>
                Doctor’s basic details, medical registration, education
                qualification, establishment details etc.
              </p>
              <Button onClick={goToProfileDetails} className="changebtn">
                Change
              </Button>
              <hr />
              <p className="secclass">Section B: Profile Verification</p>
              <p>
                Doctor identity proof, registration proof, establishment ownership
                proof etc.
              </p>
              <Button className="continue-button" onClick={goToIdentityProof}>
                Continue
              </Button>
              <hr />
              <p className="secclass">Section C: Start getting patients</p>
              <p className="secclass">Location, Timings, Fees</p>
            </div>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default ProgressSummary;
