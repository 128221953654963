import React, { useState, ChangeEvent } from "react";
import { Modal, Button } from "react-bootstrap";
import './style.css';
import { ReactComponent as CloseButton } from "../../assets/icons/close.svg"; 
import { ReactComponent as Uploadmenuicon } from "../../assets/icons/menuicon.svg"; 
// import { ReactComponent as Rreeentick } from "../../assets/icons/icons8-tick.svg";

interface UploadPrescriptionModalProps {
  show: boolean;
  handleClose: () => void;
}

const UploadPrescriptionModal: React.FC<UploadPrescriptionModalProps> = ({ show, handleClose }) => {
  const [file, setFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files ? e.target.files[0] : null;
    if (uploadedFile) {
      setFile(uploadedFile);
      setFilePreview(URL.createObjectURL(uploadedFile));
    }
  };

  const handleClearFile = () => {
    setFile(null);
    setFilePreview(null);
  };

  return (
    <Modal show={show} dialogClassName="upload-prescription-box">
      <div className="conformation-model">
        <Modal.Body>
          <div className="upload-prescriptionwrap">
            <div className="new-option-header">
              <h4>Upload Prescription</h4>
              <CloseButton onClick={handleClose} />
            </div>
            <div className="upload-pec-main-content">
              <div id="uploadprescriptions">
                <div className="flex items-center justify-center w-full">
                  <label htmlFor="dropzone-file" className="upload-container">
                    <div className="flex flex-col items-center justify-center">
                      <Uploadmenuicon />
                      <p className="mb-2 text-sm text-black opacity-80">
                        <span className="poppins-medium">Drag and Drop file here or </span>
                        <u className="poppins-semibold">Choose file</u>
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      accept="image/*"
                      type="file"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
                {filePreview && (
                  <div className="image-preview">
                    <img src={filePreview} alt="Uploaded Prescription" className="uploaded-image" />
                  </div>
                )}
                <div className="support-text-box-sec">
                  <span className="flex-col">Supported format: PNG, SVG, JPG</span>
                  <span className="flex-col">Maximum size: 20 MB</span>
                </div>
                <div className="remarks-prescription">
                  <span className="poppins-regular">Remarks</span>
                  <textarea
                    name="concerns"
                    id="concerns"
                    className="poppins-regular"
                    cols={10}
                    rows={2}
                  ></textarea>
                </div>
                <div className="flex actions-btn-sec-content">
                  <div className="flex-column">
                    <div className="uploadprescriptions-leftbtns flex_center">
                      <Button className="saved-prescription-btn poppins-medium">
                        Saved Prescription
                      </Button>
                      <Button className="saved-prescription-btn poppins-medium" onClick={handleClearFile}>
                        Clear Prescription
                      </Button>
                    </div>
                  </div>
                  <div className="uploadprescriptions-rightbtns flex_center">
                    <Button
                      id="uploadsubmitbtn"
                      className="submit-prescription-btn poppins-medium button-shadow"
                      type="submit"
                      disabled={!file}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default UploadPrescriptionModal;
