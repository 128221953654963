import React from "react";
import { Modal } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Profile from "../../../assets/images/profile.png";
import logo from "../../../assets/images/logo.webp";
import './style.css';
import { Table } from "antd";

interface PreviewModalProps {
  isVisible: boolean;
  handleCancel: () => void;
}

const dataSource = [
  {
    key: "1",
    no: "01",
    brand: "Dolo",
    dosage: "650 MG",
    type: "Tablet",
    qty: "10",
    frequency: "1-1-1",
    noOfDays: "5",
    intake: "After Food",
    price: "19.00",
  },
];

const columns = [
  {
    title: "Sl No",
    dataIndex: "no",
    key: "no",
  },
  {
    title: "Brand",
    dataIndex: "brand",
    key: "brand",
  },
  {
    title: "Dosage",
    dataIndex: "dosage",
    key: "dosage",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Qty",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "Frequency",
    dataIndex: "frequency",
    key: "frequency",
  },
  {
    title: "No Of Days",
    dataIndex: "noOfDays",
    key: "noOfDays",
  },
  {
    title: "Intake",
    dataIndex: "intake",
    key: "intake",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
];

const PreviewModal: React.FC<PreviewModalProps> = ({ isVisible, handleCancel }) => {
  return (
    <Modal
      show={isVisible}
      onHide={handleCancel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div className="modal-header-content">
          <div className="logo-section">
            <img
              src={logo}
              alt="Logo"
              className="logo-image"
            />
          </div>
          <div className="patient-info-section">
            <div className="patient-details">
              <div className="profile-pic">
                <Image src={Profile} alt="Profile" className="profile-image" />
              </div>
              <div className="details">
                <h2>Alex Peter</h2>
                <p>
                  <span className="det-head">Age: </span> 32 &nbsp;&nbsp; <span className="det-head">Gender:</span> Male &nbsp;&nbsp; <span className="det-head">City:</span> Bangalore
                  &nbsp;&nbsp; <span className="det-head">Ticket no:</span> #4584
                </p>
                <p><span className="det-head">Purpose:</span> Fever &nbsp;&nbsp; <span className="det-head">Next Consultation:</span> 20-08-24</p>
              </div>
            </div>
            <div className="doctor-info-section">
              <p className="doc-name">DR.Prabakaran PP</p>
              <p><span className="doc-head">Date:</span> 27-04-2024</p>
              <p><span className="doc-head">Time:</span> 2:30 PM</p>
              <p>
                Mode:{" "}
                {/* <img
                  src={logo}
                  alt="Home Mode"
                  className="mode-icon"
                /> */}
              </p>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <h2 className="body-head">Medicine Prescribed By Doctor</h2>
        <Table
          dataSource={dataSource}
          columns={columns}
          className="custom-table"
          pagination={false}
        />
        <div className="patient-cart">
            <p>Total : 239.00</p>
            <button>Add To Patient Cart</button>
        </div>

        <h2 className="body-head">Comparative Medicine By Raphacure</h2>
        <Table
          dataSource={dataSource}
          columns={columns}
          className="custom-table"
          pagination={false}
        />
        <div className="patient-cart">
            <p>Total : 239.00</p>
            <button>Add To Patient Cart</button>
        </div>
      
            <div className="address">
                <p>Dr. John Smith General Practitioner</p>
                <p>Clinic Address: Dr. Smith's Health Clinic</p>
                <p>1234, MG Road Near Trinity Circle</p>
                <p>Bangalore - 560001 Karnataka, India</p>
                <p>Contact Information: Phone: (080) 1234-5678</p>
                <p>Email: drjohnsmith@example.com</p>
            </div>
           
        
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={handleCancel}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default PreviewModal;
