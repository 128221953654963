import styled from "styled-components";

export const PatientStyled = styled.div`
  .patient-page {
    padding: 20px;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .left-controls {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .title {
    color: #747070;
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
  }

  .search-bar {
    display: flex;
    align-items: center;
    background-color: transparent;
    border-radius: 3px;
    border: 1px solid #d0d0d0;
    padding: 5px;
    width: 210px;
  }

  .search-bar svg {
    margin-right: 5px;
  }

  .search-bar input {
    border: none;
    outline: none;
    padding: 5px;
    background: transparent;
    color: #ccc;
    font-size: 14px;
    font-weight: 400;
  }

  .add-patient-button {
    padding: 12px 15px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 40px;
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 2px;
    background: #fff;
  }

  .add-patient-button {
    margin-left: auto;
    padding: 12px 20px;
    border-radius: 1px;
    background: var(--Primary, #9747ff);
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
    color: var(--white, #fff);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .custom-dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
    gap: 46px;
    width: 124px;
    height: 40px;
    padding: 9px 15px;
    flex-shrink: 0;
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 2px;
    background: #fff;
    border: none;
  }
  .custom-dropdown .dropdown-menu {
    border: none;
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .button-sort {
    display: flex;
    gap: 18px;
  }

  .patient-table {
    margin-top: 12px;
  }

  .patient-table Table {
    --bs-table-bg: transparent !important;
    border-radius: 5px;
    border: 1px solid #d6cece;
  }
  .patient-table thead {
    border-radius: 5px 5px 0px 0px;
    background: #d3edfc;
  }
  .patient-table th {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
  .patient-table td {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
  .patient-table tr {
    border: 1px solid #d6cece;
  }
  .patient-table .action-report {
    text-align: center;
  }
  .patient-table .action-report svg{
    cursor: pointer !important;
  }

  .patient-table .icon-space {
    margin: 0 6px;
  }
  .btn-link {
    background: none;
    border: none;
    color: #007bff; /* Bootstrap primary color */
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
  }

  .btn-link:hover {
    color: #0056b3; /* Darker shade on hover */
    text-decoration: none;
  }
  .pagination {
    justify-content: center;
  }
  .custom-pagination .page-item.active .page-link {
    background-color: #7c4dff;
    border-color: #7c4dff;
    color: white;
  }

  .custom-pagination .page-link {
    color: black;
  }

  .custom-pagination .page-link:hover {
    background-color: #ccbbfa;
  }

  .add-patient-box-sec {
    .form-label {
      margin-bottom: 2px;
    }
  }

  @media (max-width: 768px) {
    .patient-table .icon-space {
      margin: 0 0;
    }
  }
  @media (max-width: 768px) {
    .controls {
      display: block;
    }
    .left-controls {
      display: block;
    }
    .search-bar {
      width: 100%;
      margin-bottom: 12px;
    }
    .button-sort {
      margin-bottom: 12px;
    }
    .add-patient-button {
      margin: auto;
    }
  }
`;
