import styled from "styled-components";

export const EmployeeStyled = styled.div`
  padding: 0px 20px;
  .employee-add-container {
    background-color: #f9fafb; /* Light background color */
  }
  .custom-table-container {
    margin-top: 20px;
    /* overflow-x: auto;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 0.5rem; */
  }
  .employee-count-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
  }
  .search-bar {
    width: 406px;
    padding: 0;
    font-size: 14px;
    border: none;
    border-radius: 6px;
    background: #fff;
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    color: black;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    min-height: 40px;
    input {
      border: none;
      border-radius: 10px;
      width: 100%;
    }
    input:focus {
      outline: none;
    }
  }

  .custom-table-container thead {
    border-radius: 5px 5px 0px 0px;
    background: #d3edfc;
  }

  .custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f9fafb;
    border-radius: 10px;
    overflow: hidden;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }

  .custom-table th {
    background-color: #e0f0ff;
    text-align: left;
    padding: 12px;
    font-weight: bold;
    color: #333;
  }

  .custom-table td {
    padding: 12px;
    border-top: 1px solid #ddd;
    color: #333;
  }

  .custom-table tr {
    transition: background-color 0.3s ease;
  }

  .custom-table tr:hover {
    background-color: #f1f1f1;
  }

  .custom-table input[type="checkbox"] {
    margin: 0;
    accent-color: #9747ff;
  }

  .custom-button {
    color: #9747ff;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    text-decoration: underline;
  }

  .custom-button:hover {
    color: #5a2dbb;
  }
  .download-btn {
    border: 1px solid #9747ff;
    background: #fff;
    color: #9747ff;
    margin-right: 1rem;
  }
  .pagination {
    display: flex;
    justify-content: center;
  }
`;
