import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP,  } from "../../../config";
import { post, put , get} from "../../../library/Requests/helpers";


export const createBookingAPI = createAsyncThunk(
  "checkout/createBookingAPI",
  async (body: any) => await post(`${SERVER_IP}/api/v1/booking`,body)
);
export const updateOrderDetailsAPI = createAsyncThunk(
  "checkout/updateOrderDetailsAPI",
  async (body: any) => await put(`${SERVER_IP}/api/v1/payment/${body?.id}`,body?.body)
);
 

