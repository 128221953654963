import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Signin from "../pages/Signin";
import MyProfile from "../pages/MyProfile";
import { useLocation } from "react-router-dom";
import PrivacyPolicy from "../pages/policy/PrivacyPolicy/PrivacyPolicy";
import Terms from "../pages/policy/Terms/Terms";
import ReturnRefund from "../pages/policy/ReturnRefund/ReturnRefund";
import ShippingPolicies from "../pages/policy/ShippingPolicies/ShippingPolicies";
import SecurityPolicies from "../pages/policy/SecurityPolicies/SecurityPolicies";
import Disclaimer from "../pages/policy/Disclaimer/Disclaimer";
import CityDialogList from "../pages/CityDialog/CityDialogList";
import AboutUs from "../pages/AboutUs/AboutUs";
import Doctordashboard from "../pages/Doctordashboard";
import ContactUs from "../pages/ContactUs/ContactUs";
import Bookings from "../pages/Bookings/Bookings";
import Reports from "../pages/Reports/Reports";
import Login from "../pages/Login/Login";
import MyPatients from "../pages/MyPatients/Patients";
import Orders from "../pages/Orders/Order";
import CreateOrder from "../pages/Orders/CreateOrder/CreateOrder";
import OrderAgain from "../pages/Orders/Orderagain/OrderAgain";
import Clinic from "../pages/Clinics/Clinic";
import ProfileDetails from "../pages/Login/ProfileDetails";
import Catalogue from "../pages/Catalogue/Catalogue";
import Marketing from "../pages/Marketing/Marketing";
import Remainder from "../pages/Remainder/Remainder";
import Clients from "../pages/Clients/Clients";
import NewOrder from "../pages/Orders/NewOrder/NewOrder";
import Centre from "../pages/Centre/Centre";
import Employee from "../pages/Employee/Employee";
import HRReports from "../pages/HRReports/HRReports";
import ChatRoom from "../pages/ChatRoom/ChatRoom";
import DetailsClintWallet from "../pages/Clients/DetailsClintWallet";
import Prescription from "../pages/HRPrescription/Prescription";

const MainRoutes: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      <Route exact path="/signin" component={Signin} />
      <Route exact path="/myProfile" component={MyProfile} />

      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/return-refund" component={ReturnRefund} />
      <Route exact path="/shipping-policy" component={ShippingPolicies} />
      <Route exact path="/security-policy" component={SecurityPolicies} />
      <Route exact path="/disclaimer" component={Disclaimer} />
      <Route exact path="/citydialoglist" component={CityDialogList} />
      <Route exact path="/aboutus" component={AboutUs} />
      <Route exact path="/contactus" component={ContactUs} />
      <Route exact path="/catalogue" component={Catalogue} />
      <Route exact path="/" component={Doctordashboard} />
      <Route exact path="/bookings" component={Bookings} />
      <Route exact path="/reports" component={HRReports} />
      <Route exact path="/hrprescription" component={Prescription} />
      <Route exact path="/register/" component={ProfileDetails} />
      <Route exact path="/register/:component" component={Login} />
      <Route exact path="/MyClinic" component={Clinic} />
      <Route exact path="/mypatients" component={MyPatients} />
      <Route exact path="/orders" component={Orders} />
      <Route exact path="/orders/create-order" component={NewOrder} />
      <Route exact path="/create-order" component={CreateOrder} />
      <Route exact path="/Orderagain" component={OrderAgain} />
      <Route exact path="/Marketing" component={Marketing} />
      <Route exact path="/Remainder" component={Remainder} />
      <Route exact path="/clients" component={Clients} />
      <Route
        exact
        path="/clients/detailsclintwallet"
        component={DetailsClintWallet}
      />
      <Route exact path="/employee" component={Employee} />
      <Route exact path="/chatroom/:component" component={ChatRoom} />
    </Switch>
  );
};

export default MainRoutes;
