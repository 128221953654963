import styled from "styled-components";

export const ClinicStyled = styled.div`
  .container {
    margin-top: 20px;
  }

  .clinic-card {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border: none;
    margin-bottom: 20px;
    min-height: 300px; /* Ensure all cards have the same height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .add-clinic-card {
    border: 2px dashed #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa; /* Light background for the add card */
    min-height: 300px; /* Ensure height consistency with other cards */
    transition: all 0.3s ease;

    &:hover {
      background-color: #f5f5f5; /* Subtle background color change on hover */
      border-color: #bbb;
    }
  }

  .add-clinic-text {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #555;
  }

  .card-title {
    color: var(--dummy, #808080);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .card-text {
    color: var(--dummy, #808080);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .card-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .time-text {
    color: var(--dummy, #808080);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;
  }

  .btn-link {
    text-decoration: none;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    color: #0eb6ca;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
