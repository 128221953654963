import logo from "../../../assets/images/logo.webp";
import "../styles.css";

const Disclaimer = () => {
  return (
    <div className="p-3">
      <img
        alt=""
        title=""
        src={logo}
        className="p-2 prescription-banner opacity-10 container fixed bgImage"
      />
      <span>
        <h1>DISCLAIMER</h1>
      </span>
      <div className="text-justify sub">
        DISCLAIMER THE URL WWW.RAPHACURE.COM IS OWNED BY THE LEGAL ENTITY ‘CURE
        AND CARE PRIMARY CARE PVT. LTD., INDIA’. THE TERMS AND CONDITIONS OF
        ENGAGEMENT WITH RAPHACURE AS PER THESE GENERAL TERMS AND CONDITIONS WILL
        APPLY IN ALL CASES WHERE YOU REGISTER / AVAIL SERVICES FROM RAPHACURE.
        ONLY SPECIFIC CLAUSE/S WHEN SUPERSEDED BY SPECIFIC CONDITIONS OF
        CONTRACT WITH YOU IN WRITING SHALL HAVE THE EFFECT OF THE OVERRIDING
        CONDITIONS AND FOR THE BALANCE, OUR GENERAL TERMS AND CONDITIONS SHALL
        PREVAIL WHEREVER REFERENCES TO RAPHACURE GENERAL TERMS AND CONDITIONS
        ARE GIVEN.
        <br />
        IF YOU DO NOT AGREE TO BE BOUND BY (OR CANNOT COMPLY WITH) ANY OR ALL OF
        RAPHACURE TERMS AND CONDITIONS, DO NOT CHECK/CLICK THE "I AGREE" BOX, DO
        NOT COMPLETE THE REGISTRATION PROCESS, AND DO NOT ATTEMPT TO ACCESS OR
        USE ANY SERVICES. WHEN YOU REGISTER / ACCESS ANY SERVICES OF RAPHACURE
        YOU ARE BOUND BY ALL OF THE TERMS AND CONDITIONS WHICH ARE INTENDED TO
        BE FULLY EFFECTIVE AND BINDING.
        <br />
        RAPHACURE MAY MODIFY TERMS AND CONDITIONS AT ANY TIME AND SUCH
        MODIFICATIONS SHALL BE EFFECTIVE IMMEDIATELY UPON POSTING THE MODIFIED
        AGREEMENT. YOU AGREE TO REVIEW AGREEMENTS PERIODICALLY TO BE AWARE OF
        SUCH MODIFICATIONS AND YOU ACCESSING THE RAPHACURE WEBSITE OR APP
        CONSTITUTES YOUR ACCEPTANCE OF THE RAPHACURE GENERAL TERMS AND
        CONDITIONS AS IT APPEARS AT THE TIME OF YOUR ACCESS OR USE.
        <br />
        RAPHACURE PROVIDES ONLY PRIMARY HEALTHCARE SERVICES AND DOES NOT TREAT
        MEDICAL EMERGENCIES.
        <br />
        YOU UNDERSTAND AND AGREE THAT USE OF RAPHACURE SERVICES IS ENTIRELY AT
        YOUR OWN RISK AND THAT IN NO EVENT SHALL RAPHACURE BE LIABLE FOR ANY
        DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY,
        PUNITIVE, OR ANY OTHER MONETARY OR OTHER DAMAGES, FEES, FINES,
        PENALTIES, OR LIABILITIES ARISING OUT OF OR RELATING IN ANY WAY TO THIS
        SITE, OR SITES ACCESSED THROUGH THIS SITE, APP, AND / OR CONTENT OR
        INFORMATION PROVIDED HEREIN. <br />
        (HEREINAFTER REFERRED TO AS ‘GENERAL INDEMNITY’ PROTECTION TO RAPHACURE)
        <br />
        INFORMATION PROVIDED ON RAPHACURE WEBSITE / APP SHOULD NOT BE CONSTRUED
        AS MEDICAL ADVICE OR USED FOR TREATMENT PURPOSES AND NO CONTENT IN THE
        WEBSITE / APP INTENDS TO PROVIDE MEDICAL ADVICE, PROFESSIONAL DIAGNOSIS,
        OPINION, TREATMENT TO YOU OR ANY OTHER INDIVIDUAL.
        <br />
        THE INFORMATION CONTAINED IN AND SERVICE DELIVERY BY RAPHACURE VIA THIS
        WEBSITE / APP COULD INCLUDE HYPERTEXT LINKS OR POINTERS TO INFORMATION
        THAT IS CREATED, OWNED AND MANAGED BY EXTERNAL MEDICAL SERVICE PROVIDERS
        (HEREINAFTER CALLED ‘MSPs’) TO ASSIST YOU IN YOUR SERVICES OR ANY
        PROMOTIONAL LINKS. WHEN YOU SELECT A LINK TO AN OUTSIDE WEBSITE, YOU
        AGREE THAT YOU DO SO AT YOUR RISK AND ARE SUBJECT TO THE TERMS AND
        CONDITIONS OF THE OWNERS / SPONSORS OF THAT OUTSIDE WEBSITE. RAPHACURE
        DOES NOT GUARANTEE THE AVAILABILITY OF SUCH LINKED PAGES AT ALL TIMES.
        THE INCLUSION OF ANY LINK DOES NOT IMPLY AFFILIATION, ENDORSEMENT OR
        ADOPTION BY US OF THE LINKED SITE.
        <br />
        YOU AGREE TO RECEIVE REPORTS, DOCUMENTS, NOTIFICATIONS, CONFIRMATIONS,
        ADMINISTRATIVE MESSAGES, COMMUNICATIONS, PHONE CALLS AND MESSAGES FROM
        RAPHACURE OR THE OWNERS / SPONSORS OF EXTERNAL WEBSITES NOTWITHSTANDING
        A DND (DO NOT DISTURB) REGISTRATION YOU MAY HAVE WITH THE PROVIDERS OF
        COMMUNICATION CHANNELS.
        <br />
        YOU AGREE AND GRANT YOUR CONSENT THAT RAPHACURE MAY RECORD ALL
        CONVERSATIONS, AUDIO OR VIDEO CALLS FOR PURPOSES OF MEDICAL RECORDS AND
        QUALITY TRAINING PURPOSES.
        <br />
        RAPHACURE RESERVES THE RIGHT TO WITHDRAW, MODIFY, SUSPEND OR DISCONTINUE
        ANY FUNCTIONALITY OR FEATURE OF ITS WEBSITE OR APP AT ANY TIME WITHOUT
        NOTICE.
        <br />
        THE RAPHACURE WEBSITE OR APP MAY CONTAIN FORWARD LOOKING STATEMENTS THAT
        DO NOT CONSTITUTE PROMISES OR ASSERTIVE STATEMENTS IN ALL CONTEXTS. IT
        IS IN YOUR DISCRETION TO MAKE USE OF THE INFORMATION OR STATEMENTS
        PROVIDED. RAPHACURE MAKES NO REPRESENTATION THAT ALL INFORMATION IS
        ACCURATE, LATEST OR COMPREHENSIVE.
        <br />
        RAPHACURE IS NOT RESPONSIBLE FOR COMPLETE INFORMATION OR FLOW NOT
        APPEARING DUE TO FAULTS IN CERTAIN SOFTWARE, APPLICATIONS OR DEVICES YOU
        MAY BE USING.
        <br />
        DATA FOR SERVICES AVAILABLE ON FREE OF CHARGE ON A TRIAL BASIS MAY BE
        LOST AT THE END OF THE TRIAL PERIOD AND ADDITIONAL TERMS AND CONDITIONS
        MAY APPEAR OR BECOME APPLICABLE.
        <br />
        YOU AGREE THAT IN THE EVENT OF ANY PROBLEM IN USING RAPHACURE WEBSITE OR
        APP, YOUR SOLE REMEDY SHALL BE TO CEASE USING RAPHACURE WEBSITE OR APP.
        <br />
        ALL CONTENTS OF THIS WEBSITE ARE THE PROPERTY OF RAPHACURE. WE GRANT YOU
        PERMISSION TO DOWNLOAD, STORE, PRINT, DISPLAY AND USE THE CONTENT ONLY
        FOR YOUR PERSONAL USE AND NOT FOR ANY COMMERCIAL USE. YOU AGREE NOT TO
        REPRODUCE ANY CONTENT WITHOUT THE WRITTEN CONSENT OF RAPHACURE.
        <br />
        ANY SUGGESTIONS RECEIVED THROUGH ‘CONTACT US’ SHALL BECOME THE PROPERTY
        OF RAPHACURE WITHOUT ANY OBLIGATION AND YOU SHALL NOT BE ENTITLED TO ANY
        CLAIM OR COMPENSATION UNDER ANY CIRCUMSTANCES.
        <br />
        RAPHACURE MAY FOR ANY REASON SUSPEND, MODIFY, DISCONTINUE OR TERMINATE
        SERVICES OR ACCESS TO YOU AT ANY TIME; FOR CAUSE OR CONVENIENCE WITHOUT
        ANY PRIOR NOTICE. YOU AGREE TO THE SAME WITHOUT QUESTION OR DEMUR.
        <br />
        YOU GRANT RAPHACURE THE RIGHT TO ASSIGN THIS CONTRACT TO ITS AFFILIATE
        OR IN CONNECTION WITH A MERGER, ACQUISITION OR SALE WITH AN INTIMATION
        ON THE WEBSITE OR TO YOU.
        <br />
        IN CASE ANY OF THE TERMS AND CONDITIONS BECOME INOPERATIVE OR INVALID OR
        UNENFORCEABLE, THE REMAINING TERMS SHALL REMAIN TO BE EFFECTIVE AND
        VALID. FAILURE OF RAPHACURE TO INSIST ON PERFORMANCE OF ANY ONE OF THE
        TERMS OF THIS AGREEMENT WILL NOT MEAN A WAIVER OF THE SAME TERM AGAIN OR
        ANY WAIVER OF ANY OTHER TERMS OR CONDITIONS.
        <br />
        A PRINTED VERSION OF THIS AGREEMENT AND ANY RELATED NOTICE GIVEN IN
        ELECTRONIC FORM SHALL BE ADMISSIBLE IN ANY JUDICIAL PROCEEDINGS TO THE
        SAME EXTENT AS ANY OTHER AGREEMENT ON PAPER OR PRINTED FORM. BY CLICKING
        ON “I ACCEPT” OR “AGREED” OR ANY SUCH CONNOTATION TO THE ELECTRONIC
        AGREEMENT, YOU ACCEPT THAT YOU DIGITALLY GIVE YOUR COMPLETE AND
        UNEQUIVOCAL CONSENT TO THE TERMS AND CONDITIONS OF RAPHACURE.
        <br />
      </div>
    </div>
  );
};

export default Disclaimer;
