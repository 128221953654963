import React, { useEffect, useState } from "react";
import { MyProfileStyled } from "./MyProfile.styled";
import { BiHomeAlt } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { FaCity } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { IoMdWarning } from "react-icons/io";
import { useDispatch } from "react-redux";
import { getProfileDetails } from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import docImg from "../../assets/images/doctor-male.png";
const MyProfile = () => {
  const dispatch = useDispatch();
  const [docObj, setDocObj] = useState({});
  useEffect(() => {
    const getDoctorProfileInfo = async () => {
      try {
        const response = await dispatch(getProfileDetails());
        const resJson = JSON.parse(JSON.stringify(response));
        setDocObj(resJson?.payload?.data?.doctor);
      } catch (error) {
        console?.error(error);
        toast.error(error);
      }
    };
    getDoctorProfileInfo();
  }, []);

  useEffect(() => {
    console.log("docObj : ", docObj);
  }, [docObj]);

  return (
    <MyProfileStyled>
      <div className="bannnerGradient">
        <div className="editBtn">
          <MdEdit size={20} />
        </div>
      </div>
      <div className="userDiv spacedDiv">
        <div className="imgDiv">
          <img src={docObj?.image || docImg} alt="" />
        </div>
        <h2>{docObj?.name || "NA"}</h2>
        <p>{docObj?.user?.email || "NA"}</p>
        <p>{docObj?.user?.phone || "NA"}</p>
      </div>
      <div className="addrWrapper">
        <div className="addrItem">
          <BiHomeAlt size={23} />
          <div>{docObj?.home || "NA"}</div>
        </div>
        <div className="addrItem">
          <CiLocationOn size={30} />
          <div>{docObj?.location || "NA"}</div>
        </div>
        <div className="addrItem">
          <FaCity size={23} />
          <div>{docObj?.city || "NA"}</div>
        </div>
      </div>
      <div className="otherInfo">
        <div className="medInfo">
          <div>
            <h2>Medical Registration Number </h2>
            <p>{docObj?.registration_number || "NA"}</p>
          </div>
          <div>
            <h2>Medical Council </h2>
            <p>{docObj?.medical_registration_council || "NA"} </p>
          </div>
          <div>
            <h2>Registration Year</h2>
            <p>{docObj?.registration_date || "2024"}</p>
          </div>
        </div>
        <div className="eduInfo">
          <div>
            <h2>Educational Qualification </h2>
            <p style={{ textWrap: "nowrap" }}>
              {docObj?.specialization || "NA"}
            </p>
          </div>
          <div>
            <h2>College </h2>
            <p>{docObj?.highest_education || "NA"} </p>
          </div>
          <div>
            <h2>Completion Year</h2>
            <p>{docObj?.year || "2024"}</p>
          </div>
        </div>
      </div>
      <div className="doctorInfo">
        <div className="userDoctor">
          <img src={docObj?.image || docImg} alt="" />
          <div>
            <h2>Dr {docObj?.name}</h2>
            <p>{docObj?.specialization}</p>
          </div>
        </div>
        <div className="verifyProfile">
          <h2>
            <IoMdWarning color="#F4A93C" size={23} />
            Profile is under Verification
          </h2>
          <p>
            This profile is currently under verification. Our team is reviewing
            the documents you've uploaded. You will be notified once the
            verification process is complete. Thank you for your patience.
          </p>
        </div>
      </div>
    </MyProfileStyled>
  );
};

export default MyProfile;
