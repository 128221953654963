import React, { useEffect, useState } from "react";
import { Select, Radio, Button } from "antd";
import {
  Table,
  Container,
  Row,
  Col,
  Pagination as BootstrapPagination,
} from "react-bootstrap";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { HRReportsStyled } from "./HRReports.styled";
import { useDispatch } from "react-redux";
import { listAllBookigsAPI } from "../../redux/slices/bookingScreen/bookingScreenService";
import PatientDetailModal from "../../components/PatientDetailModal/Patientmodal";
import * as XLSX from "xlsx";

const { Option } = Select;

const HRReports = () => {
  const [selectedPackage, setSelectedPackage] = useState("AHC Packages");
  const [resBookings, setResBookings] = useState([]);
  const [selectedClient, setSelectedClient] = useState("All Clients");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Set number of items per page (e.g., 10)
  const dispatch = useDispatch();

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [lgShow, setLgShow] = useState(false);

  const handleModalOpen = (patient: any) => {
    setSelectedPatient(patient);
    setLgShow(true);
  };

  const handleModalClose = () => {
    setLgShow(false);
    setSelectedPatient(null);
  };

  const handleDownload = () => {
    // Prepare the data for the download
    const exportData =
      selectedPackage === "AHC Packages" ? ahcTableData : subscriptionTableData;

    // Create a new worksheet from the table data
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "HR Reports");

    // Trigger a download
    XLSX.writeFile(workbook, "HR_Reports.xlsx");
  };

  useEffect(() => {
    document.title = "HR Reports - Raphacure";
    return () => {
      document.title = "Raphacure";
    };
  }, []);

  useEffect(() => {
    const listAllBookigs = async () => {
      const bookingsObj = {
        role: "client_employee",
        count: 1000,
        page: 0,
        searchText: "",
      };
      const res = await dispatch(listAllBookigsAPI(bookingsObj));
      const resJson = JSON.parse(JSON.stringify(res));
      setResBookings(resJson.payload.data.bookings);
      console.log("resBookings: ", resJson.payload.data.bookings);
    };
    listAllBookigs();
  }, [dispatch]);

  const handleClientChange = (value: any) => {
    setSelectedClient(value);
    setCurrentPage(1); // Reset to the first page when client changes
  };

  const getUniqueClients = (bookings: any) => {
    const clientNames = bookings
      .map((booking: any) => booking?.user?.client?.name)
      .filter((name: any) => !!name); // Filter out null or undefined client names

    const uniqueClients = Array.from(new Set(clientNames)); // Remove duplicates
    return ["All Clients", ...uniqueClients]; // Add "All Clients" option
  };

  const getFilteredData = () => {
    if (selectedClient === "All Clients") {
      return resBookings; // Return all bookings if "All Clients" is selected
    }
    return resBookings.filter(
      (booking: any) => booking?.user?.client?.name === selectedClient
    );
  };

  const filteredData = getFilteredData();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTableData = filteredData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const subscriptionTableData = currentTableData.map(
    (booking: any, index: any) => ({
      slNo: (indexOfFirstItem + index + 1).toString().padStart(2, "0"),
      employeeId: `#${booking.user.id || ""}`,
      sex: booking.user.gender || "",
      name: booking.user.first_name || "",
      age: booking.user.age || "",
      number: booking.user.phone || "",
      emailId: booking.user.email || "",
      scheduleDate: booking.collection_1_date
        ? new Date(parseInt(booking.collection_1_date)).toLocaleDateString()
        : "",
      status: booking.status || "",
      report: "View",
      walletType: booking.package?.service_name || "",
      communicationReports: "View",
      walletUsage: booking.final_amount?.toFixed(2) || "0.00",
      walletBalance: (booking.final_amount - 100).toFixed(2), // Just a mock calculation
      billingReports: "View",
    })
  );

  const ahcTableData = currentTableData.map((booking: any, index: any) => ({
    slNo: (indexOfFirstItem + index + 1).toString().padStart(2, "0"),
    employeeId: `#${booking.user.id || ""}`,
    sex: booking.user.gender || "",
    name: booking.user.first_name || "",
    age: booking.user.age || "",
    number: booking.user.phone || "",
    emailId: booking.user.email || "",
    scheduleDate: booking.collection_1_date
      ? new Date(parseInt(booking.collection_1_date)).toLocaleDateString()
      : "",
    status: booking.status || "",
    report: "View",
    walletType: booking.package?.service_name || "",
    communicationReports: "View",
  }));

  const totalMembers = filteredData.length;
  const maleMembers = filteredData.filter(
    (booking: any) => booking.user.gender === "male"
  ).length;
  const femaleMembers = filteredData.filter(
    (booking: any) => booking.user.gender === "female"
  ).length;

  // Change page handler
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Pagination component
  const totalPages = Math.ceil(totalMembers / itemsPerPage);

  // Generate pagination items
  const getPaginationItems = () => {
    const items = [];
    const range = 2; // Number of page numbers to show before and after the current page

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        items.push(i);
      }
    } else {
      items.push(1); // First page

      if (currentPage > range + 1) {
        items.push("...");
      }

      for (
        let i = Math.max(currentPage - range, 2);
        i <= Math.min(currentPage + range, totalPages - 1);
        i++
      ) {
        items.push(i);
      }

      if (currentPage < totalPages - range) {
        items.push("...");
      }

      items.push(totalPages); // Last page
    }

    return items;
  };

  return (
    <HRReportsStyled>
      <Container fluid className="p-3">
        <h2 className="sectionHeading">Reports</h2>
        <Row className="mb-4">
          <Col md={6}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a Client"
              optionFilterProp="children"
              value={selectedClient}
              onChange={handleClientChange}
              filterOption={(input: any, option: any) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
            >
              {getUniqueClients(resBookings).map(
                (clientName: any, index: any) => (
                  <Option key={index} value={clientName}>
                    {clientName}
                  </Option>
                )
              )}
            </Select>
          </Col>
          <Col md={6}>
            <Radio.Group
              defaultValue="AHC Packages"
              buttonStyle="solid"
              onChange={(e) => setSelectedPackage(e.target.value)}
            >
              <Radio value="AHC Packages">AHC Packages</Radio>
              <Radio value="Subscription Packages">Subscription Packages</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={6}>
            <div className="d-flex justify-content-between">
              <span>Total Members: {totalMembers}</span>
              <span>Male Members: {maleMembers}</span>
              <span>Female Members: {femaleMembers}</span>
            </div>
          </Col>
          <Col md={6} className="text-end">
            <Button type="primary" onClick={handleDownload}>
              Download
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ordertable">
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Sl No</th>
                    <th>Employee Id</th>
                    <th>Sex</th>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Number</th>
                    <th>Email id</th>
                    <th>Schedule Date</th>
                    <th>Status</th>
                    <th>Report</th>
                    <th>Wallet Type</th>
                    <th>Communication reports</th>
                    {selectedPackage === "Subscription Packages" && (
                      <>
                        <th>Wallet Usage</th>
                        <th>Wallet Balance</th>
                        <th>Billing reports</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {(selectedPackage === "AHC Packages"
                    ? ahcTableData
                    : subscriptionTableData
                  ).map((row: any, index: any) => (
                    <tr key={index}>
                      <td>{row.slNo}</td>
                      <td
                        style={{ color: "purple", cursor: "pointer" }}
                        onClick={() => handleModalOpen(row)}
                      >
                        {row.employeeId}
                      </td>
                      <td>{row.sex}</td>
                      <td
                        style={{ color: "purple", cursor: "pointer" }}
                        onClick={() => handleModalOpen(row)}
                      >
                        {row.name}
                      </td>
                      <td>{row.age}</td>
                      <td>{row.number}</td>
                      <td>{row.emailId}</td>
                      <td>{row.scheduleDate}</td>
                      <td>{row.status}</td>
                      <td style={{ color: "purple", cursor: "pointer" }}>
                        {row.report}
                      </td>
                      <td>{row.walletType}</td>
                      <td style={{ color: "purple", cursor: "pointer" }}>
                        {row.communicationReports}
                      </td>
                      {selectedPackage === "Subscription Packages" && (
                        <>
                          <td>{row.walletUsage}</td>
                          <td>{row.walletBalance}</td>
                          <td style={{ color: "purple", cursor: "pointer" }}>
                            {row.billingReports}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <BootstrapPagination>
                <BootstrapPagination.First
                  onClick={() => setCurrentPage(1)}
                  disabled={currentPage === 1}
                />
                <BootstrapPagination.Prev
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentPage === 1}
                />
                {getPaginationItems().map((item: any, index) =>
                  item === "..." ? (
                    <BootstrapPagination.Ellipsis key={index} />
                  ) : (
                    <BootstrapPagination.Item
                      key={item}
                      active={item === currentPage}
                      onClick={() => setCurrentPage(item)}
                    >
                      {item}
                    </BootstrapPagination.Item>
                  )
                )}
                <BootstrapPagination.Next
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  disabled={currentPage === totalPages}
                />
                <BootstrapPagination.Last
                  onClick={() => setCurrentPage(totalPages)}
                  disabled={currentPage === totalPages}
                />
              </BootstrapPagination>
            </div>
          </Col>
        </Row>
      </Container>
      <PatientDetailModal
        show={lgShow}
        onHide={handleModalClose}
        patient={selectedPatient}
      />
    </HRReportsStyled>
  );
};

export default HRReports;
