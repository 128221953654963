import styled from "styled-components";

export const LoginCompStyled = styled.div`
  .login-container {
    display: flex;
    align-items: center;
    height: 100vh;
  }

  .login-box {
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
  }

  .login-box h2 {
    margin-bottom: 20px;
    color: #fff;
    text-align: left;
  }

  .login-box form {
    margin-bottom: 20px;
  }

  .login-box label {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    color: #fff;
  }

  .login-box input[type] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .otp-text {
    color: #fff;
    text-align: left;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .login-box button[type="button"] {
    width: 100%;
    padding: 10px;
    background-color: #f2f2f2;
    border: none;
    border-radius: 5px;
    color: #4f4f4f;
    cursor: pointer;
    font-size: 16px;
  }

  .login-box button[type="button"]:disabled {
    background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3));
  }

  .signup-text {
    margin-top: 20px;
    text-align: left;
  }

  .signup-text p {
    color: #fff;
  }

  .signup-text a {
    color: #ffa500;
    text-decoration: none;
  }

  .or-divider {
    margin: 20px 0;
    color: #4f4f4f;
    font-size: 14px;
    position: relative;
  }

  .or-divider span {
    background-color: white;
    padding: 0 10px;
    position: relative;
    z-index: 2;
  }

  .or-divider::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ccc;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
  }

  .social-login {
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 1rem;
    @media (max-width: 760px) {
      flex-direction: column;
    }
  }

  .social-login button {
    padding: 10px;
    border: none;
    color: white;
    background: #5b38c5;
    border: 1px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  /* Chrome, Safari, Edge, Opera */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .proceed-btn {
    margin-top: 24px;
    button {
      border-radius: 10px;
      background: #7b6be6;
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: none;
      padding: 0.5rem;
      cursor: pointer;
    }
    button:disabled {
      border-radius: 10px;
      background: #d9d9d9;
    }
  }
`;
