import React, { useState } from "react";
import { Table, Button } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import PatientDetailModal from "../../components/PatientDetailModal/Patientmodal";
import Pagination from "react-bootstrap/Pagination";
import "./History.css";
import { ReactComponent as Man } from "../../assets/icons/man.svg";

interface DataType {
  key: string;
  slNo: string;
  date: string;
  name: string;
  ticketNo: string;
  message: string;
  source: string;
  purpose: string;
}

const History: React.FC = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<DataType | null>(null);

  const handleNameClick = (record: DataType) => {
    setSelectedPatient(record);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedPatient(null);
  };

  const handleViewClick = (record: DataType) => {
    setSelectedPatient(record);
    setSidebarVisible(true);
  };

  const handleSidebarClose = () => {
    setSidebarVisible(false);
    setSelectedPatient(null);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Sl No",
      dataIndex: "slNo",
      key: "slNo",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: DataType) => (
        <button
          style={{ color: "purple", cursor: "pointer" }}
          onClick={() => handleNameClick(record)}
          className="btn-link"
        >
          {text}
        </button>
      ),
      align: "center",
    },
    {
      title: "Ticket No",
      dataIndex: "ticketNo",
      key: "ticketNo",
      align: "center",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      align: "center",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      align: "center",
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
      align: "center",
    },
    {
      title: "Chat",
      dataIndex: "chat",
      key: "chat",
      render: (_, record) => (
        <Button
          type="primary"
          icon={<CommentOutlined />}
          onClick={() => handleViewClick(record)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          View
        </Button>
      ),
      align: "center",
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      slNo: "01",
      date: "20-08-2024",
      name: "Mallika Sukumaran",
      ticketNo: "#12277885",
      message: "I am Fine Thanks... 😊",
      source: "Instagram",
      purpose: "Fever & Cough",
    },
    {
      key: "2",
      slNo: "02",
      date: "20-08-2024",
      name: "Mallika Sukumaran",
      ticketNo: "#12277885",
      message: "I am Fine Thanks... 😊",
      source: "Whatsapp",
      purpose: "Fever & Cough",
    },
    {
      key: "3",
      slNo: "03",
      date: "20-08-2024",
      name: "Mallika Sukumaran",
      ticketNo: "#12277885",
      message: "I am Fine Thanks... 😊",
      source: "Whatsapp",
      purpose: "Fever & Cough",
    },
    {
      key: "4",
      slNo: "04",
      date: "20-08-2024",
      name: "Mallika Sukumaran",
      ticketNo: "#12277885",
      message: "I am Fine Thanks... 😊",
      source: "Whatsapp",
      purpose: "Fever & Cough",
    },
    {
      key: "5",
      slNo: "05",
      date: "20-08-2024",
      name: "Mallika Sukumaran",
      ticketNo: "#12277885",
      message: "I am Fine Thanks... 😊",
      source: "Whatsapp",
      purpose: "Fever & Cough",
    },
    {
      key: "6",
      slNo: "06",
      date: "20-08-2024",
      name: "Mallika Sukumaran",
      ticketNo: "#12277885",
      message: "I am Fine Thanks... 😊",
      source: "Whatsapp",
      purpose: "Fever & Cough",
    },
  ];

  return (
    <div className="history-sec">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: "max-content" }} // Makes the table horizontally scrollable
      />

      <div className="pagination">
        <Pagination className="custom-pagination">
          <Pagination.First />
          <Pagination.Prev />
          <Pagination.Item active>{1}</Pagination.Item>

          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Item>{3}</Pagination.Item>

          <Pagination.Item>{4}</Pagination.Item>
          <Pagination.Next />
          <Pagination.Last />
        </Pagination>
      </div>

      {selectedPatient && (
        <PatientDetailModal
          show={modalVisible}
          onHide={handleModalClose}
          patient={selectedPatient}
        />
      )}

      {sidebarVisible && (
        <div className={`custom-sidebar ${sidebarVisible ? "open" : ""}`}>
          <div className="sidebar-header">
            <h3>
              <span>
                <Man />
              </span>
              {selectedPatient?.name}
            </h3>
            <button className="close-btn" onClick={handleSidebarClose}>
              &times;
            </button>
          </div>
          <div className="sidebar-content">
            <p>Chat details for {selectedPatient?.name}</p>
            {/* Add more content here */}
          </div>
        </div>
      )}

      {sidebarVisible && (
        <div className="sidebar-overlay" onClick={handleSidebarClose}></div>
      )}
    </div>
  );
};

export default History;
