// MedicalRegistration.js
import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from "./Login.Styled";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorInfoAPI } from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";

const MedicalRegistration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [docLinkableId, setDocLinkableId] = useState<any>();

  const [registrationDetails, setRegistrationDetails] = useState({
    registrationNumber: "",
    registrationCouncil: "",
    registrationYear: "",
  });

  const [errorData, setErrorData] = useState({
    registrationNumber: "",
    registrationCouncil: "",
    registrationYear: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setRegistrationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (value.trim() !== "") {
      setErrorData((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const errors = {} as any;

    if (!registrationDetails.registrationNumber.trim()) {
      errors.registrationNumber = "Please enter your Registration Number";
    }
    if (!registrationDetails.registrationCouncil.trim()) {
      errors.registrationCouncil = "Please select Registration Council";
    }
    if (!registrationDetails.registrationYear.trim()) {
      errors.registrationYear = "Please select Registration Year";
    }

    setErrorData(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validateForm()) {
      history.push("/register/education-qualification");
    }
  };

  useEffect(() => {
    const getDocInfo = async () => {
      if (!user.phone) {
        toast.error("Please save Your Phone Number from dashboard");
        return;
      }
      const res = await dispatch(getDoctorInfoAPI(user.phone));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("resJson : ", resJson);
      if (!resJson?.payload?.data?.doctorDetails?.id) {
        toast.error("Linkable Id not found");
      }
      setDocLinkableId(resJson?.payload?.data?.doctorDetails?.id);
    };
    getDocInfo();
  }, []);

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Medical Registration</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="registrationNumber">
                <Form.Label className="form-label">
                  Registration Number
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Type registration number"
                  name="registrationNumber"
                  value={registrationDetails.registrationNumber}
                  onChange={handleInputChange}
                  isInvalid={!!errorData.registrationNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errorData.registrationNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="registrationCouncil">
                <Form.Label className="form-label">
                  Registration Council
                </Form.Label>
                <Form.Control
                  as="select"
                  name="registrationCouncil"
                  value={registrationDetails.registrationCouncil}
                  onChange={handleInputChange}
                  isInvalid={!!errorData.registrationCouncil}
                >
                  <option value="">Type & select registration council</option>
                  <option value="Council 1">Council 1</option>
                  <option value="Council 2">Council 2</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errorData.registrationCouncil}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="registrationYear">
                <Form.Label className="form-label">
                  Registration Year
                </Form.Label>
                <Form.Control
                  as="select"
                  name="registrationYear"
                  value={registrationDetails.registrationYear}
                  onChange={handleInputChange}
                  isInvalid={!!errorData.registrationYear}
                >
                  <option value="">Type registration year</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errorData.registrationYear}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="button-container">
                <Button
                  className="back-button"
                  onClick={() => history.push("/register")}
                >
                  <BackIcon /> Back
                </Button>
                <Button className="continue-button" type="submit">
                  Next
                </Button>
              </div>
            </Form>
            <div className="info-box">
              This information enables us to conduct essential checks, ensuring
              only licensed and genuine medical practitioners are listed on
              RaphaCure. Upon verification, your profile will receive a
              “Verified” badge, which leads to 95% more patient views.
            </div>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default MedicalRegistration;
