import React from "react";
import { PatientStyled } from "./Patients.Styled";
import SearchPatient from "./SearchPatient";
import PatientsTable from "./PatientsTable";

const MyPatients = () => {
  return (
    <PatientStyled>
      <div className="patient-page">
        <SearchPatient />
        <PatientsTable />
      </div>
    </PatientStyled>
  );
};

export default MyPatients;
