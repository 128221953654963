import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from './Login.Styled';
import { useHistory } from 'react-router-dom';

const MedRegisterProof: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [errorData, setErrorData] = useState<string>('');
  const history = useHistory();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      if (selectedFile.size > 25 * 1024 * 1024) { // 25 MB
        setErrorData('File size exceeds 25 MB');
        setFile(null);
      } else if (!['image/png', 'image/jpeg', 'image/svg+xml'].includes(selectedFile.type)) {
        setErrorData('Invalid file format. Only PNG, JPG, and SVG are allowed.');
        setFile(null);
      } else {
        setFile(selectedFile);
        setErrorData('');
      }
    }
  };

  const validateForm = () => {
    if (!file) {
      setErrorData("Please upload your medical registration proof.");
      return false;
    }
    return true;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      history.push('/register/establishment-proof'); // Ensure this matches the route path
    }
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Medical Registration Proof</h2>
            <p>Dr. Jerome Bell</p>
            <Form onSubmit={handleSubmit}>
              <div className="photo">
                <div className="frontphoto">
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg, .svg"
                    onChange={handleFileChange}
                    className="updbtn"
                  />
                </div>
                {errorData && (
                  <Form.Control.Feedback type="invalid">
                    {errorData}
                  </Form.Control.Feedback>
                )}
                <div className="photodet">
                  <p>Supported format: PNG, SVG, JPG</p>
                  <p>Maximum size: 25 MB</p>
                </div>
                <div className="paragraph-text">
                  <p>
                    Please upload your medical registration proof. Only licensed and
                    genuine doctors are listed on RaphaCure.
                  </p>
                </div>
              </div>
              <div className="button-container">
                <Button
                  className="back-button"
                  onClick={() => history.push("/register/identity-proof")} // Ensure this matches the route path
                >
                  <BackIcon /> Back
                </Button>
                <Button type="submit" className="continue-button">
                  Next
                </Button>
              </div>
            </Form>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default MedRegisterProof;
