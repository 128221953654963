import React from "react";

const well = [
  {
    name:"Ann Mariya",
  },
  {
    name:"Ann Mariya",
  },
  {
    name:"Ann Mariya",
  },
  {
    name:"Ann Mariya",
  },
];
const Wellwishing = () => {
  return (
    <div className="followup">
      {well.map((item, index) => (
      <div className="message">
        <p>Hi {item.name},</p>
        <p>
          Wishing you a happy and healthy holiday season! May this time be
          filled with joy, relaxation, and good health. If you need any
          assistance, don't hesitate to reach out to us.
        </p>
        <p>Warm regards,</p>
        <div className="check">
        <p>Dr. Syam CV and the Apolo Clinic Team</p>
        <input type="checkbox" />
      </div>
      </div>
       ))}
    </div>
  );
};

export default Wellwishing;
