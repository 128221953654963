import React, { useState } from "react";
import { ReactComponent as Searchbar } from "../../../assets/icons/search.svg";

const allMedicines = [
  {
    name: "Bbc Multi Specialty Hospital",
    pieces: "Hsr Layout 5th cross Road",
    imageUrl: "path-to-placeholder-image.jpg",
  },
  {
    name: "Bbc Multi Specialty Hospital",
    pieces: "Hsr Layout 5th cross Road",
    imageUrl: "path-to-placeholder-image.jpg",
  },
  {
    name: "Bbc Multi Specialty Hospital",
    pieces: "Hsr Layout 5th cross Road",
    imageUrl: "path-to-placeholder-image.jpg",
  },
  {
    name: "Bbc Multi Specialty Hospital",
    pieces: "Hsr Layout 5th cross Road",
    imageUrl: "path-to-placeholder-image.jpg",
  },
  {
    name: "Bbc Multi Specialty Hospital",
    pieces: "Hsr Layout 5th cross Road",
    imageUrl: "path-to-placeholder-image.jpg",
  },
  {
    name: "Bbc Multi Specialty Hospital",
    pieces: "Hsr Layout 5th cross Road",
    imageUrl: "path-to-placeholder-image.jpg",
  },
  {
    name: "Bbc Multi Specialty Hospital",
    pieces: "Hsr Layout 5th cross Road",
    imageUrl: "path-to-placeholder-image.jpg",
  },
  {
    name: "Bbc Multi Specialty Hospital",
    pieces: "Hsr Layout 5th cross Road",
    imageUrl: "path-to-placeholder-image.jpg",
  },
  {
    name: "Bbc Multi Specialty Hospital",
    pieces: "Hsr Layout 5th cross Road",
    imageUrl: "path-to-placeholder-image.jpg",
  },
];

const BloodBankDetail = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const filteredMedicines = allMedicines.filter((medicine) =>
    medicine.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="pharmacy-det">
      <div className="search-bar-categories">
        <div className="search-barcat">
          <div className="input-container">
            <span className="search-icon">
              <Searchbar />
            </span>
            <input
              type="text"
              placeholder="Search for Blood Bank"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      <div className="medicine-grid">
        {filteredMedicines.map((medicine, index) => (
          <div key={index} className="medicine-card">
            <img src={medicine.imageUrl} alt={medicine.name} />
            <h3>{medicine.name}</h3>
            <p>{medicine.pieces}</p>

            <button className="Add-btn">Add</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BloodBankDetail;
