import React from "react";
import ComingSoonImg from "../../assets/comingsoon.png";
import { ComingSoonStyled } from "./ComingSoon.styled";

const ComingSoon = () => {
  return (
    <ComingSoonStyled>
      <div className="coming-soon-container">
        <img src={ComingSoonImg} className="coming-soon-image" />
      </div>
    </ComingSoonStyled>
  );
};

export default ComingSoon;
