import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Form,
  Button,
  InputGroup,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
import { FaMicrophone } from "react-icons/fa";
import { BsSendFill } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import { CRHomeStyled } from "./CRHome.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  getFirebaseTokenAPI,
  getMessagesGroupsAPI,
} from "../../../redux/slices/chatroom/chatRoomService";
import chatRoomImg from "../../../assets/chatroom/live-chat-support-png.png";
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";
import {
  collection,
  getDocs,
  addDoc,
  orderBy,
  limit,
  startAfter,
  query,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
} from "firebase/firestore";
import { isSupported, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import { firebaseDb, generateToken, messaging  } from "../../../firebase";
import { getCurrentTimestamp } from "../../../utils";

interface Chat {
  id: string;
  document_id: string;
  name: string;
  last_message?: string;
  participants: Participant[];
}

interface Participant {
  id: number;
  first_name: string;
  last_name: string;
}

interface Message {
  id: string;
  text: string;
  user_id: string;
  timestamp: any;
}

const CRHome: React.FC = () => {
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [docId, setDocId] = useState<string | undefined>(undefined);
  const [showLoginModal, setShowLoginModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [newMessageText, setNewMessageText] = useState("");
  const [messagesRef, setMessagesRef] = useState<any>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [loadingOlderMessages, setLoadingOlderMessages] = useState(false);
  const [lastVisible, setLastVisible] =
    useState<DocumentSnapshot<DocumentData> | null>(null);
  const { internalChats, firebaseToken } = useSelector(
    (state: any) => state.chatroom
  );
  const dispatch = useDispatch();
  const auth = getAuth();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    handleLogin(firebaseToken);
  }, []);

  useEffect(() => {
    console.log("internalChats : ", internalChats);
  }, [internalChats]);

  function getPrefixes(text: string) {
    const prefixes = new Set<string>();
    text.split(" ").forEach((word) => {
      for (let i = 1; i <= word.length; i++) {
        if (i > 2) {
          prefixes.add(word.substring(0, i).toLowerCase());
        }
      }
    });
    return Array.from(prefixes);
  }

  useEffect(() => {
    const storedToken = localStorage.getItem("firebaseToken");
    if (storedToken) {
      handleLogin(storedToken);
    } else {
      getFirebaseToken();
    }
  }, []);

  const handleLogin = async (token: string) => {
    setLoading(true);
    try {
      await signInWithCustomToken(auth, token);
      localStorage.setItem("firebaseToken", token);
      setShowLoginModal(false);
      await getChatRooms();
      setError(null);
    } catch (error) {
      setError("Login failed. Attempting to refresh token...");
      console.error("Error signing in with custom token: ", error);
      const hasFirebaseMessagingSupport = await isSupported();
      if (hasFirebaseMessagingSupport) {
        await dispatch(getFirebaseTokenAPI());
      } else {
        setError("Firebase Messaging is not supported in this browser.");
        console.error("Firebase Messaging is not supported in this browser.");
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  const getChatRooms = async () => {
    try {
      await dispatch(getMessagesGroupsAPI());
    } catch (err) {
      setError("Failed to load chat rooms. Please try again later.");
    }
  };

  const getFirebaseToken = async () => {
    try {
      const hasFirebaseMessagingSupport = await isSupported();

      if (hasFirebaseMessagingSupport) {
        await dispatch(getFirebaseTokenAPI());
      } else {
        setError("Firebase Messaging is not supported in this browser.");
        console.error("Firebase Messaging is not supported in this browser.");
        return;
      }
    } catch (err) {
      setError("Failed to get Firebase Token. Please try again later.");
    }
  };

  useEffect(() => {
    if (internalChats?.length > 0) {
      setSelectedChat(internalChats[0]);
      setDocId(internalChats[0].document_id);
    }
  }, [internalChats]);

  useEffect(() => {
    if (docId) {
      fetchMessages(docId);
    }
  }, [docId]);

  const fetchMessages = async (documentId: string) => {
    const messagesReference = collection(
      firebaseDb,
      `messages/${documentId}/chats`
    );
    setMessagesRef(messagesReference);
    try {
      const q = query(
        messagesReference,
        orderBy("timestamp", "desc"),
        limit(20)
      );
      const querySnapshot = await getDocs(q);
      const fetchedMessages: Message[] = [];
      querySnapshot.forEach((doc) => {
        fetchedMessages.push({ id: doc.id, ...doc.data() } as Message);
      });
      console.log("fetchedMessages : ", fetchedMessages);
      setMessages(fetchedMessages.reverse());
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      // scrollToBottom();
    } catch (error) {
      console.error("Error fetching messages: ", error);
    }
  };

  useEffect(() => {
    const initialiseFirebaseFunctions = async () => {
      const hasFirebaseMessagingSupport = await isSupported();
      console.log(
        "hasFirebaseMessagingSupport : ",
        hasFirebaseMessagingSupport
      );

      if (hasFirebaseMessagingSupport) {
        generateToken();
        onMessage(messaging, (payload) => {
          console.log(payload);
          if (payload?.notification?.body) {
            toast.success(payload.notification.body);
          }
        });
      } else {
        console.error("Firebase Messaging not supported in this browser");
        return;
      }
    };
    initialiseFirebaseFunctions();
  }, [messages]);

  const loadOlderMessages = async () => {
    if (loadingOlderMessages || !lastVisible) return;
    setLoadingOlderMessages(true);
    const messagesReference = collection(firebaseDb, `messages/${docId}/chats`);
    const q = query(
      messagesReference,
      orderBy("timestamp", "desc"),
      startAfter(lastVisible),
      limit(20)
    );
    try {
      const querySnapshot = await getDocs(q);
      const olderMessages: Message[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Message[];
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setMessages((prevMessages) => [
        ...olderMessages.reverse(),
        ...prevMessages,
      ]);
      setLoadingOlderMessages(false);
    } catch (error) {
      console.error("Error loading older messages: ", error);
      setLoadingOlderMessages(false);
    }
  };

  const handleChatSelection = (chat: Chat) => {
    setSelectedChat(chat);
    setDocId(chat.document_id);
  };

  const handleSendMessage = async () => {
    if (newMessageText.trim() === "") return;

    try {
      await addDoc(messagesRef, {
        prefixes: getPrefixes(newMessageText),
        text: newMessageText,
        user_id: auth.currentUser?.uid,
        timestamp: getCurrentTimestamp(),
      });
      setNewMessageText("");
      fetchMessages(docId as any); // Fetch messages again to update the UI
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScroll = useCallback(() => {
    if (messagesContainerRef.current) {
      if (messagesContainerRef.current.scrollTop === 0) {
        loadOlderMessages();
      }
    }
  }, [loadOlderMessages]);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.removeEventListener(
          "scroll",
          handleScroll
        );
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    // scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (firebaseToken) {
      handleLogin(firebaseToken);
    }
  }, [firebaseToken]);

  const getUserName = (userId: string) => {
    const participant = selectedChat?.participants.find(
      (p) => p.id.toString() === userId
    );
    return participant
      ? `${participant.first_name} ${participant.last_name}`
      : "Unknown User";
  };

  const formatDate = (timestamp: any) => {
    const date = new Date(timestamp?.seconds * 1000);
    return date.toLocaleString();
  };

  return (
    <CRHomeStyled>
      <h2 className="sectionHeading">ChatRoom</h2>
      {loading && (
        <div className="loader-container">
          <Spinner animation="border" />
        </div>
      )}
      {/* <Modal show={showLoginModal} centered>
        <Modal.Body className="text-center">
          <div>Attempting Login</div>
          {error && <Alert variant="danger">{error}</Alert>}
        </Modal.Body>
      </Modal> */}
      {!showLoginModal && (
        <Container fluid className="p-4">
          <Row className="chat-wrap">
            <Col md={4} className="border-end">
              {/* <Form.Control type="text" placeholder="Search" className="mb-3" /> */}
              {error && <Alert variant="danger">{error}</Alert>}
              <ListGroup variant="flush" className="chat-div">
                {internalChats?.map((chat: Chat) => (
                  <ListGroup.Item
                    key={chat.id}
                    action
                    onClick={() => handleChatSelection(chat)}
                    active={chat === selectedChat}
                  >
                    <div className="d-flex align-items-center chat-img-div ">
                      <img
                        src={chatRoomImg}
                        alt={chat.name}
                        className="rounded-circle me-2 userImg"
                      />
                      <div>
                        <div>{chat.name}</div>
                        <div
                          className="text-muted"
                          style={{ fontSize: "small" }}
                        >
                          {chat.last_message
                            ? chat.last_message
                            : messages.length > 0
                            ? `${messages.length} Messages`
                            : "No Messages Yet"}
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
            <Col md={8} className="d-flex flex-column chatContainer">
              {selectedChat ? (
                <div
                  className="flex-grow-1 overflow-auto"
                  ref={messagesContainerRef}
                >
                  <div className="border-bottom mb-3 fixedHeader">
                    <h5>{selectedChat.name}</h5>
                  </div>
                  <div className="mb-3 bubbleWrapper">
                    {messages.map((message, idx) => (
                      <div className="chatBubble" key={idx}>
                        <div
                          key={message.id}
                          className={`mb-2 p-2 ${
                            message.user_id === auth.currentUser?.uid
                              ? "align-self-end rightChat"
                              : "align-self-start leftChat"
                          }`}
                        >
                          <div
                            className={`small ${
                              message.user_id === auth.currentUser?.uid
                                ? "text-end"
                                : "text-start"
                            } text-muted mb-1`}
                          >
                            {formatDate(message.timestamp)}
                          </div>
                          <div>{message.text}</div>
                          <div
                            className={`small ${
                              message.user_id === auth.currentUser?.uid
                                ? "text-end"
                                : "text-start"
                            } text-muted`}
                          >
                            {getUserName(message.user_id)}
                          </div>
                        </div>
                      </div>
                    ))}
                    <div ref={messagesEndRef}></div>
                  </div>
                </div>
              ) : (
                <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                  <p>Select a chat to view details</p>
                </div>
              )}
              <InputGroup className="mt-auto">
                <Form.Control
                  type="text"
                  placeholder="Type a message"
                  value={newMessageText}
                  onChange={(e) => setNewMessageText(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSendMessage();
                    }
                  }}
                />

                <Button variant="outline-secondary" onClick={handleSendMessage}>
                  <BsSendFill />
                </Button>
                <Button variant="outline-primary">
                  <FaMicrophone />
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Container>
      )}
    </CRHomeStyled>
  );
};

export default CRHome;
