import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP,  } from "../../../config";
import { post, put , get} from "../../../library/Requests/helpers";

import {healthCategories, labtestPackages, pharmacyCategories} from "../../../Scenes/graphqlConfig";

export const getHealthCategoriesAPI = createAsyncThunk(
  "auth/getHealthCategoriesAPI",
  async () => await post(`${SERVER_IP}/graphql`, JSON.stringify(healthCategories))
);
 
export const getHealthPackagesAPI = createAsyncThunk(
  "auth/getHealthPackagesAPI",
  async () => await post(`${SERVER_IP}/graphql`, JSON.stringify(labtestPackages))
);
export const getAllTestsAPI = createAsyncThunk(
  "auth/getAllTestsAPI",
  async (body: any) => {
    let query
  if(body?.searchText){
    query = `tests(page: ${body?.page}, count: ${body?.count}, type: "${body?.type}" , searchText:"${body?.searchText}")`
  }else{
    query = `tests(page: ${body?.page}, count: ${body?.count}, type: "${body?.type}")`
  }

    const response =  await post(`${SERVER_IP}/graphql`, JSON.stringify({
      query: `{
            ${query}{
              service_code,
              service_name,
              fasting,
              type
              price {
                actual_cost
                discount_percentage
                discounted_price
              },
              visit_type
            },
        }`,
    }))
  return {
    ...response,
  };
  }
    
    
);
 

export const getPharmacyCategoriesAPI = createAsyncThunk(
  "auth/getPharmacyCategoriesAPI",
  async () => await post(`${SERVER_IP}/graphql`, JSON.stringify(pharmacyCategories))
);