import React, { useState } from "react";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";

const HospitalDetails = () => {
  const [activeTab, setActiveTab] = useState("virtual");

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  const virtualConsultationData = [
    {
      name: "Dr. Annette Black",
      specialization: "Ayurvedic",
      rating: 4.1,
      experience: "5+ Years Experience",
      languages: "English, Hindi, Tamil",
      clinic: "2nd Sector, HSR Layout",
      clinicname: "• ABC Clinic",
      fee: 500,
      nextAvailable: "Next Available at 11:00AM",
      image: "../../../assets/images/imgdoc.png",
    },
    {
      name: "Dr. Annette Black",
      specialization: "Ayurvedic",
      rating: 4.1,
      experience: "5+ Years Experience",
      languages: "English, Hindi, Tamil",
      clinic: "2nd Sector, HSR Layout ",
      clinicname: "• ABC Clinic",
      fee: 500,
      nextAvailable: "Next Available at 11:00AM",
      image: "../../../assets/images/imgdoc.png",
    },
  ];

  const opdConsultationData = [
    {
      name: "Hospital Name",
      specialization: "Specializations",
      rating: 4.5,
      clinicname: "Magadi Main Rd, Chandana layout, Sunkadakatte, Bengaluru, Karnataka 560091",
      fee: 700,
      nextAvailable: "Next Available at 12:00PM",
      image: "../../../assets/images/imgdoc.png",
      distance:"4 Km Away",
    },
    {
      name: "Hospital Name",
      specialization: "Specializations",
      rating: 4.7,
      clinicname: "Magadi Main Rd, Chandana layout, Sunkadakatte, Bengaluru, Karnataka 560091",
      fee: 600,
      nextAvailable: "Next Available at 1:00PM",
      image: "../../../assets/images/imgdoc.png",
      distance:"4 Km Away",
    },
  ];

  const renderCards = (data: any) => {
    return data.map((doctor: any, index: any) => (
      <div className="doctor-card" key={index}>
        <div className="doctor-info">
          <img src={doctor.image} alt={doctor.name} className="doctor-image" />
          <div className="doctor-details">
            <h2>{doctor.name}</h2>
            <p className="specialization">{doctor.specialization}</p>
            <div className="rating">
              <span className="star">★</span>
              <span>{doctor.rating}</span>
            </div>
            <div className="experience-languages">
              <span>{doctor.experience}</span>
              <span>•</span>
              <span>{doctor.languages}</span>
            </div>
          </div>
        </div>
        <hr />
        <div className="clinic-info">
          <div className="info">
            <p>
              {doctor.clinic}{" "}
              <span className="clinicname">  {doctor.clinicname}</span>
            </p>
            <p className="fee">{doctor.fee} Consultation Fee</p>
            <p className="next-available">{doctor.distance}</p>
          </div>
          <div className="appbtn">
            <button className="book-appointment">Book Appointment</button>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="hospital-details">
      <div className="top-section">
        <div className="search-bar">
          <Search />
          <input type="text" placeholder="Search Order" />
        </div>
        <div className="consultation-buttons">
          <button
            className={`virtual-consultation ${
              activeTab === "virtual" ? "active" : ""
            }`}
            onClick={() => handleTabClick("virtual")}
          >
            Virtual Consultation
          </button>
          <button
            className={`opd-consultation ${
              activeTab === "opd" ? "active" : ""
            }`}
            onClick={() => handleTabClick("opd")}
          >
            OPD Consultation
          </button>
        </div>
      </div>
      <div className="cards-container">
        {activeTab === "virtual"
          ? renderCards(virtualConsultationData)
          : renderCards(opdConsultationData)}
      </div>
    </div>
  );
};

export default HospitalDetails;
