import React from "react";

const follow = [
  {
    patientname: "Patient's Name",
    amount: "Amount",
    date: "Date",
    number: "Phone Number",
    clinic: "Clinic Name"
  },
  {
    patientname: "Patient's Name",
    amount: "Amount",
    date: "Date",
    number: "Phone Number",
    clinic: "Clinic Name"
  },
  {
    patientname: "Patient's Name",
    amount: "Amount",
    date: "Date",
    number: "Phone Number",
    clinic: "Clinic Name"
  },
  {
    patientname: "Patient's Name",
    amount: "Amount",
    date: "Date",
    number: "Phone Number",
    clinic: "Clinic Name"
  },
];

const Followup = () => {
  return (
    <div className="followup">
      {follow.map((item, index) => (
        <div className="message" key={index}>
          <p>Hi {item.patientname}</p>
          <p>
            This is a reminder that your payment of [{item.amount}] is due on [{item.date}].
            Please log in to your patient portal to make the payment. For any
            questions, contact us at [{item.number}].
          </p>
          <p>Thank you</p>
          <p>[{item.clinic}]</p>
        </div>
      ))}
    </div>
  );
};

export default Followup;
