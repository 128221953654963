import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getHealthCategoriesAPI,
  getHealthPackagesAPI,
  getAllTestsAPI,
  getPharmacyCategoriesAPI,
} from "./labtestService";

export interface LabTestState {
  loading: boolean;
  error: any;
  healthCategoriesList: any;
  healthPackagesList: any;
  allTestsList: any;
  allPharmacyCategoriesList: any;
}
const userData: string | null = localStorage.getItem("user");
const initialState: LabTestState = {
  loading: false,
  error: null,
  healthCategoriesList: [],
  healthPackagesList: [],
  allTestsList: [],
  allPharmacyCategoriesList: []
};

export const authSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state: LabTestState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  
    updateErrorMessage: (state: LabTestState, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    //getHealthCategoriesAPI
    builder.addCase(getHealthCategoriesAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getHealthCategoriesAPI.fulfilled,
      (state: LabTestState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.healthCategoriesList = action.payload?.data;
      }
    );
    builder.addCase(getHealthCategoriesAPI.rejected, (state: LabTestState, action: any) => {
      state.loading = false;
      state.healthCategoriesList = [];
    });
    //getHealthPackagesAPI
    builder.addCase(getHealthPackagesAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getHealthPackagesAPI.fulfilled,
      (state: LabTestState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.healthPackagesList = action.payload?.data;
      }
    );
    builder.addCase(getHealthPackagesAPI.rejected, (state: LabTestState, action: any) => {
      state.loading = false;
      state.healthPackagesList = [];
    });
    //getAllTestsAPI
    builder.addCase(getAllTestsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllTestsAPI.fulfilled,
      (state: LabTestState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allTestsList = action.payload?.data;
      }
    );
    builder.addCase(getAllTestsAPI.rejected, (state: LabTestState, action: any) => {
      state.loading = false;
      state.allTestsList = [];
    });
    //getPharmacyCategoriesAPI

    builder.addCase(getPharmacyCategoriesAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getPharmacyCategoriesAPI.fulfilled,
      (state: LabTestState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allPharmacyCategoriesList = action.payload?.data?.medicineCategories;
      }
    );
    builder.addCase(getPharmacyCategoriesAPI.rejected, (state: LabTestState, action: any) => {
      state.loading = false;
      state.allPharmacyCategoriesList = [];
    });
    
  },
});

export const { setLoading , updateErrorMessage} = authSlice.actions;

export default authSlice.reducer;
