import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { get, patch, post, put } from "../../../library/Requests/helpers";

import {
  getClientOrders,
  getClients,
  getClientUsers,
  getOrderClients,
  getPrescriptions,
  getVendorsForCity,
} from "../../../Scenes/graphqlConfig";

export const getClientOrdersAPI = createAsyncThunk(
  "auth/getClientOrdersAPI",
  async () =>
    await post(`${SERVER_IP}/graphql`, JSON.stringify(getClientOrders))
);

export const getOrderClientsAPI = createAsyncThunk(
  "auth/getOrderClientsAPI",
  async () =>
    await post(`${SERVER_IP}/graphql`, JSON.stringify(getOrderClients))
);

export const getClientsAPI = createAsyncThunk(
  "auth/getClientsAPI",
  async () => await post(`${SERVER_IP}/graphql`, JSON.stringify(getClients))
);

export const getPrescriptionsAPI = createAsyncThunk(
  "auth/getPrescriptionsAPI",
  async (type:any) => await post(`${SERVER_IP}/graphql`, JSON.stringify(getPrescriptions(type)))
);

export const getClientUsersAPI = createAsyncThunk(
  "auth/getClientUsersAPI",
  async (body: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(
        getClientUsers(body.clientId, body.searchText, body.count, body.page)
      )
    )
);

export const getEmployeeWalletAPI = createAsyncThunk(
  "auth/getEmployeeWalletAPI",
  async (id: any) => await get(`${SERVER_IP}/api/v1/wallet/user/${id}`)
);

export const addEmployeeForCompanyAPI = createAsyncThunk(
  "auth/addEmployeeForCompanyAPI",
  async (body: any) =>
    await post(`${SERVER_IP}/api/v1/client/${body.clientId}/user`, body.payload)
);

export const editEmployeeForCompanyAPI = createAsyncThunk(
  "auth/editEmployeeForCompanyAPI",
  async (body: any) =>
    await put(`${SERVER_IP}/api/v1/client/${body.clientId}/user`, body.payload)
);

export const getVendorsForCityAPI = createAsyncThunk(
  "auth/getVendorsForCityAPI",
  async (body: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(getVendorsForCity(body.city, body.packageCode))
    )
);

export const getVendorSlotsAPI = createAsyncThunk(
  "auth/getVendorSlotsAPI",
  async (obj: any) => await patch(`${SERVER_IP}/api/v1/timeslot/vendor`, obj)
);

export const createOrderAPI = createAsyncThunk(
  "auth/createOrderAPI",
  async (obj: any) => await post(`${SERVER_IP}/api/v1/order`, obj)
);

export const addClientAPI = createAsyncThunk(
  "auth/addClientAPI",
  async (obj: any) => await post(`${SERVER_IP}/api/v1/client`, obj)
);
