import React from "react";
import { useState, useEffect } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { DocdashboardStyled } from "./DocdashboardStyled.styled";
import Overview from "./Components/Overview/Overview";
import Appointmentorder from "./Components/Appointmentorder/Appointmentorder";
// import BookingsDoc from "./Components/Bookingsdoctor/BookingsDoc";
import { getDashboardInfo } from "../../redux/slices/dashboard/dashboardService";
import Appointment from "./Components/Appointment/Appointment";

const Docdash = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const { dashboardInfo } = useSelector(
    (ReduxState: any) => ReduxState.dashboard
  );
  useEffect(() => {
    dispatch(getDashboardInfo());
  }, []);

  return (
    <DocdashboardStyled>
      <div className="maindash">
      <h2 className="sectionHeading">Dashboard</h2>
        <div className="leftsec">
          <Overview />
          <Appointmentorder />
        </div>
        <div className="rightsec">{/* <Appointment /> */}</div>
      </div>
    </DocdashboardStyled>
  );
};

export default Docdash;
