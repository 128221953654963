import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FaFacebook, FaGoogle, FaWhatsapp } from "react-icons/fa";
import docLoginHeroImg from "../../assets/images/docLoginHeroImg.png";
import "./styles.css";
import { LoginCompStyled } from "./LoginComp.styled";
import { SignUpCompStyled } from "./SignupComp.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  requestOtpAPI,
  validateOtpAPI,
} from "../../redux/slices/auth/authService";
import OTPInput from "react-otp-input";
import { IoMdArrowRoundBack } from "react-icons/io";
import SocialLoginButtons from "../../components/SocialLoginButtons/SocialLoginButtons";

const Signin = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [loginFormTitle, setLoginFormTitle] = useState(
    "Sign in to your existing account."
  );

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // Select the elements with the specified classes
    const rightMenuSideContent = document.querySelector(
      ".right-menu-side-content"
    );
    const footerWrapper = document.querySelector(".footer-wrapper");

    // Apply the new styles if the elements exist
    if (rightMenuSideContent) {
      rightMenuSideContent.style.width = "100%";
      rightMenuSideContent.style.marginLeft = "0px";
    }

    if (footerWrapper) {
      footerWrapper.style.width = "100%";
      footerWrapper.style.marginLeft = "0px";
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const LoginComp = ({ setActiveTab }) => {
    const [mobileNumber, setMobileNumber] = useState("");
    const [errorMobileNumber, setErrorMobileNumber] = useState(false);

    const [password, setPassword] = useState("");
    const [errorPassword, setErrorPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const { error, user } = useSelector(({ auth }) => auth);
    const history = useHistory();
    const dispatch = useDispatch();
    const [otpCode, setOtpCode] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [otpErrorMsg, setOtpErrorMsg] = useState("");
    const [timeRemainingVal, setTimeRemainingVal] = useState(60);
    const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
    const [isPhoneNumber, setIsPhoneNumber] = useState(false);
    const [validateOtp, setValidateOtp] = useState("");
    let timer = null;
    let timeRemaining = 60;

    useEffect(() => {
      if (user?.id) {
        history.push("/");
        window.location.href = window.location.href;
      }
    }, [user, history]);

    const handleContinueClick = () => {
      if (mobileNumber === "") {
        setErrorMobileNumber(true);
      } else if (password === "") {
        setErrorPassword(true);
      } else {
        setErrorMobileNumber(false);
        setErrorPassword(false);
        // requestOtp();
        signInCall();
      }
    };

    const signInCall = () => {
      setLoading(true);
      const body = {
        email: mobileNumber, // Assuming the API accepts an email or phone number
        password: password, // Password is optional or managed through OTP in this flow
        role: "client_employee",
      };
      dispatch(loginUser(body)).finally(() => {
        setLoading(false);
        window.location.href = window.location.href;
      });
    };

    const handleChangeValue = (e) => {
      setMobileNumber(e.target.value);
      setErrorMobileNumber(false);
    };

    const handleChangeValuePass = (e) => {
      setPassword(e.target.value);
      setErrorPassword(false);
    };

    const checkPhoneNumberOrEmail = (inputV) => {
      const phoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (phoneRegex.test(inputV)) {
        return "phoneNo";
      }
      if (emailRegex.test(inputV)) {
        return "email";
      }
      return "invalid";
    };

    const resetTImer = async () => {
      timer = setInterval(updateTimer, 1000);
    };

    const updateTimer = () => {
      timeRemaining--;
      if (timeRemaining === 0) {
        clearInterval(timer);
      } else {
        setTimeRemainingVal(timeRemaining);
      }
    };
    const requestOtp = async () => {
      const phoneNumber = mobileNumber;
      const inputType = checkPhoneNumberOrEmail(phoneNumber);
      if (inputType === "phoneNo") {
        sendOTPCall({ phone: phoneNumber });
        setIsPhoneNumber(true);
      } else if (inputType === "email") {
        sendOTPCall({ email: phoneNumber });
        setIsPhoneNumber(false);
      } else {
        setIsPhoneNumberError(true);
      }
    };
    const sendOTPCall = async (bodyJ) => {
      setLoading(true);
      const response = await dispatch(requestOtpAPI(bodyJ));
      setLoading(false);
      console.log("response", response);
      if (response?.payload?.success) {
        setOtpSent(true);
        setOtpErrorMsg("");
        setValidateOtp("");
        timeRemaining = 60;
        resetTImer();
      } else {
        setIsPhoneNumberError(true);
      }
    };

    const handlePinChange = (pinCode) => {
      setValidateOtp(pinCode);
    };

    const handleResedOTP = () => {
      requestOtp();
    };

    const checkOtpDisabled = () => {
      return validateOtp?.length < 6 ? true : false;
    };

    const handleValidateOtp = async () => {
      const reqBody = isPhoneNumber
        ? {
            phone: mobileNumber,
            otp: validateOtp.toString(),
            // role: "doctor",
          }
        : {
            email: mobileNumber,
            otp: validateOtp.toString(),
            // role: "doctor",
          };
      setLoading(true);
      const response = await dispatch(validateOtpAPI(reqBody));
      console.log("response main", response);

      setLoading(false);
      if (response?.payload?.success) {
        setOtpSent(true);
        props?.handleClose();
      } else {
        setOtpErrorMsg("Please enter Valid OTP");
      }
    };
    console.log("otpErrorMsg", otpErrorMsg);

    return (
      <LoginCompStyled>
        <div className="login-container">
          <div className="login-box">
            <h2>Log In</h2>
            {!otpSent ? (
              <>
                <form>
                  <div>
                    <label htmlFor="phone">Email</label>
                    <input
                      type="text"
                      id="phone"
                      placeholder="Enter your number / email"
                      value={mobileNumber}
                      className={errorMobileNumber ? "error" : ""}
                      onChange={handleChangeValue}
                    />
                  </div>
                  <div>
                    <label htmlFor="phone">Password</label>
                    <input
                      type="text"
                      id="phone"
                      placeholder="Enter your Password"
                      value={password}
                      className={errorPassword ? "error" : ""}
                      onChange={handleChangeValuePass}
                    />
                  </div>
                  {error && <p className="errorMessage">{error}</p>}
                  <button
                    type="button"
                    onClick={handleContinueClick}
                    disabled={mobileNumber?.length < 6}
                  >
                    {loading ? "Loading..." : "Continue"}
                  </button>
                </form>
                {/* <div className="signup-text">
                  <p>
                    Don't have an account?{" "}
                    <a href="#" onClick={() => setActiveTab(1)}>
                      Signup
                    </a>
                  </p>
                </div> */}
                {/* <div className="or-divider">
                  <span>OR</span>
                </div>
                <div className="social-login">
                  <button className="google-btn">
                    <FaGoogle size={20} />
                    Google
                  </button>
                  <button className="whatsapp-btn">
                    <FaWhatsapp size={20} />
                    Whatsapp
                  </button>
                  <button className="facebook-btn">
                    <FaFacebook size={20} />
                    Facebook
                  </button>
                  <div className="g-butttons">
                    <SocialLoginButtons
                      closeModel={() => {}}
                      isSignUp={true}
                      showPageLoader={() => {}}
                      navigateUserSocial={() => {}}
                    />
                  </div> 
                </div>*/}
              </>
            ) : (
              <div className="login-step2">
                {otpSent && (
                  <>
                    <div className="back-btn-sec text-white">
                      <span
                        onClick={() => {
                          setOtpSent(false);
                        }}
                        className=" text-xl cursor-pointer top-category-sec font-bold mx-2 back-btn-sec opacity-75 "
                      >
                        <IoMdArrowRoundBack />
                        &nbsp;
                        {` Back`}
                      </span>
                    </div>
                    <div className="login-header-left text-white">
                      Verification code
                    </div>
                    <p className="verify-new-line-sec text-white">
                      We have sent a 6-digit OTP on your mobile number +91
                      {` `} {mobileNumber}
                    </p>

                    <div className="otp-enter-box">
                      <OTPInput
                        inputType="number"
                        inputStyle={{
                          marginRight: "14px",
                          width: "100%",
                          height: "100%",
                          fontSize: "24px",
                          // fontFamily: "Montserrat",
                          MozAppearance: "textfield",
                        }}
                        value={validateOtp}
                        onChange={(e) => {
                          handlePinChange(e);
                          setOtpErrorMsg("");
                        }}
                        numInputs={6}
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    {otpErrorMsg && (
                      <p className="error_message">{otpErrorMsg}</p>
                    )}
                    <div className="resend-time-sec">
                      <p className="text-white">
                        Did not receive OTP?
                        {timeRemainingVal > 1 ? (
                          <>
                            {` `} Resend in
                            <span>
                              {`0:`}
                              {timeRemainingVal}
                            </span>
                            Seconds
                          </>
                        ) : (
                          <>
                            <button
                              onClick={handleResedOTP}
                              className="re-send-btn"
                            >
                              Resend
                            </button>
                          </>
                        )}
                      </p>
                    </div>
                    <div className="proceed-btn text-white">
                      <button
                        onClick={handleValidateOtp}
                        disabled={checkOtpDisabled()}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </LoginCompStyled>
    );
  };

  function SignUpComp({ setActiveTab }) {
    return (
      <SignUpCompStyled>
        <div className="signup-container">
          <div className="signup-box">
            <h2>Patients are eager to find doctors like you.</h2>
            <p>
              Join RaphaCure and connect with millions of patients seeking the
              right doctor through your digital profile.
            </p>
            <form>
              <label htmlFor="name">Name</label>
              <input type="text" id="name" placeholder="Type full name" />
              <label htmlFor="phone">Phone</label>
              <input
                type="number"
                min={0}
                max={9999999999}
                id="phone"
                placeholder="Type number"
              />
              <p className="otp-text">
                OTP will be sent to this number for verification
              </p>
              <button type="button">Continue</button>
            </form>
            <div className="login-text">
              <p>
                If you already have an account. Please{" "}
                <a href="#" onClick={() => setActiveTab(0)}>
                  login
                </a>
              </p>
            </div>
            <div className="or-divider">
              <span>OR</span>
            </div>
            <div className="social-login">
              <button className="google-btn">
                <FaGoogle size={20} />
                Google
              </button>
              <button className="whatsapp-btn">
                <FaWhatsapp size={20} />
                Whatsapp
              </button>
              <button className="facebook-btn">
                <FaFacebook size={20} />
                Facebook
              </button>
            </div>
          </div>
        </div>
      </SignUpCompStyled>
    );
  }

  useEffect(() => {
    let urlQueryString = location.search;
    let signInType = new URLSearchParams(urlQueryString).get("returnUrl");
  }, [location.search]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      history.push("/");
    }
  }, []);

  return (
    <div className="loginsignupContainer">
      {activeTab === 0 && <LoginComp setActiveTab={setActiveTab} />}
      {activeTab === 1 && <SignUpComp setActiveTab={setActiveTab} />}
      {/* <div className="docImgWrapper">
        <img src={docLoginHeroImg} alt="Doctor Login" />
      </div> */}
      {/* <div className="data-loginsignupContainer">
        <div className="freshbag_signin_header">
          {activeTab === 0 ? loginFormTitle : "Create an account for faster ."}
        </div>
        <Row className={"tabHeaderDesktop"}>
          <Col
            xs={6}
            onClick={() => setActiveTab(0)}
            className={activeTab === 0 ? " tabActive" : "tabInActive"}
          >
            <span>Sign In</span>
          </Col>
        </Row>
        {activeTab === 0 && <SignInTab gotoDelivery={gotoDelivery} />}
        {activeTab === 1 && (
          <SignUpTab onSignUpSuccess={() => setActiveTab(0)} />
        )}
      </div> */}
    </div>
  );
};

export default Signin;
