import React, { useState } from "react";
import PharmacyIcon from "../../../assets/images/pharmacyicon.png";
import LabtestIcon from "../../../assets/images/labtestico.png";
import RadiologyIcon from "../../../assets/images/radiologyicon.png";
import AmbulanceIcon from "../../../assets/images/ambulanceicon.png";
import BloodbankIcon from "../../../assets/images/bloodbankicon.png";
import PharmacyDetails from "./PharmacyDetails";
import LabtestDetail from "./LabtestDetail";
import RadiologyDetails from "./RadiologyDetail";
import AmbulanceDetails from "./AmbulanceDetail";
import BloodBankDetails from "./BloodBank";
import HospitalDetails from "./HospitalDetail";



const OrderCategories = () => {
  const [activeTab, setActiveTab] = useState('Pharmacy');

  const renderProducts = () => {
    switch (activeTab) {
      case 'Pharmacy':
        return (
          <PharmacyDetails />
        );
      case 'Lab Test':
        return (
          <LabtestDetail />
        );
      case 'Radiology':
        return (
          <RadiologyDetails />
        );
      case 'Ambulance':
        return (
          <AmbulanceDetails />
        );
      case 'Blood Bank':
        return (
          <BloodBankDetails />
        );
      case 'Hospital':
        return (
          <HospitalDetails />
        );
      default:
        return null;
    }
  };

  return (
    <div className="order-category">
      <div className="category-buttons">
        <div
          className={`category-button pharmacy ${activeTab === 'Pharmacy' ? 'active' : ''}`}
          onClick={() => setActiveTab('Pharmacy')}
        >
          <img src={PharmacyIcon} alt="Pharmacy" />
          <span>Pharmacy</span>
        </div>
        <div
          className={`category-button lab-test ${activeTab === 'Lab Test' ? 'active' : ''}`}
          onClick={() => setActiveTab('Lab Test')}
        >
          <img src={LabtestIcon} alt="Lab Test" />
          <span>Lab Test</span>
        </div>
        <div
          className={`category-button radiology ${activeTab === 'Radiology' ? 'active' : ''}`}
          onClick={() => setActiveTab('Radiology')}
        >
          <img src={RadiologyIcon} alt="Radiology" />
          <span>Radiology</span>
        </div>
        <div
          className={`category-button ambulance ${activeTab === 'Ambulance' ? 'active' : ''}`}
          onClick={() => setActiveTab('Ambulance')}
        >
          <img src={AmbulanceIcon} alt="Ambulance" />
          <span>Ambulance</span>
        </div>
        <div
          className={`category-button blood-bank ${activeTab === 'Blood Bank' ? 'active' : ''}`}
          onClick={() => setActiveTab('Blood Bank')}
        >
          <img src={BloodbankIcon} alt="Blood Bank" />
          <span>Blood Bank</span>
        </div>
        <div
          className={`category-button hospital ${activeTab === 'Hospital' ? 'active' : ''}`}
          onClick={() => setActiveTab('Hospital')}
        >
          <img src={AmbulanceIcon} alt="Hospital" />
          <span>Hospital</span>
        </div>
      </div>

      {/* <div className="search-bar">
        <input type="text" placeholder="Search Medicines" />
      </div>

      <div className="filter-buttons">
        <button className="filter-button active">Branded</button>
        <button className="filter-button">Generic</button>
        <button className="filter-button">Ayurveda</button>
        <button className="filter-button">Homeopathy</button>
      </div> */}

      {renderProducts()}
    </div>
  );
};

export default OrderCategories;
