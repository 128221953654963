import React, { useEffect, useState } from "react";
import { Input, Button, Select, Modal, Form, Checkbox, Radio } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import { EmployeeStyled } from "./Employee.styled";
import PatientDetailModal from "../../components/PatientDetailModal/Patientmodal";
import { useDispatch, useSelector } from "react-redux";

import {
  addEmployeeForCompanyAPI,
  editEmployeeForCompanyAPI,
  getClientsAPI,
  getClientUsersAPI,
  getEmployeeWalletAPI,
} from "../../redux/slices/orders/orderService";
import * as XLSX from "xlsx";
import { Pagination, Table } from "react-bootstrap";
import { getEmployeeCountAPI } from "../../redux/slices/generic/genericService";
import { FaSearch } from "react-icons/fa";

const { Search } = Input;
const { Option } = Select;

const Employee = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState<any>([]);
  const [originalData, setOriginalData] = useState<any>([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [isWalletModalVisible, setIsWalletModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState<any>(null);
  const [walletData, setWalletData] = useState<any>([]);
  const [form] = Form.useForm();
  const [lgShow, setLgShow] = useState(false);
  const dispatch = useDispatch();
  const [clients, setClients] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [empID, setEmpID] = useState("");
  const [employeeCount, setEmployeeCount] = useState<any>("");
  const { clientUsers } = useSelector((ReduxState: any) => ReduxState.orders);
  const { allEmployeeCountList } = useSelector(
    (ReduxState: any) => ReduxState.generic
  );

  const filterOption = (input: string, option?: { children: string }) =>
    (option?.children ?? "").toLowerCase().includes(input.toLowerCase());

  console.log(empID, "clientUsers");

  useEffect(() => {
    const getClients = async () => {
      const res = await dispatch(getClientsAPI());
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("clients : ", resJson.payload.data.clients);
      setClients(resJson.payload.data.clients);
    };
    getClients();
  }, [dispatch]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (value.trim() === "") {
      setData(originalData);
    } else {
      const filteredData = originalData.filter(
        (item: any) =>
          item.firstName.toLowerCase().includes(value.toLowerCase()) ||
          item.lastName.toLowerCase().includes(value.toLowerCase()) ||
          item.contact.toLowerCase().includes(value.toLowerCase()) ||
          item.mail.toLowerCase().includes(value.toLowerCase())
      );
      setData(filteredData);
    }
  };

  const handleCompanyChange = async (value: string, option: any) => {
    setEmpID(value);

    try {
      setSelectedCompany(option.children);
      setSelectedCompanyId(value); // Save the selected company client ID in the state
      const response = await dispatch(
        getClientUsersAPI({
          clientId: value,
          searchText: "",
          count: 20,
          page: 0,
        })
      );
      const resJson = JSON.parse(JSON.stringify(response));
      console.log("Client Users API Response:", value);

      const formattedData = resJson.payload.data.clientUsers.map(
        (user: any) => ({
          id: user.id,
          firstName: user.first_name || "",
          lastName: user.last_name || "",
          gender: user.gender || "",
          age: user.age || null,
          contact: user.phone || "",
          mail: user.email || "",
          employeeId: user.employee_id || "",
          designation: user.designation || "",
          location: "", // Not provided in clientUsers, keeping it empty
          dob: user.dob || null,
          clientName: "", // Not provided in clientUsers, keeping it empty
          parentClientName: "", // Not provided in clientUsers, keeping it empty
          status: user.active_status || "",
          isActive: user.active_status === "active",
          secondary_phone: user.secondary_phone || "",
          secondary_email: user.secondary_email || "",
        })
      );

      setData(formattedData);
      setOriginalData(formattedData);
    } catch (error) {
      console.error("Error fetching client users:", error);
    }
  };

  const handleAddNewEmployee = () => {
    setIsAddModalVisible(true);
  };

  const handleAddModalCancel = () => {
    setIsAddModalVisible(false);
    form.resetFields();
  };

  // Function to handle adding new employee
  const handleAddEmployee = async () => {
    try {
      const values = await form.validateFields();
      console.log("Add Employee Form Data:", values);
      console.log("Selected Company Client ID:", selectedCompanyId);

      const payload = {
        user: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          secondary_email: values.email, // Using the same email for secondary
          phone: values.number,
          secondary_phone: values.number, // Using the same number for secondary
          gender: values.gender,
          employee_id: values.employeeId,
          dob: values.dob,
          active_status: values.isActive ? "active" : "inactive",
          age: calculateAge(values.dob), // You'll need to implement this function
          designation: values.designation,
        },
      };

      const response = await dispatch(
        addEmployeeForCompanyAPI({
          clientId: selectedCompanyId,
          payload: payload,
        })
      );

      console.log("Add Employee API Response:", response);

      // Handle the response as needed (e.g., show success message, update the employee list)

      setIsAddModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Error adding employee:", error);
      // Handle the error (e.g., show error message)
    }
  };

  // Helper function to calculate age from date of birth
  const calculateAge = (dob: string) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  // Function to handle editing employee
  const handleEditEmployee = async () => {
    try {
      const values = await form.validateFields();
      console.log("Edit Employee Form Data:", values);
      console.log("Selected Company Client ID:", selectedCompanyId);

      const payload = {
        user: {
          id: currentEmployee.id, // Assuming the current employee object has an id field
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          secondary_email: values.email, // Using the same email for secondary
          phone: values.number,
          secondary_phone: values.number, // Using the same number for secondary
          gender: values.gender,
          employee_id: values.employeeId,
          dob: values.dob,
          active_status: values.isActive ? "active" : "inactive",
          age: calculateAge(values.dob),
          designation: values.designation,
        },
      };

      const response = await dispatch(
        editEmployeeForCompanyAPI({
          clientId: selectedCompanyId,
          payload: payload,
        })
      );

      console.log("Edit Employee API Response:", response);

      // Handle the response as needed (e.g., show success message, update the employee list)
      setIsDetailsModalVisible(false);
      setIsEditMode(false);
      form.resetFields();

      // Refresh the employee list after editing
      handleCompanyChange(selectedCompanyId, { children: selectedCompany });
    } catch (error) {
      console.error("Error editing employee:", error);
      // Handle the error (e.g., show error message)
    }
  };

  const handleDetailsClick = (employee: any) => {
    setCurrentEmployee(employee);
    setIsDetailsModalVisible(true);
    setIsEditMode(false);
  };

  const handleWalletClick = async (employee: any) => {
    setCurrentEmployee(employee);
    setIsWalletModalVisible(true);
    const response = await dispatch(getEmployeeWalletAPI(employee.id));
    const resJson = JSON.parse(JSON.stringify(response));
    console.log("Employee Wallet API Response:", resJson.payload.data.wallets);
    setWalletData(resJson.payload.data.wallets);
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleDetailsModalCancel = () => {
    setIsDetailsModalVisible(false);
    setIsEditMode(false);
  };

  const totalEmployee = data.length;
  const totalMaleEmployees = data.filter(
    (employee: any) => employee.gender === "male"
  ).length;
  const totalFemaleEmployees = data.filter(
    (employee: any) => employee.gender === "female"
  ).length;
  const formatDate = (timestamp: any) => {
    const date = new Date(parseInt(timestamp, 10));
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  const handleDownload = () => {
    const exportData = data.map((item: any) => ({
      ID: item?.id,
      Name: `${item?.firstName} ${item?.lastName}`,
      Age: item?.user?.age,
      Gender: item?.gender || "N/A",
      "Visit Type": item.type || "N/A",
      ClientName: item?.clientName || "N/A",
      Contact: item?.contact || "N/A",
      Designation: item?.designation || "N/A",
      "Date of Birth ": formatDate(item?.dob),
      Active: item?.isActive || "N/A",
      MailID: item?.mail || "N/A",
      ParentClientName: item?.parentClientName || "N/A",
    }));
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Booking Reports");
    XLSX.writeFile(workbook, "Booking_Reports.xlsx");
  };
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  // const getPaginationItems = () => {
  //   const paginationItems = [];
  //   const maxPageNumbersToShow = 10;
  //   const half = Math.floor(maxPageNumbersToShow / 2);
  //   let startPage = Math.max(1, currentPage - half);
  //   let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);
  //   if (endPage - startPage < maxPageNumbersToShow - 1) {
  //     startPage = Math.max(1, endPage - (maxPageNumbersToShow - 1));
  //   }
  //   for (let i = startPage; i <= endPage; i++) {
  //     paginationItems.push(
  //       <Pagination.Item
  //         key={i}
  //         active={i === currentPage}
  //         onClick={() => handlePageChange(i)}
  //       >
  //         {i}
  //       </Pagination.Item>
  //     );
  //   }
  //   if (startPage > 1) {
  //     paginationItems.unshift(
  //       <Pagination.Ellipsis
  //         key="ellipsis-start"
  //         onClick={() => handlePageChange(startPage - 1)}
  //       />
  //     );
  //   }
  //   if (endPage < totalPages) {
  //     paginationItems.push(
  //       <Pagination.Ellipsis
  //         key="ellipsis-end"
  //         onClick={() => handlePageChange(endPage + 1)}
  //       />
  //     );
  //   }
  //   return paginationItems;
  // };
  const userCountDetails = async (userD: any = {}) => {
    console.log(" empID : ", empID);
    const res = await dispatch(getEmployeeCountAPI(empID));
    const resJson = JSON.parse(JSON.stringify(res));
    console.log(resJson?.payload, "resJson");
    setEmployeeCount(resJson?.payload);
  };

  useEffect(() => {
    userCountDetails();
  }, [empID]);

  return (
    <EmployeeStyled>
      <h2 className="sectionHeading">Employee</h2>
      <div className="employee-add-container d-flex align-items-center">
        {/* Search Bar */}
        {/* <Search
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search Employee"
          enterButton
          style={{
            width: 300,
            marginRight: 20,
            borderRadius: "10px",
          }}
        /> */}

        <div
          className="search-bar"
          style={{
            width: 300,
            marginRight: 20,
            borderRadius: "10px",
          }}
        >
          <input
            type="text"
            placeholder="Search Patient Name or Serial No"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <FaSearch style={{ width: "3rem" }} />
        </div>
        {/* Add New Employee Button */}
        {selectedCompanyId && (
          <Button
            type="primary"
            onClick={handleAddNewEmployee}
            style={{
              backgroundColor: "#9747ff",
              borderColor: "#9747ff",
              marginRight: 10,
              borderRadius: "10px",
            }}
          >
            Add New Employee
          </Button>
        )}

        {/* Bulk Upload Button */}
        <Button style={{ marginRight: 10, borderRadius: "10px" }}>
          Bulk Upload
        </Button>

        {/* City Selector */}
        <Select
          defaultValue="Bangalore"
          style={{ width: 120, marginRight: 20, borderRadius: "10px" }}
        >
          <Option value="Bangalore">Bangalore</Option>
          <Option value="Mumbai">Mumbai</Option>
          <Option value="Delhi">Delhi</Option>
          {/* Add more cities as needed */}
        </Select>

        {/* Company Dropdown with Search */}
        <Select
          showSearch
          placeholder="Select Company"
          optionFilterProp="children"
          onChange={handleCompanyChange}
          onSearch={setSearchValue}
          filterOption={filterOption}
          style={{ width: 200, borderRadius: "10px" }}
        >
          {clients.map((client: any) => (
            <Option key={client.id} value={client.id}>
              {client.name}
            </Option>
          ))}
        </Select>
      </div>
      {selectedCompany && (
        <div className=" employee-count-div">
          <p>Showing Results for {selectedCompany}</p>
          <p>Total Employee: {clientUsers?.clientUsersCount}</p>
          <p>Male Employee: {employeeCount?.maleCount}</p>
          <p>Female Employee: {employeeCount?.femaleCount}</p>
          <button className="btn download-btn" onClick={handleDownload}>
            Download
          </button>
        </div>
      )}
      <div className="custom-table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Gender</th>
              <th>Age</th>
              <th>Contact</th>
              <th>Mail Id</th>
              <th>Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.length > 0 &&
              data.map((item: any) => (
                <tr key={item.id}>
                  <td>
                    {item.firstName} {item.lastName}
                  </td>
                  <td>{item.gender}</td>
                  <td>{item.age}</td>
                  <td>{item.contact}</td>
                  <td>{item.mail}</td>
                  <td
                    style={{
                      color: item.status === "active" ? "#00a124" : "#f34900",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {item.status}
                    <input type="checkbox" checked={item.isActive} readOnly />
                  </td>
                  <td>
                    <button
                      className="btn btn-link custom-button"
                      onClick={() => handleDetailsClick(item)}
                    >
                      Details
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-link custom-button"
                      onClick={() => {
                        handleWalletClick(item);
                      }}
                    >
                      Wallet
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <Pagination className="custom-pagination">
          <Pagination.First onClick={() => handlePageChange(1)} />
          <Pagination.Prev
            onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
          />
          {/* {getPaginationItems()} */}
          <Pagination.Next
          // onClick={() =>
          // handlePageChange(Math.min(totalPages, currentPage + 1))
          // }
          />
          <Pagination.Last
          // onClick={() => handlePageChange(totalPages)}
          />
        </Pagination>
      </div>

      {/* Modal for Adding New Employee */}
      <Modal
        title="Employee Details"
        visible={isAddModalVisible}
        onCancel={handleAddModalCancel}
        footer={[
          <Button key="cancel" onClick={handleAddModalCancel}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleAddEmployee} // Calls the add employee function
            style={{ backgroundColor: "#9747ff", borderColor: "#9747ff" }}
          >
            Save
          </Button>,
        ]}
        centered
        width={700}
      >
        <EmployeeForm form={form} readOnly={false} employee={null} />
      </Modal>

      {/* Modal for Viewing and Editing Employee Details */}
      {currentEmployee && (
        <Modal
          title="Employee Details"
          visible={isDetailsModalVisible}
          onCancel={handleDetailsModalCancel}
          footer={
            isEditMode ? (
              <>
                <Button onClick={handleDetailsModalCancel} type="default">
                  Cancel
                </Button>
                <Button
                  onClick={handleEditEmployee}
                  type="primary"
                  style={{ backgroundColor: "#9747ff", borderColor: "#9747ff" }}
                >
                  Save
                </Button>
              </>
            ) : (
              <Button onClick={handleEditClick} type="default">
                Edit
              </Button>
            )
          }
          centered
          width={700}
        >
          <EmployeeForm
            form={form}
            readOnly={!isEditMode}
            employee={currentEmployee}
          />
        </Modal>
      )}

      <PatientDetailModal
        show={lgShow}
        onHide={() => setLgShow(false)}
        patient={null}
      />

      {/* Modal for Wallet Details */}
      <Modal
        title={`${currentEmployee?.firstName} ${currentEmployee?.lastName} Wallet`}
        visible={isWalletModalVisible}
        onCancel={() => {
          setIsWalletModalVisible(false);
        }}
        footer={null}
        centered
        width={700}
      >
        <table className="custom-table">
          <thead>
            <tr>
              <th>Wallet Type</th>
              <th>Client Wallet Amount</th>
              <th>Remaining Amount</th>
              <th>Discount</th>
              <th>Limits</th>
            </tr>
          </thead>
          <tbody>
            {walletData.map((wallet: any) => (
              <tr key={wallet.id}>
                <td>{wallet.name}</td>
                <td>{wallet?.total_amount ? wallet?.total_amount : 0}</td>
                <td>{wallet?.amount ? wallet?.amount : 0}</td>
                <td>{wallet.discount_percentage}%</td>
                <td>{wallet.limits === 9999 ? "Unlimited" : wallet.limits}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    </EmployeeStyled>
  );
};

const EmployeeForm = ({ form, readOnly, employee, onSave }: any) => {
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={employee}
      onFinish={onSave}
      disabled={readOnly}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Form.Item
          name="isActive"
          valuePropName="checked"
          style={{ margin: 0 }}
        >
          <Checkbox disabled={readOnly}>Active</Checkbox>
        </Form.Item>
      </div>

      <div className="d-flex justify-content-between">
        <Form.Item
          name="firstName"
          label="First Name"
          style={{ flex: 1, marginRight: 10 }}
        >
          <Input
            placeholder="Enter First Name"
            type="text"
            readOnly={readOnly}
          />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name" style={{ flex: 1 }}>
          <Input
            placeholder="Enter Last Name"
            type="text"
            readOnly={readOnly}
          />
        </Form.Item>
      </div>

      <div className="d-flex justify-content-between">
        <Form.Item
          name="email"
          label="Email"
          style={{ flex: 1, marginRight: 10 }}
        >
          <Input placeholder="Enter Email ID" type="text" readOnly={readOnly} />
        </Form.Item>
        <Form.Item name="number" label="Number" style={{ flex: 1 }}>
          <Input
            placeholder="Enter Contact Number"
            type="number"
            readOnly={readOnly}
          />
        </Form.Item>
      </div>

      <div className="d-flex justify-content-between">
        <Form.Item
          name="employeeId"
          label="Employee ID"
          style={{ flex: 1, marginRight: 10 }}
        >
          <Input
            placeholder="Enter Employee ID"
            type="text"
            readOnly={readOnly}
          />
        </Form.Item>
        <Form.Item name="designation" label="Designation" style={{ flex: 1 }}>
          <Input
            placeholder="Enter Designation"
            type="text"
            readOnly={readOnly}
          />
        </Form.Item>
      </div>

      <div className="d-flex justify-content-between">
        <Form.Item
          name="location"
          label="Location"
          style={{ flex: 1, marginRight: 10 }}
        >
          <Input placeholder="Enter Location" type="text" readOnly={readOnly} />
        </Form.Item>
        <Form.Item name="dob" label="Date of Birth" style={{ flex: 1 }}>
          <Input
            placeholder="Enter Date of Birth"
            type="date"
            readOnly={readOnly}
          />
        </Form.Item>
      </div>

      <Form.Item name="gender" label="Gender">
        <Radio.Group disabled={readOnly}>
          <Radio value="male">Male</Radio>
          <Radio value="female">Female</Radio>
          <Radio value="other">Other</Radio>
        </Radio.Group>
      </Form.Item>

      <div className="d-flex justify-content-between">
        <Form.Item
          name="clientName"
          label="Client Name"
          style={{ flex: 1, marginRight: 10 }}
        >
          <Input
            placeholder="Enter Client Name"
            type="text"
            readOnly={readOnly}
          />
        </Form.Item>
        <Form.Item
          name="parentClientName"
          label="Parent Client Name"
          style={{ flex: 1 }}
        >
          <Input
            placeholder="Enter Parent Client Name"
            type="text"
            readOnly={readOnly}
          />
        </Form.Item>
      </div>
    </Form>
  );
};

export default Employee;
