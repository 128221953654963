import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getClientUsersAPI } from "./orderService";

export interface MyPatientsState {
  loading: boolean;
  error: any;
  clientUsers: any;
}

const initialState: MyPatientsState = {
  loading: false,
  error: null,
  clientUsers: {},
};

export const myOrderReducer = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setLoading: (state: MyPatientsState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateErrorMessage: (
      state: MyPatientsState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getClientUsersAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
      state.patientsList = null;
    });
    builder.addCase(
      getClientUsersAPI.fulfilled,
      (state: MyPatientsState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.clientUsers = action.payload.data;
      }
    );
    builder.addCase(
      getClientUsersAPI.rejected,
      (state: MyPatientsState, action: any) => {
        console.log({ action });
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
  },
});

export const { setLoading, updateErrorMessage } = myOrderReducer.actions;

export default myOrderReducer.reducer;
