import React from 'react';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/redicon.svg';
import { ReactComponent as Green } from '../../../assets/icons/greencircle.svg';


const AmbulanceDetails = () => {
    return (
        <div className="ambulance-container">
            <div className="form-section">
                <div className="location-input">
                    <div className="input-wrapper">
                        <div className="icon-wrapper">
                            <Green />
                        </div>
                        <input type="text" placeholder="Pickup Location" className="location-input-field" />
                    </div>
                    <div className="input-wrapper">
                        <div className="icon-wrapper">
                            <ArrowIcon />
                        </div>
                        <input type="text" placeholder="Drop Location" className="location-input-field" />
                    </div>
                </div>
                <div className="fare-display">
                    <p>Total Fare</p>
                    <p className="fare-amount">250.00</p>
                </div>
                <div className="action-buttons">
                    <button className="cancel-button">Cancel</button>
                    <button className="confirm-button">Confirm</button>
                </div>
            </div>
            <div className="map-section">
                <div className="ambulance-type-buttons">
                    <button className="ambulance-type-button active">Road Ambulance</button>
                    <button className="ambulance-type-button">Train Ambulance</button>
                    <button className="ambulance-type-button">Air Ambulance</button>
                </div>
                <div className="map-view">
                    <img src="/path-to-map-image" alt="Map" />
                </div>
            </div>
        </div>
    );
};

export default AmbulanceDetails;
