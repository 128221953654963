import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Dropdown } from "react-bootstrap";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import Select from "react-select";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addPatientAPI,
  fetchPatientsAPI,
} from "../../redux/slices/myPatients/myPatientsService";
import { toast } from "react-toastify";
import { getConfigInfoAPI } from "../../redux/slices/config/configService";

// State list with label and value pairs
const stateList = [
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chandigarh", value: "Chandigarh" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Dadra and Nagar Haveli", value: "Dadra and Nagar Haveli" },
  { label: "Daman and Diu", value: "Daman and Diu" },
  { label: "Delhi", value: "Delhi" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Lakshadweep", value: "Lakshadweep" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "Odisha", value: "Odisha" },
  { label: "Puducherry", value: "Puducherry" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" },
];

const SearchPatient = () => {
  const [showModal, setShowModal] = useState(false);
  const { configInfo } = useSelector((ReduxState: any) => ReduxState.config);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConfigInfoAPI());
  }, []);

  useEffect(() => {
    console.log("configInfo : ", configInfo);
  }, [configInfo]);

  // State to manage form inputs
  const [patientDetails, setPatientDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    secondaryEmail: "",
    secondaryPhone: "",
    gender: "",
    age: "",
    address: "",
    state: {
      label: "",
      value: "",
    },
    city: {
      label: "",
      value: "",
    },
    pincode: "",
    employeeId: "",
  });

  // State to manage form errors
  const [errorData, setErrorData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    age: "",
    gender: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    employeeId: "",
  });

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  // Function to handle input changes
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setPatientDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    // Clear error for the current field
    if (value.trim() !== "") {
      setErrorData((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  // Function to handle select changes
  const handleSelectChange = (selectedOption: any, actionMeta: any) => {
    const { name } = actionMeta;
    setPatientDetails((prevDetails) => ({
      ...prevDetails,
      [name]: selectedOption,
    }));

    // Clear error for the current field
    if (selectedOption) {
      setErrorData((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  // Validate form inputs
  const validateForm = () => {
    const errors: any = {};

    if (!patientDetails.firstName.trim()) {
      errors.firstName = "Please enter First Name";
    }
    if (!patientDetails.lastName.trim()) {
      errors.lastName = "Please enter Last Name";
    }
    if (!patientDetails.email.trim()) {
      errors.email = "Please enter Email";
    }
    if (!patientDetails.phone.trim()) {
      errors.phone = "Please enter Phone number";
    }
    if (!patientDetails.age.trim()) {
      errors.age = "Please enter Age";
    }
    if (!patientDetails.gender.trim()) {
      errors.gender = "Please select Gender";
    }
    if (!patientDetails.address.trim()) {
      errors.address = "Please enter Address";
    }
    if (!patientDetails.state) {
      errors.state = "Please select State";
    }
    if (!patientDetails.city) {
      errors.city = "Please select City";
    }
    if (!patientDetails.pincode.trim()) {
      errors.pincode = "Please enter Pincode";
    }
    if (!patientDetails.employeeId.trim()) {
      errors.employeeId = "Please enter Employee Id";
    }

    setErrorData(errors);
    return Object.keys(errors).length === 0;
  };

  const addNewPatient = () => {
    if (!validateForm()) {
      return;
    }
    const getPatientsData = async () => {
      const body = {
        patient: {
          first_name: patientDetails?.firstName,
          last_name: patientDetails?.lastName,
          email: patientDetails?.email,
          secondary_email: patientDetails?.secondaryEmail,
          phone: patientDetails?.phone,
          secondary_phone: patientDetails?.secondaryPhone,
          gender: patientDetails?.gender,
          age: parseInt(patientDetails?.age, 10),
          address: {
            address: patientDetails?.address || null,
            state: patientDetails?.state?.value || null,
            city: patientDetails?.city?.value || null,
            zip: patientDetails?.pincode || null,
          },
          employee_id: patientDetails?.employeeId || null,
        },
      };

      const res = await dispatch(addPatientAPI(body));
      const resJSON = JSON.parse(JSON.stringify(res));
      console.log("resJSON : ", resJSON);
      if (resJSON?.error?.message) {
        toast.error(resJSON.error.message);
      } else {
        dispatch(fetchPatientsAPI());
        handleClose();
      }
    };
    getPatientsData();
  };

  // Options for city dropdown based on configInfo
  const cityOptions =
    configInfo?.cities?.map((city: any) => ({
      label: city.name,
      value: city.id,
    })) || [];

  return (
    <>
      <div className="controls">
        <div className="left-controls">
          <div className="title">Patients List</div>
          <div className="search-bar">
            <Search />
            <input type="text" placeholder="Search Equipments" />
          </div>
          <div className="button-sort">
            <Dropdown className="custom-dropdown">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Today
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Last Week</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Last Month</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Last Year</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="custom-dropdown">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Sort By
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">
                  Price High to Low
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2">
                  Price Low to High
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <button className="add-patient-button" onClick={handleShow}>
          Add Patient
        </button>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        className="custom-modal"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Patient Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formName">
                  <Form.Label>First Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter First Name"
                    name="firstName"
                    value={patientDetails.firstName}
                    onChange={handleInputChange}
                    isInvalid={!!errorData.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formLastName">
                  <Form.Label>Last Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Last Name"
                    name="lastName"
                    value={patientDetails.lastName}
                    onChange={handleInputChange}
                    isInvalid={!!errorData.lastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email Id *</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Please enter Email Id"
                    name="email"
                    value={patientDetails.email}
                    onChange={handleInputChange}
                    isInvalid={!!errorData.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formSecondaryEmail">
                  <Form.Label>Secondary Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Please enter Secondary Email"
                    name="secondaryEmail"
                    value={patientDetails.secondaryEmail}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formMobileNumber">
                  <Form.Label>Mobile Number *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Mobile number"
                    name="phone"
                    value={patientDetails.phone}
                    onChange={handleInputChange}
                    isInvalid={!!errorData.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formSecondaryPhone">
                  <Form.Label>Secondary Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Secondary Phone"
                    name="secondaryPhone"
                    value={patientDetails.secondaryPhone}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formAge">
                  <Form.Label>Age *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Age"
                    name="age"
                    value={patientDetails.age}
                    onChange={handleInputChange}
                    isInvalid={!!errorData.age}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData.age}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formGender">
                  <Form.Label>Gender *</Form.Label>
                  <Form.Control
                    as="select"
                    name="gender"
                    value={patientDetails.gender}
                    onChange={handleInputChange}
                    isInvalid={!!errorData.gender}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errorData.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please type & select address"
                    name="address"
                    value={patientDetails.address}
                    onChange={handleInputChange}
                    isInvalid={!!errorData.address}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData.address}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formState">
                  <Form.Label>State</Form.Label>
                  <Select
                    name="state"
                    options={stateList}
                    value={patientDetails.state}
                    onChange={handleSelectChange}
                    isSearchable
                    isClearable
                    className={`react-select ${
                      errorData.state ? "is-invalid" : ""
                    }`}
                  />
                  {errorData.state && (
                    <div className="invalid-feedback d-block">
                      {errorData.state}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formCity">
                  <Form.Label>City</Form.Label>
                  <Select
                    name="city"
                    options={cityOptions}
                    value={patientDetails.city}
                    onChange={handleSelectChange}
                    isSearchable
                    isClearable
                    className={`react-select ${
                      errorData.city ? "is-invalid" : ""
                    }`}
                  />
                  {errorData.city && (
                    <div className="invalid-feedback d-block">
                      {errorData.city}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPincode">
                  <Form.Label>Pin code *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Pin code"
                    name="pincode"
                    value={patientDetails.pincode}
                    onChange={handleInputChange}
                    isInvalid={!!errorData.pincode}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData.pincode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formEmployeeId">
                  <Form.Label>Employee Id *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Employee Id"
                    name="employeeId"
                    value={patientDetails.employeeId}
                    onChange={handleInputChange}
                    isInvalid={!!errorData.employeeId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData.employeeId}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancelbtn" onClick={handleClose}>
            Cancel
          </button>
          <button className="save-btn" onClick={addNewPatient}>
            Save Details
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SearchPatient;
