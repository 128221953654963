import React, { useState } from 'react';
import { Button, Modal, Form, Image } from 'react-bootstrap';
import Success from "../../assets/images/successtwo.png"; 
import './style.css';


const ReminderModal = ({ show, handleClose }:any) => {
  const [reminderSent, setReminderSent] = useState(false);

  const handleSendReminder = () => {
    setReminderSent(true);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{reminderSent ? 'Reminder Sent' : 'Send Reminder'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {reminderSent ? (
          <div className="reminder-sent-content">
            <p>We've sent a reminder to <strong>Name</strong> for their appointment on May 5, 2024, at 10:00 AM.</p>
            <Image src={Success} alt="Success" className="mb-3" />
          </div>
        ) : (
          <Form.Group controlId="reminderMessage">
            <Form.Control as="textarea" rows={6} placeholder="Type your reminder message here..." />
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        {reminderSent ? (
          <Button variant="primary" onClick={handleClose} style={{ backgroundColor: '#A992FF', borderColor: '#A992FF' }}>
            Close
          </Button>
        ) : (
          <Button variant="primary" onClick={handleSendReminder} style={{ backgroundColor: '#A992FF', borderColor: '#A992FF' }}>
            Send Reminder
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ReminderModal;
