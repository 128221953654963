import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { BookingStyled } from "../Bookings/Bookings.styled";
import { useLocation } from "react-router-dom";

const OrderDetail = ({ clientOrders }: any) => {
  let location = useLocation();
  console.log(location, "location");

  console.log("clientOrders", clientOrders);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Calculate the current page's data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = clientOrders.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(clientOrders.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const formatDate = (timestamp: any) => {
    if (!timestamp) return "N/A";
    const date = new Date(parseInt(timestamp, 10));
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <BookingStyled>
      <div className="Order-main-div">
        <div className="ordertable">
          <Table responsive="sm">
            <thead>
              <tr>
                <th>Sl No</th>
                <th>Order Id</th>
                <th>Product Name</th>
                <th>Clients</th>
                {/* <th>Customer</th> */}
                {/* <th>Relation</th> */}
                <th>Order Date</th>
                <th>Completed Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentOrders.map((order: any, index: any) => (
                <tr key={index}>
                  <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                  <td>{order.id}</td>
                  <td>{order.productName || "N/A"}</td>
                  <td>{order.client.name}</td>
                  {/* <td>{order.customer || "N/A"}</td> */}
                  {/* <td>{order.relation || "N/A"}</td> */}
                  <td>{formatDate(order.created_at)}</td>
                  <td>{formatDate(order.invoice_date)}</td>
                  <td>{order.status || "Pending"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pagination">
            <Pagination className="custom-pagination">
              <Pagination.First onClick={() => handlePageChange(1)} />
              <Pagination.Prev
                onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
              />
              {[...Array(totalPages)].map((_, i) => (
                <Pagination.Item
                  key={i + 1}
                  active={i + 1 === currentPage}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() =>
                  handlePageChange(Math.min(totalPages, currentPage + 1))
                }
              />
              <Pagination.Last onClick={() => handlePageChange(totalPages)} />
            </Pagination>
          </div>
        </div>
      </div>
    </BookingStyled>
  );
};

export default OrderDetail;
