import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

import {
  constructAllAppointmentsQuery,
  constructAllBookingQuery,
  constructBookingQuery,
  getBookingIdDetails,
  getMyPackageDetailsQuery,
} from "../../../Scenes/graphqlConfig";

export const getDashboardInfo = createAsyncThunk(
  "auth/getDashboardInfo",
  async () => await get(`${SERVER_IP}/api/v1/dashboard`)
);

// export const getMyBookingsAPI = createAsyncThunk(
//   "auth/getMyBookingsAPI",
//   async (count: any) =>
//     await post(
//       `${SERVER_IP}/graphql`,
//       JSON.stringify(constructBookingQuery(count))
//     )
// );

export const getAllBookingListAPI = createAsyncThunk(
  "auth/getAllBookingListAPI",
  async (body: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(
        constructAllBookingQuery(
          body.count,
          body.searchText,
          body.searchFromDate,
          body.searchToDate,
          body.searchCheckbox
        )
      )
    )
);

export const getAllAppointmentsListAPI = createAsyncThunk(
  "auth/getAllAppointmentsListAPI",
  async (body: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(constructAllAppointmentsQuery(body))
    )
);

// export const getMyOrderAPI = createAsyncThunk(
//   "auth/getMyOrderAPI",
//   async (bookingId: any) => await post(`${SERVER_IP}/graphql`, JSON.stringify(getBookingIdDetails(bookingId)))
// );

// export const signUpUser = createAsyncThunk(
//   "auth/signUpUser",
//   async (body: any) => await post(`${SERVER_IP}/auth/register`, body)
// );
// export const updateUserDetails = createAsyncThunk(
//   "auth/updateUserDetails",
//   async (body: any) => await put(`${SERVER_IP}/user/update`, body)
// );
// export const getAllSubscriptions = createAsyncThunk(
//   "auth/getAllSubscriptions",
//   async () => await get(`${SERVER_IP}/api/v1/wallet`)
// );

// export const getMyPackageDetailsAPI = createAsyncThunk(
//   "auth/getMyPackageDetailsAPI",
//   async () => await post(`${SERVER_IP}/graphql`, JSON.stringify(getMyPackageDetailsQuery()))
// );
