import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from './Login.Styled';

interface Errors {
  establishmentName?: string;
  city?: string;
  locality?: string;
}

const EstablishmentDetails = () => {
  const history = useHistory();
  const [establishmentName, setEstablishmentName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [locality, setLocality] = useState<string>("");
  const [errors, setErrors] = useState<Errors>({});

  const validateForm = () => {
    const newErrors: Errors = {};
    if (!establishmentName) newErrors.establishmentName = "Establishment Name is required.";
    if (!city) newErrors.city = "City is required.";
    if (!locality) newErrors.locality = "Locality is required.";
    return newErrors;
  };

  const handleNextStep = () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      history.push("/register/progress-summary");
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Establishment basic details</h2>
            <Form>
              <Form.Group controlId="establishmentName">
                <Form.Label className="form-label">Establishment Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Type establishment name"
                  value={establishmentName}
                  onChange={(e) => setEstablishmentName(e.target.value)}
                  isInvalid={!!errors.establishmentName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.establishmentName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="city">
                <Form.Label className="form-label">City</Form.Label>
                <Form.Control
                  as="select"
                  value={city}
                  onChange={(e: any) => setCity(e.target.value)}
                  isInvalid={!!errors.city}
                >
                  <option>Type & select city</option>
                  <option>City 1</option>
                  <option>City 2</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="locality">
                <Form.Label className="form-label">Locality</Form.Label>
                <Form.Control
                  as="select"
                  value={locality}
                  onChange={(e) => setLocality(e.target.value)}
                  isInvalid={!!errors.locality}
                >
                  <option>Select Locality</option>
                  <option>Locality 1</option>
                  <option>Locality 2</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.locality}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="button-container">
                <Button className="back-button" onClick={() => history.push("/register/progress-summary")}>
                  <BackIcon /> Back
                </Button>
                <Button className="continue-button" onClick={handleNextStep}>
                  Next
                </Button>
              </div>
            </Form>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default EstablishmentDetails;
