import React from "react";

const info = [
  {
    name:"Ann Mariya",
  },
  {
    name:"Ann Mariya",
  },
  {
    name:"Ann Mariya",
  },
];


const Bookinginfo = () => {
  return (
    <div className="followup">
      {info.map((item, index) => (
      <div className="message">
        <p>Hi Ann Mariya,</p>
        <p>
          Your appointment with Dr. Syam CV has been successfully rescheduled to
          [New Date] at [New Time]. Please let us know if you have any further
          questions or need additional assistance.
        </p>
        <p>Thank you</p>
        <div className="check">
        <p>Apolo Clinic</p>
        <input type="checkbox" />
      </div>
      </div>
     ))}
    </div>
  );
};

export default Bookinginfo;
