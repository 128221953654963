import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ReactSelect from "react-select";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import ReminderModal from "./ReminderModal";

const Remaindersearch = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const options = [
    { value: "today", label: "Today" },
    { value: "this_week", label: "This week" },
    { value: "this_month", label: "This month" },
    { value: "last_3_months", label: "Last 3 months" },
    { value: "last_6_months", label: "Last 6 months" },
    { value: "this_year", label: "This year" },
  ];

  const optionscase = [
    { value: "dengue", label: "Dengue" },
    { value: "case1", label: "Case 1" },
    { value: "case2", label: "Case 2" },
    { value: "case3", label: "Case 3" },
    { value: "case4", label: "Case 4" },
    { value: "case5", label: "Case 5" },

  ];

  return (
    <div className="remainder-search">
      <div className="search-bar">
        <Search />
        <input type="text" placeholder="Search Order" />
      </div>

      {/* Clear All */}
      <Button variant="link" className="clear-all">
        Clear All
      </Button>

      <ReactSelect
        className="select-type"
        classNamePrefix="select"
        options={optionscase}
        defaultValue={optionscase[0]}
      />

      <ReactSelect
        className="select-type"
        classNamePrefix="select"
        options={options}
        defaultValue={options[0]}
      />

      <Button
        variant="primary"
        className="send-reminder-button"
        onClick={handleShowModal}
      >
        Send Reminder to All
      </Button>

      <ReminderModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default Remaindersearch;
