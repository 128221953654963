import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as RemainderIcon } from '../../assets/icons/remainder.svg';
import { ReactComponent as WhatsApp } from '../../assets/icons/whats-app.svg';
import { ReactComponent as Mail } from '../../assets/icons/gmail.svg';
import ReminderModal from './ReminderModal';  

const Remdetail = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const patientInfo = [
    {
      id: 1,
      name: 'Guy Hawkins',
      case: 'Dengue',
      visitDate: '26/10/2024, Tue',
      avatar: '/path-to-image.jpg',
    },
    {
      id: 2,
      name: 'Guy Hawkins',
      case: 'Dengue',
      visitDate: '26/10/2024, Tue',
      avatar: '/path-to-image.jpg',
    },
    {
      id: 3,
      name: 'Guy Hawkins',
      case: 'Dengue',
      visitDate: '26/10/2024, Tue',
      avatar: '/path-to-image.jpg',
    },
  ];

  return (
    <div className="rem-detail">
      {patientInfo.map((patient) => (
        <div key={patient.id} className="patient-info-container">
          <div className="patient-info">
            <img src={patient.avatar} alt={patient.name} className="patient-avatar" />
            <div className="info-text">
              <h3>{patient.name}</h3>
              <p>Case: {patient.case}</p>
              <p>Visit Date: {patient.visitDate}</p>
            </div>
          </div>
          <div className="actions">
            <Button 
              variant="primary" 
              className="action-button remainder"
              onClick={handleShow}
            >
              <RemainderIcon /> &nbsp; Send Reminder
            </Button>
            <Button variant="success" className="action-button whatsapp">
              <WhatsApp /> &nbsp; Whatsapp
            </Button>
            <Button variant="danger" className="action-button email">
              <Mail /> &nbsp; Send Email
            </Button>
          </div>
        </div>
      ))}

      <ReminderModal show={show} handleClose={handleClose} />
    </div>
  );
};

export default Remdetail;
