import styled from "styled-components";

export const CreateOrderStyled = styled.div`
 
  .category-buttons {
    display: flex;
    gap: 8px;
    margin-bottom: 20px;
  }

  .category-button {
    display: flex;
    align-items: center;
    padding: 10px 22px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 5px;
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
  }

  .category-button img {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }

  .category-button span {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .category-button.active {
    background-color: #e0d7ff;
  }

  .search-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .search-bar input {
    width: 50%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .filter-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .filter-button {
    margin: 0 10px;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 20px;
    cursor: pointer;
    background-color: white;
    transition: background-color 0.3s;
  }

  .filter-button.active {
    background-color: #e0d7ff;
  }

  .products {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .product-card {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    width: 200px;
    margin-bottom: 20px;
    text-align: center;
    transition: transform 0.3s;
  }

  .product-card:hover {
    transform: translateY(-5px);
  }

  .product-image {
    width: 100%;
    height: 100px;
    background-color: #e0e0e0;
    margin-bottom: 10px;
  }

  .product-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-name {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .product-quantity {
    color: #777;
    margin-bottom: 10px;
  }

  .product-price {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .add-button {
    padding: 10px 20px;
    background-color: #a675ff;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  /* Pharmacy */
  .pharmacy-det {
    margin: 0 auto;
    padding-right: 20px;
  }

  .search-bar-categories {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .search-barcat {
    flex: 1;
    margin-right: 20px;
  }

  .input-container {
    position: relative;
    width: 100%;
  }

  .search-barcat .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #ccc;
    cursor: pointer;
  }

  .search-barcat input {
    width: 100%;
    padding: 7px 7px 7px 30px; /* Adjusted padding to make space for the icon */
    border-radius: 5px;
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    color: var(--dummy, #808080);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
  }

  .categories {
    display: flex;
    gap: 10px;
  }

  .categories button {
    padding: 6px 20px;
    color: #c1a4a4;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #c1a4a4;
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
  }

  .categories .active {
    border-radius: 5px;
    border: 1px solid var(--Primary, #9747ff);
    background: var(--white, #fff);
    color: var(--Primary, #9747ff);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .medicine-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
  }

  .medicine-card {
    border-radius: 2px;
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }

  .medicine-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .medicine-card h3 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
  }

  .medicine-card p {
    color: var(--dummy, #808080);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
  .medicine-card .priceandbtn {
    display: flex;
    text-align: center;
    align-items: baseline;
    justify-content: space-between;
  }
  .medicine-card .priceandbtn p {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .medicine-card button {
    border: none;
    color: var(--white, #fff);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    border-radius: 5px;
    background: var(--Primary, #9747ff);
    padding: 6px 8px;
  }
  /* Search bar */
  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    padding-right: 20px;
  }

  .left-controls {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .left-controls .search-bar{
    justify-content: left;
  }

  .title {
    color: #747070;
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
  }
  .controls .search-bar {
    margin-bottom: 0;
  }
  .search-bar {
    display: flex;
    align-items: center;
    padding: 5px;
    width: 310px;
    border-radius: 5px;
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
  }

  .search-bar svg {
    margin-right: 5px;
  }

  .search-bar input {
    border: none;
    outline: none;
    padding: 5px;
    background: transparent;
    color: #ccc;
    font-size: 14px;
    font-weight: 400;
  }
  .savebtn {
    color: var(--white, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: var(--Primary, #9747ff);
  }

  .add-patient-button {
    padding: 12px 15px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 40px;
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 2px;
    background: #fff;
  }

  .add-patient-button {
    margin-left: auto;
    padding: 12px 20px;
    border-radius: 1px;
    background: var(--Primary, #9747ff);
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
    color: var(--white, #fff);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .custom-dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
    gap: 46px;
    width: 124px;
    height: 40px;
    padding: 9px 15px;
    flex-shrink: 0;
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 2px;
    background: #fff;
    border: none;
  }
  .custom-dropdown .dropdown-menu {
    border: none;
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .button-sort {
    display: flex;
    gap: 18px;
  }
  .right-buttons {
    display: flex;
    gap: 12px;
  }
  .create-orderbtn {
    color: #9474ff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 5px;
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border: #fff;
  }
  /* .cart-button {
    border: none;
    background: transparent;
  } */
  .upload-prescription-button {
    border-radius: 3px;
    border: 1px solid #ccc;
    background: var(--white, #fff);
    color: #6f5e5e;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 20px;
  }

  @media (max-width: 768px) {
    .controls {
      display: block;
      text-align: center;
    }
    .left-controls {
      display: block;
    }
    .search-bar {
      width: 100%;
      margin-bottom: 12px !important;
    }
    .upload-prescription-button{
      margin-bottom: 12px;
    }
    .right-buttons{
      justify-content: center;
    }
    .button-sort {
      text-align: center;
      justify-content: center;
    }
    .custom-dropdown {
      margin-bottom: 12px;
    }
    .add-patient-button {
      margin: 0%;
    }
    .category-buttons{
      display: grid;
      justify-content: center;
      grid-template-columns:1fr 1fr;
      margin: 12px;
    }
    .search-bar-categories{
      display: block;
    }
    .search-barcat{
      margin: 12px;
    }
    .categories{
      display: grid;
      margin: 12px;
      grid-template-columns:1fr 1fr;
    }
    .medicine-grid{
      grid-template-columns:1fr 1fr;
    }
    .ambulance-container{
      display: block !important;
    }
    .form-section{
      width: 100% !important;
      margin-bottom: 20px !important;
    }
    .ambulance-type-button{
      padding: 20px !important;
    }
    .top-section{
      display: block;
    }
    .top-section .search-bar{
      width: auto !important;
    }
    .consultation-buttons{
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .consultation-buttons button{
      padding:10px !important;
    }
    .cards-container{
      display: grid;
      margin-top: 12px;
    }
    .doctor-card{
      width: 100% !important;
    }
  }
  .Add-btn {
    width: 100%;
  }

  .ambulance-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .form-section {
    width: 40%;
    margin-right: 20px;
  }

  .location-input {
    background-color: #d0c1f3;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .icon-wrapper {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pickup-icon {
    color: green;
  }

  .drop-icon {
    color: red;
  }

  .location-input-field {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
    background-color: white;
    font-size: 16px;
  }

  .fare-display {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    margin-bottom: 40px;
    border-bottom: 1px solid #cccccc;
  }

  .fare-display p {
    margin: 0;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .fare-amount {
    font-weight: bold;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
  }

  .cancel-button,
  .confirm-button {
    flex: 1;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin: 0 5px;
  }

  .cancel-button {
    border-radius: 5px;
    border: 1px solid #ac8a8a;
    color: #b69d9d;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .confirm-button {
    border-radius: 5px;
    background: #159001;
    color: var(--white, #fff);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .map-section {
    width: 60%;
  }

  .ambulance-type-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .ambulance-type-button {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--dummy, #808080);
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    color: var(--dummy, #808080);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin: 0 5px;
  }

  .ambulance-type-button.active {
    border-radius: 5px;
    border: 1px solid var(--Primary, #9747ff);
    background: var(--white, #fff);
    color: var(--Primary, #9747ff);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .map-view {
    width: 100%;
    height: 200px;
    background-color: #e0e0e0;
    border-radius: 10px;
  }

  /* Hospital */
  .hospital-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
  }

  .top-section {
    display: flex;
    width: 100%;
    gap:12px;
  }
  .top-section .search-bar{
    justify-content: left;
    width:400px;
  }
  /* .search-bar {
    flex-grow: 1;
    margin-right: 20px;
}

.search-bar input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
} */

 

  .consultation-buttons button {
    padding: 10px 60px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    border-radius: 5px;
border: 1px solid #C1A4A4;
background: var(--white, #FFF);
box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.10);
color: #C1A4A4;
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-right: 12px;
  }

  .consultation-buttons button.active {
    border-radius: 5px;
border: 1px solid var(--Primary, #9747FF);
background: var(--white, #FFF);
color: var(--Primary, #9747FF);
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }

 

  .cards-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
  }

  .doctor-card {
    display: flex;
    flex-direction: column;
    width: 49%; /* Adjust the width as needed */
    border-radius: 5px;
    border: 1px solid var(--Primary, #9747ff);
    background: var(--white, #fff);
    margin-bottom: 20px;
  }

  .doctor-info {
    display: flex;
    align-items: center;
    margin: 12px;
  }

  .doctor-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
  }

  .doctor-details h2 {
    margin: 0;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .specialization {
    color: #00c9aa;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;
  }

  .rating {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .star {
    color: #ffb400;
  }

  .experience-languages {
    display: flex;
    gap: 5px;
    color: #757575;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .clinic-info {
    margin: 12px;
    display: flex;
    
    justify-content: space-between;
  }
  .clinic-info p {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 6px;
  }
  .clinic-info .fee {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
  }
  .clinic-info .clinicname {
    color: #000;
    opacity: 0.6;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .next-available {
    color: #388e3c;
    font-weight: bold;
  }

  .book-appointment {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background: var(--Primary, #9747ff);
    transition: background-color 0.3s;
    color: var(--white, #fff);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .appbtn {
    align-self: flex-end;
}
hr{
  margin: 0;
}
 
`;
