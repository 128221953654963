import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, InputGroup, Modal, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  getClintUserWalletAmountDetailsAPI,
  getClintUserWalletDetailsAPI,
  getClintWalletDetailsAPI,
  signinAsUserAPI,
} from "../../redux/slices/generic/genericService";
import { BookingStyled } from "../../components/Bookings/Bookings.styled";
import { IoEyeOffOutline } from "react-icons/io5";

const DetailsClintWallet = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const ID = location?.state as { id: string };
  const walletId = ID?.id;

  const [showOrder, setShowOrder] = useState(false);
  const [orderItem, setOrderItem] = useState(walletId);
  const [userdetail, setdetail] = useState<any>(null);
  const [bookingWallet, setBookingWallet] = useState<any>([]);
  const [bookingUserWallet, setBookingUserWallet] = useState<any>([]);
  const [userAmount, setUserAmount] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [selectedSection, setSelectedSection] = useState("wallets");
  const AllclientWalletTypes = bookingWallet?.clientWallets;
  const AllclientUserWalletTypes = bookingUserWallet?.clientUsers;
  const userId = userdetail?.id;

  console.log(userAmount, "userAmount");
  console.log("userId userId", userId);
  const handleCloseOrder = () => setShowOrder(false);

  const handleShowOrder = (item: any) => {
    setdetail(item);
    setShowOrder(true);
  };

  const filteredData = AllclientUserWalletTypes?.filter(
    (person: any) =>
      person.first_name.toLowerCase().includes(search.toLowerCase()) ||
      person.phone?.toString().includes(search.toString()) ||
      person.email?.toLowerCase().includes(search.toLowerCase())
  );
  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!walletId) return;

      try {
        // Fetch Client Wallet Details
        const res = await dispatch(getClintWalletDetailsAPI(walletId));
        const resJson = JSON.parse(JSON.stringify(res));
        setBookingWallet(resJson?.payload?.data);

        // Fetch Client User Wallet Details
        const userRes = await dispatch(getClintUserWalletDetailsAPI(walletId));
        const userResJson = JSON.parse(JSON.stringify(userRes));
        setBookingUserWallet(userResJson?.payload?.data);
      } catch (error) {
        console.error("Error fetching wallet details:", error);
      }
    };

    setOrderItem(walletId);
    fetchData();
  }, [walletId]);

  useEffect(() => {
    const fetchUserWallet = async () => {
      if (!userId) {
        return;
      }
      const res = await dispatch(getClintUserWalletAmountDetailsAPI(userId));
      const resJson = JSON.parse(JSON.stringify(res));
      setUserAmount(resJson?.payload?.data?.wallets);
    };
    fetchUserWallet();
  }, [userId]);

  const handleCreateOrder = async (userD: any) => {
    const res = (await dispatch(
      signinAsUserAPI({ userid: userD?.id?.toString() })
    )) as any;
    if (res?.payload?.data?.url) {
      window.location.href = res?.payload?.data?.url;
    }
  };

  return (
    <BookingStyled>
      <div className="appoint-order">
        <div className="Details-Clint-Wallet-select-div">
          <p
            className={`me-4 ${selectedSection === "wallets" ? "active" : ""}`}
            onClick={() => setSelectedSection("wallets")}
            style={{ cursor: "pointer" }}
          >
            Client Wallets
          </p>
          <p
            className={`${selectedSection === "users" ? "active" : ""}`}
            onClick={() => setSelectedSection("users")}
            style={{ cursor: "pointer" }}
          >
            Associated Users
          </p>
        </div>

        {selectedSection === "wallets" && (
          <div className="ordertable">
            <h5>Client User Details</h5>

            <Table responsive="sm mt-3">
              <thead>
                <tr>
                  <th>Wallet Name</th>
                  <th>Type</th>
                  <th>Discount </th>
                  <th>Limits</th>
                  <th>Specialization</th>
                </tr>
              </thead>
              <tbody>
                {AllclientWalletTypes?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>{item?.name}</td>
                    <td>{item?.type}</td>
                    <td>
                      {item?.discount_percentage
                        ? `${item.discount_percentage} %`
                        : item?.amount}
                    </td>
                    <td>{"Unlimited"}</td>
                    <td>
                      {item?.specialization ? item?.specialization : "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        {selectedSection === "users" && (
          <div className="ordertable">
            <h5>Associated Users Details</h5>
            <InputGroup className="mb-3 mt-2">
              <Form.Control
                placeholder="Search associated user by name, phone, or email"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className="shadow-none custom-border-radius"
                value={search}
                onChange={handleSearchChange}
              />
            </InputGroup>

            <Table responsive="sm mt-3">
              <thead>
                <tr>
                  <th>Contact Name</th>
                  <th>Mobile Number</th>
                  <th>Email ID</th>
                  <th>Wallet</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.length > 0 ? (
                  filteredData?.map((item: any, index: any) => (
                    <tr key={index}>
                      <td>
                        {item?.first_name} {item?.last_name}
                      </td>
                      <td>{item?.phone ? item?.phone : "N/A"}</td>
                      <td>{item?.email ? item?.email : "N/A"}</td>
                      <td>
                        <button
                          className="btn"
                          onClick={() => handleShowOrder(item)}
                        >
                          <IoEyeOffOutline />
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn create-order-btn"
                          onClick={() => {
                            handleCreateOrder(item);
                          }}
                        >
                          Create Order
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No results found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}
      </div>

      <Modal show={showOrder} onHide={handleCloseOrder}>
        <Modal.Header closeButton>
          <h4></h4>
        </Modal.Header>
        <BookingStyled>
          <Table responsive="sm">
            <thead>
              <tr>
                <th>Wallet Type</th>
                <th>Client Wallet Amount</th>
                <th>Remaining Amount</th>
                <th>Limits</th>
              </tr>
            </thead>
            <tbody>
              {userAmount.map((wallet: any) => (
                <tr key={wallet.id}>
                  <td>{wallet.name}</td>
                  <td>
                    {wallet?.total_amount
                      ? wallet?.total_amount
                      : `${wallet.discount_percentage}%`}
                  </td>
                  <td>{wallet?.amount ? wallet?.amount : 0}</td>

                  <td>
                    {wallet.limits === 9999
                      ? "Unlimited"
                      : `${Math.floor(wallet.limits / 100)}`}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </BookingStyled>
      </Modal>
    </BookingStyled>
  );
};

export default DetailsClintWallet;
