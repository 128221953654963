import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from "./Login.Styled";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  doctorRegistration,
  getDoctorSpecializationAPI,
} from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import { getConfigInfoAPI } from "../../redux/slices/config/configService";

const ProfileDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { configInfo } = useSelector((ReduxState: any) => ReduxState.config);
  const [specializations, setSpecializations] = useState([]);
  const [formError, setFormError] = useState<string | null>(null);

  // Generate city options for Select
  const citiesList = configInfo?.cities?.map((city: any) => ({
    label: city.name,
    value: city.name,
  }));

  // Generate specialization options for Select
  const specializationOptions = specializations.map(
    (specialization: string) => ({
      label: specialization,
      value: specialization,
    })
  );

  useEffect(() => {
    dispatch(getConfigInfoAPI());
  }, [dispatch]);

  useEffect(() => {
    const getDocSpecInfo = async () => {
      const res = await dispatch(getDoctorSpecializationAPI());
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("resJson : ", resJson);
      if (resJson?.error) {
        toast.error(resJson?.error?.message);
        return;
      }
      if (
        resJson?.payload?.data &&
        resJson?.payload?.data?.specializations?.length > 0
      ) {
        setSpecializations(resJson?.payload?.data?.specializations || []);
      }
    };
    getDocSpecInfo();
  }, [dispatch]);

  const [profileDetails, setProfileDetails] = useState<any>({
    name: "",
    specialization: null,
    gender: "",
    city: null,
    phone: "",
  });

  const [errorData, setErrorData] = useState({
    name: "",
    specialization: "",
    gender: "",
    city: "",
    phone: "",
  });

  const handlePhoneInputChange = (e: any) => {
    const { name, value } = e.target;

    // Allow only numbers and restrict the length to 10
    if (/^\d{0,10}$/.test(value)) {
      setProfileDetails((prevDetails: any) => ({
        ...prevDetails,
        [name]: value,
      }));

      if (value.trim() !== "") {
        setFormError(null);
        setErrorData((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setProfileDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (value.trim() !== "") {
      setFormError(null);
      setErrorData((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleCityChange = (selectedOption: any) => {
    setProfileDetails((prevDetails: any) => ({
      ...prevDetails,
      city: selectedOption,
    }));

    if (selectedOption) {
      setFormError(null);
      setErrorData((prevErrors) => ({
        ...prevErrors,
        city: "",
      }));
    }
  };

  const handleSpecializationChange = (selectedOption: any) => {
    setProfileDetails((prevDetails: any) => ({
      ...prevDetails,
      specialization: selectedOption,
    }));

    if (selectedOption) {
      setFormError(null);
      setErrorData((prevErrors) => ({
        ...prevErrors,
        specialization: "",
      }));
    }
  };

  const validateForm = () => {
    const errors = {} as any;

    if (!profileDetails.name.trim()) {
      errors.name = "Please enter your Name";
    }
    if (!profileDetails.specialization) {
      errors.specialization = "Please select Specialization";
    }
    if (!profileDetails.gender.trim()) {
      errors.gender = "Please select Gender";
    }
    if (!profileDetails.city) {
      errors.city = "Please select City";
    }
    if (!profileDetails.phone.trim()) {
      errors.phone = "Please enter your Phone number";
    }

    setErrorData(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (validateForm()) {
      const docRegisterBody = {
        name: profileDetails.name,
        specialization: profileDetails.specialization?.value,
        gender: profileDetails.gender,
        city: profileDetails.city?.value?.toLowerCase(),
        phone: profileDetails.phone,
      };

      const res = await dispatch(doctorRegistration(docRegisterBody));
      console.log("res : ", res);
      const resJson = JSON.parse(JSON.stringify(res));
      if (resJson?.error) {
        setFormError(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.success === true) {
        toast.success("Registration Successful");
        history.push("/register/medicalregistration");
      }
    }
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Hello Doctor! Let's create your dedicated profile.</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="name">
                <Form.Label className="form-label">Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please enter your Name"
                  name="name"
                  value={profileDetails.name}
                  onChange={handleInputChange}
                  isInvalid={!!errorData.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errorData.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="specialization">
                <Form.Label className="form-label">Specialization</Form.Label>
                <Select
                  name="specialization"
                  options={specializationOptions}
                  value={profileDetails.specialization}
                  onChange={handleSpecializationChange}
                  className={`react-select ${
                    errorData.specialization ? "is-invalid" : ""
                  }`}
                />
                {errorData.specialization && (
                  <div className="invalid-feedback d-block">
                    {errorData.specialization}
                  </div>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label className="form-label">Gender</Form.Label>
                <div className="gender-options">
                  <Form.Check
                    inline
                    type="radio"
                    label="Male"
                    name="gender"
                    value="male"
                    id="gender-male"
                    checked={profileDetails.gender === "male"}
                    onChange={handleInputChange}
                    isInvalid={!!errorData.gender}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Female"
                    name="gender"
                    value="female"
                    id="gender-female"
                    checked={profileDetails.gender === "female"}
                    onChange={handleInputChange}
                    isInvalid={!!errorData.gender}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Other"
                    name="gender"
                    value="other"
                    id="gender-other"
                    checked={profileDetails.gender === "other"}
                    onChange={handleInputChange}
                    isInvalid={!!errorData.gender}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData.gender}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Form.Group controlId="phone">
                <Form.Label className="form-label">Phone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please enter your Phone number"
                  name="phone"
                  value={profileDetails.phone}
                  onChange={handlePhoneInputChange}
                  isInvalid={!!errorData.phone}
                  maxLength={10}
                />
                <Form.Control.Feedback type="invalid">
                  {errorData.phone}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="city">
                <Form.Label className="form-label">City</Form.Label>
                <Select
                  name="city"
                  options={citiesList}
                  value={profileDetails.city}
                  onChange={handleCityChange}
                  className={`react-select ${
                    errorData.city ? "is-invalid" : ""
                  }`}
                />
                {errorData.city && (
                  <div className="invalid-feedback d-block">
                    {errorData.city}
                  </div>
                )}
              </Form.Group>

              {formError && <div className="error-message">{formError}</div>}
              <Button className="continue-button" type="submit">
                Continue
              </Button>
            </Form>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default ProfileDetails;
