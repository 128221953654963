import React, { useState } from "react";
import { ReactComponent as Searchbar } from "../../../assets/icons/search.svg";
import { Modal, Button, Form } from "react-bootstrap";

const allMedicines = [
  {
    name: "Glocomids 500 Mg",
    pieces: "10 Pieces",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Branded",
  },
  {
    name: "Generic Medicine ",
    pieces: "10 Pieces",
    price: "₹ 100.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Generic",
  },
  {
    name: "Ayurveda Medicine ",
    pieces: "10 Pieces",
    price: "₹ 150.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Ayurveda",
  },
  {
    name: "Homeopathy Medicine ",
    pieces: "10 Pieces",
    price: "₹ 120.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Homeopathy",
  },
  {
    name: "Glocomids 500 Mg",
    pieces: "10 Pieces",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Branded",
  },
  {
    name: "Generic Medicine ",
    pieces: "10 Pieces",
    price: "₹ 100.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Generic",
  },
  {
    name: "Ayurveda Medicine ",
    pieces: "10 Pieces",
    price: "₹ 150.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Ayurveda",
  },
  {
    name: "Homeopathy Medicine ",
    pieces: "10 Pieces",
    price: "₹ 120.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Homeopathy",
  },
  {
    name: "Glocomids 500 Mg",
    pieces: "10 Pieces",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Branded",
  },
  {
    name: "Generic Medicine ",
    pieces: "10 Pieces",
    price: "₹ 100.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Generic",
  },
  {
    name: "Ayurveda Medicine ",
    pieces: "10 Pieces",
    price: "₹ 150.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Ayurveda",
  },
  {
    name: "Homeopathy Medicine ",
    pieces: "10 Pieces",
    price: "₹ 120.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Homeopathy",
  },
  {
    name: "Glocomids 500 Mg",
    pieces: "10 Pieces",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Branded",
  },
  {
    name: "Generic Medicine ",
    pieces: "10 Pieces",
    price: "₹ 100.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Generic",
  },
  {
    name: "Ayurveda Medicine ",
    pieces: "10 Pieces",
    price: "₹ 150.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Ayurveda",
  },
  {
    name: "Homeopathy Medicine ",
    pieces: "10 Pieces",
    price: "₹ 120.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Homeopathy",
  },
  {
    name: "Glocomids 500 Mg",
    pieces: "10 Pieces",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Branded",
  },
  {
    name: "Generic Medicine ",
    pieces: "10 Pieces",
    price: "₹ 100.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Generic",
  },
  {
    name: "Ayurveda Medicine ",
    pieces: "10 Pieces",
    price: "₹ 150.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Ayurveda",
  },
  {
    name: "Homeopathy Medicine ",
    pieces: "10 Pieces",
    price: "₹ 120.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Homeopathy",
  },
  // Add more medicines if needed
];

const PharmacyDetails = () => {
  const [selectedCategory, setSelectedCategory] = useState("Branded");
  const [showModal, setShowModal] = useState(false);
  const [selectedMedicine, setSelectedMedicine] = useState(null);

  const handleCategoryChange = (category:any) => {
    setSelectedCategory(category);
  };

  const handleAddClick = (medicine:any) => {
    setSelectedMedicine(medicine);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const filteredMedicines = allMedicines.filter(
    (medicine) => medicine.category === selectedCategory
  );

  return (
    <div className="pharmacy-det">
      <div className="search-bar-categories">
        <div className="search-barcat">
          <div className="input-container">
            <span className="search-icon">
              <Searchbar />
            </span>
            <input type="text" placeholder="Search Medicines" />
          </div>
        </div>

        <div className="categories">
          <button
            className={selectedCategory === "Branded" ? "active" : ""}
            onClick={() => handleCategoryChange("Branded")}
          >
            Branded
          </button>
          <button
            className={selectedCategory === "Generic" ? "active" : ""}
            onClick={() => handleCategoryChange("Generic")}
          >
            Generic
          </button>
          <button
            className={selectedCategory === "Ayurveda" ? "active" : ""}
            onClick={() => handleCategoryChange("Ayurveda")}
          >
            Ayurveda
          </button>
          <button
            className={selectedCategory === "Homeopathy" ? "active" : ""}
            onClick={() => handleCategoryChange("Homeopathy")}
          >
            Homeopathy
          </button>
        </div>
      </div>

      <div className="medicine-grid">
        {filteredMedicines.map((medicine, index) => (
          <div key={index} className="medicine-card">
            <img src={medicine.imageUrl} alt={medicine.name} />
            <h3>{medicine.name}</h3>
            <p>{medicine.pieces}</p>
            <div className="priceandbtn">
              <p>{medicine.price}</p>
              <button onClick={() => handleAddClick(medicine)}>Add</button>
            </div>
          </div>
        ))}
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Medicine</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="noOfDays">
              <Form.Label>No of days</Form.Label>
              <Form.Control type="number" placeholder="Enter number of days" />
            </Form.Group>
            <Form.Group controlId="frequency">
              <Form.Label>Frequency</Form.Label>
              <Form.Control as="select">
                <option>Once a day</option>
                <option>Twice a day</option>
                <option>Thrice a day</option>
                <option>Four times a day</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="intake">
              <Form.Label>Intake</Form.Label>
              <Form.Control as="select">
                <option>Before meal</option>
                <option>After meal</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="startDate">
              <Form.Label>Start date</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PharmacyDetails;
