import React from "react";
import { RemainderStyled } from "./Remainder.styled";
import Remaindersearch from "./Remaindersearch";
import Remdetail from "./Remdetail";


const Remainder = () => {
  return (
    <RemainderStyled>
      <div className="remainder">
        <Remaindersearch />
        <Remdetail />
      </div>
    </RemainderStyled>
  );
};

export default Remainder;
