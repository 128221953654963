import React, { useState } from "react";
import { Tabs, Tab, Card, Button, Modal, Image } from "react-bootstrap";
import Iphone from "../../assets/images/iphone.png"; 
import Followup from "./Followup";
import Wellwishing from "./Wellwishing";
import Reminder from "./Reminder";
import Bookinginfo from "./Bookinginfo";
import Prescription from "./Prescription";
import UploadWA from "./UploadWA";
import SuccessImage from "../../assets/images/success.png"; 

const Communication = () => {
  const [activeTab, setActiveTab] = useState<string>("well-wishing");
  const [showModal, setShowModal] = useState<boolean>(false);

  // Function to handle tab selection
  const handleSelect = (k: string | null) => {
    if (k !== null) {
      setActiveTab(k);
    }
  };

  // Function to handle "Send" button click
  const handleSendClick = () => {
    setShowModal(true);
  };

  // Function to handle modal close
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="communication">
      <div className="row">
        <div className="col-12 col-md-10">
          <Tabs
            id="communication-tabs"
            activeKey={activeTab}
            onSelect={handleSelect}
            className="mb-3"
          >
            <Tab
              eventKey="appointments-follow-up"
              title="Appointments Follow up"
            >
              <Card>
                <Card.Body>
                  <Followup />
                </Card.Body>
              </Card>
              <button className="send-btn" onClick={handleSendClick}>
                Send
              </button>
            </Tab>
            <Tab eventKey="well-wishing" title="Well Wishing">
              <Card>
                <Card.Body>
                  <Wellwishing />
                </Card.Body>
              </Card>
              <button className="send-btn" onClick={handleSendClick}>
                Send
              </button>
            </Tab>
            <Tab eventKey="reminder" title="Reminder">
              <Card>
                <Card.Body>
                  <Reminder />
                </Card.Body>
              </Card>
              <button className="send-btn" onClick={handleSendClick}>
                Send
              </button>
            </Tab>
            <Tab eventKey="booking-info" title="Booking Info">
              <Card>
                <Card.Body>
                  <Bookinginfo />
                </Card.Body>
              </Card>
              <button className="send-btn" onClick={handleSendClick}>
                Send
              </button>
            </Tab>
            <Tab eventKey="prescription" title="Prescription">
              <Card>
                <Card.Body>
                  <Prescription />
                </Card.Body>
              </Card>
              <button className="send-btn" onClick={handleSendClick}>
                Send
              </button>
            </Tab>
            <Tab eventKey="upload-wa-status" title="Upload WA Status">
              <Card>
                <Card.Body>
                  <UploadWA />
                </Card.Body>
              </Card>
              <button className="send-btn" onClick={handleSendClick}>
                Send
              </button>
            </Tab>
          </Tabs>
        </div>
        <div className="col-12 col-md-2">
          <Image src={Iphone} className="roundedImages profile-pic" />
        </div>
      </div>

      {/* Modal for showing success message */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sent Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Image src={SuccessImage} alt="Success" className="mb-3" />
          <p>Your message was sent successfully to the selected users.</p>
          <Button className="send-btn" onClick={handleClose}>
            Send More
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Communication;
