import React, { useState, useEffect } from "react";
import { OrderStyled } from "./Orders.Styled";
import SearchOrder from "./SearchOrder";
import OrderDetail from "./Orderdetail";
import { useDispatch } from "react-redux";
import { getClientOrdersAPI } from "../../redux/slices/orders/orderService";

const Orders = () => {
  const [searchQuery, setSearchQuery] = useState<{
    text: string;
    status: string;
  }>({ text: "", status: "" });
  const [clientOrders, setClientOrders] = useState<any[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchClientOrders = async () => {
      const res = await dispatch(getClientOrdersAPI());
      const resJson = JSON.parse(JSON.stringify(res));
      if (resJson?.payload?.data?.clientOrders) {
        setClientOrders(resJson.payload.data.clientOrders);
      }
    };
    fetchClientOrders();
  }, [dispatch]);

  useEffect(() => {
    let orders = clientOrders;

    if (searchQuery.text) {
      orders = orders.filter((order) =>
        order.client.name.toLowerCase().includes(searchQuery.text.toLowerCase())
      );
    }

    if (searchQuery.status) {
      orders = orders.filter((order) => order.status === searchQuery.status);
    }

    setFilteredOrders(orders);
  }, [searchQuery, clientOrders]);

  return (
    <OrderStyled>
      <div className="order-page">
        <h2 className="sectionHeading">Orders</h2>
        <SearchOrder
          searchQuery={searchQuery.text}
          setSearchQuery={setSearchQuery}
          clientOrders={clientOrders}
        />
        <OrderDetail clientOrders={filteredOrders} />
      </div>
    </OrderStyled>
  );
};

export default Orders;
