import React, { useState } from "react";
import { ReactComponent as Searchbar } from "../../../assets/icons/search.svg";

const allMedicines = [
  {
    name: "Body Scan",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "FullBody",
  },
  {
    name: "Body Scan",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "FullBody",
  },
  {
    name: "Body Scan",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "FullBody",
  },
  {
    name: "Body Scan",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "FullBody",
  },
  {
    name: "Body Scan",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "FullBody",
  },
  {
    name: "Body Scan",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "FullBody",
  },
  {
    name: "Body Scan",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "FullBody",
  },
  {
    name: "Body Scan",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "FullBody",
  },
  {
    name: "Body Scan",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "FullBody",
  },
];

const RadiologyDetails = () => {
  const [selectedCategory, setSelectedCategory] = useState("FullBody");

  const handleCategoryChange = (category:any) => {
    setSelectedCategory(category);
  };

  const filteredMedicines = allMedicines.filter(
    (medicine) => medicine.category === selectedCategory
  );

  return (
    <div className="pharmacy-det">
      <div className="search-bar-categories">
        <div className="search-barcat">
          <div className="input-container">
            <span className="search-icon">
              <Searchbar />
            </span>
            <input type="text" placeholder="Search Lab Test" />
          </div>
        </div>

        <div className="categories">
          <button
            className={selectedCategory === "FullBody" ? "active" : ""}
            onClick={() => handleCategoryChange("FullBody")}
          >
            Full Body
          </button>
          <button
            className={selectedCategory === "WomenHealth" ? "active" : ""}
            onClick={() => handleCategoryChange("WomenHealth")}
          >
            Women Health
          </button>
          <button
            className={selectedCategory === "Allergy" ? "active" : ""}
            onClick={() => handleCategoryChange("Allergy")}
          >
            Allergy
          </button>
          <button
            className={selectedCategory === "SexualHealth" ? "active" : ""}
            onClick={() => handleCategoryChange("SexualHealth")}
          >
            Sexual Health
          </button>
          <button
            className={selectedCategory === "SeniorCitizen" ? "active" : ""}
            onClick={() => handleCategoryChange("SeniorCitizen")}
          >
            Senior Citizen
          </button>
        </div>
      </div>

      <div className="medicine-grid">
        {filteredMedicines.map((medicine, index) => (
          <div key={index} className="medicine-card">
            <img src={medicine.imageUrl} alt={medicine.name} />
            <h3>{medicine.name}</h3>
            <div className="priceandbtn">
              <p>{medicine.price}</p>
              <button>Add</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadiologyDetails;
