// ConnectPractice.js
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from './Login.Styled';

const ConnectPractice = () => {
  const history = useHistory();
  const [practiceDetails, setPracticeDetails] = useState({
    establishment: '',
  });

  const [errorData, setErrorData] = useState({
    establishment: '',
  });

  const handleInputChange = (e:any) => {
    const { name, value } = e.target;
    setPracticeDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (value.trim() !== "") {
      setErrorData((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const errors = {} as any;

    if (!practiceDetails.establishment.trim()) {
      errors.establishment = "Please select an option";
    }

    setErrorData(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    if (validateForm()) {
      history.push("/register/establishment-details");
    }
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Connect a practice</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className="form-label">
                  Please select any one of the following
                </Form.Label>
                <div className="practice-options">
                  <Form.Check
                    inline
                    type="radio"
                    label="I own an establishment"
                    name="establishment"
                    value="own"
                    onChange={handleInputChange}
                    isInvalid={!!errorData.establishment}
                    className="form-checkone"
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="I visit an establishment"
                    name="establishment"
                    value="visit"
                    onChange={handleInputChange}
                    isInvalid={!!errorData.establishment}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData.establishment}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <div className="button-container">
                <Button className="back-button" onClick={() => history.push("/register/education-qualification")}>
                  <BackIcon /> Back
                </Button>
                <Button className="continue-button" type="submit">
                  Next
                </Button>
              </div>
            </Form>
            <div className="info-box">
              Patients frequently look for doctors by locality. Thus, listing all
              your practice locations ensures your profile appears in all relevant
              searches.
            </div>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default ConnectPractice;
