import styled from "styled-components";

export const BookingStyled = styled.div`
  width: 100%;
  .priscription-main-div {
    padding: 0 20px;
  }
  .Order-main-div {
    padding: 0px;
  }
  .booking-main-div {
    padding: 0 20px;
  }
  .searcharfilter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .search-bar input {
    margin-left: 1rem;
  }
  .search-bar svg {
    margin-right: 1rem;
  }

  .search-bar {
    width: 406px;
    padding: 0;
    font-size: 14px;
    border: none;
    border-radius: 6px;
    background: #fff;
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    color: black;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    min-height: 40px;
    input {
      border: none;
      border-radius: 10px;
      width: 100%;
    }
    input:focus {
      outline: none;
    }
  }

  .filterplace {
    display: flex;
    gap: 10px;
  }

  .sort-select {
    position: relative;
    display: inline-block;
  }

  .sort-select select {
    width: 250px;
    padding: 8px 30px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #f8eeee;
    cursor: pointer;
    color: #2e2727;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-appearance: none; /* Remove default arrow in Chrome, Safari, Opera */
    -moz-appearance: none; /* Remove default arrow in Firefox */
    appearance: none; /* Remove default arrow in Edge */
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTggMTJsLTYtNi43NWwuNzUtLjc1IDUuMjUgNS4xMzcgNS4yNS01LjEzN0wxNCA1LjI1eiIvPjwvc3ZnPg==")
      no-repeat right 8px center; /* Custom arrow */
    background-color: #fff; /* Set background color to white */
  }

  .date-select {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }

  .date-select p {
    font-size: 14px;
    margin: 0;
    font-weight: 400;
  }

  .date-select input {
    height: 1rem;
    margin: 0;
  }
  .filter-button {
    padding: 8px 30px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #f8eeee;
    background: #fff;
    cursor: pointer;
    color: #2e2727;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .filter-button:hover {
    background-color: #e2e2e2;
  }

  .appoint-order {
    padding: 0 20px;
  }
  .ordertable {
    margin-top: 12px;
    width: 100%;
    overflow-x: scroll;
  }

  .ordertable Table {
    --bs-table-bg: transparent !important;
    border-radius: 5px;
    border: 1px solid #d6cece;
  }
  .ordertable thead {
    border-radius: 5px 5px 0px 0px;
    background: #d3edfc;
  }
  .ordertable th {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-wrap: nowrap;
  }
  .ordertable td {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-wrap: nowrap;
  }
  .ordertable tr {
    border: 1px solid #d6cece;
  }
  .ordertable .action-report {
    text-align: center;
  }
  .ordertable .action-report svg {
    cursor: pointer !important;
  }
  .orderTable .actions {
    display: flex;
    align-items: center;
  }
  .ordertable .actions {
    text-align: center;
  }
  .ordertable .actions svg {
    cursor: pointer;
  }
  .ordertable .icon-space {
    margin: 0 6px;
  }
  @media (max-width: 768px) {
    .ordertable .icon-space {
      margin: 0 0;
    }
    .searcharfilter {
      display: block;
      padding: 12px;
    }
    .filterplace {
      padding: 6px;
      justify-content: center;
    }
    .search-bar input {
      width: 100%;
    }
  }
  .custom-png {
    border: none;
    background: none;
  }
  .pagination {
    justify-content: center;
  }
  .custom-pagination .page-item.active .page-link {
    background-color: #7c4dff;
    border-color: #7c4dff;
    color: white;
  }

  .custom-pagination .page-link {
    color: black;
  }

  .custom-pagination .page-link:hover {
    background-color: #ccbbfa;
  }
  .download-btn {
    border: 1px solid #d3edfc;
    background: #fff;
  }

  .clear {
    clear: both;
  }

  .checkBox {
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 0px 2px #d8d8d8;
  }

  .checkBox div {
    width: 60px;
    height: 60px;
    background-color: #7c4dff;
    top: -52px;
    left: -52px;
    position: absolute;
    transform: rotateZ(45deg);
    z-index: 100;
  }

  .checkBox input[type="checkbox"]:checked + div {
    left: -10px;
    top: -10px;
    border: 1px solid #7c4dff;
  }

  .checkBox input[type="checkbox"] {
    position: absolute;
    left: 50px;
    visibility: hidden;
  }

  .transition {
    transition: 300ms ease;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-top: 1rem;
    margin-bottom: 0.8rem;
  }

  .content {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .checkBox {
    margin-right: 8px;
  }
  input[type="date"] {
    margin-left: 10px;
    margin-right: 20px;
  }
  .report-imagei-div {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  .report-imagei-div img {
    width: 15rem;
  }
  .report-imagei-div button {
    border: 1px solid #7c4dff;
    color: #7c4dff;
  }
  .search-bar {
    display: flex;
    align-items: center;
    width: 406px;
    border-radius: 5px;
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
  }
  .search-bar svg {
    margin-right: 5px;
    margin-left: 15px;
  }
  .search-bar input {
    border: none;
    outline: none;
    padding: 5px;
    background: transparent;
    color: #ccc;
    font-size: 14px;
    font-weight: 400;
  }
  .rebooking-prescription-div {
    display: flex;
    justify-content: end;
  }
  .rebooking-cancel-btn {
    color: rgb(31 41 55);
    background: rgb(209 213 219);
    font-weight: 600;
    letter-spacing: 1px;
  }
  .rebooking-confirm-btn {
    color: #fff;
    background: rgb(124, 77, 255);
    font-weight: 600;
    letter-spacing: 1px;
  }
`;
