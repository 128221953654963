import React, { useEffect, useState } from "react";
import {
  Table,
  InputGroup,
  FormControl,
  Button,
  Modal,
  Form,
  Card,
} from "react-bootstrap";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import { TableComponentStyled } from "./TableComponent.styled";
import { fetchClientsAPI } from "../../redux/slices/myPatients/myPatientsService";
import { useDispatch, useSelector } from "react-redux";
import { getConfigInfoAPI } from "../../redux/slices/config/configService";
import { addClientAPI } from "../../redux/slices/orders/orderService";
import { toast } from "react-toastify";
import { FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const TableComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { configInfo } = useSelector((ReduxState: any) => ReduxState.config);
  const [cities, setCities] = useState(configInfo?.cities || []);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [showClientModal, setShowClientModal] = useState(false);

  const [clientDetails, setClientDetails] = useState({
    companyName: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    maxUsers: "",
    dependentsPerUser: "",
    parentCompany: "",
    logo: null,
  });

  useEffect(() => {
    dispatch(getConfigInfoAPI());
  }, [dispatch]);

  useEffect(() => {
    setCities(configInfo?.cities || []);
  }, [configInfo]);

  useEffect(() => {
    const getClientsInfo = async () => {
      try {
        setLoading(true);
        const res = await dispatch(fetchClientsAPI());
        const resJson = JSON.parse(JSON.stringify(res));
        if (resJson?.payload?.data?.clients) {
          setClients(resJson.payload.data.clients);
          setFilteredClients(resJson.payload.data.clients);
        } else {
          throw new Error("No client data found");
        }
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    getClientsInfo();
  }, [dispatch]);

  useEffect(() => {
    const results = clients.filter(
      (client: any) =>
        client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (client.parentClient?.name &&
          client.parentClient.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
    );
    setFilteredClients(results);
  }, [searchTerm, clients]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setClientDetails({
      companyName: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      maxUsers: "",
      dependentsPerUser: "",
      parentCompany: "",
      logo: null,
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setClientDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCityChange = (selectedOption: any) => {
    setClientDetails((prev) => ({
      ...prev,
      city: selectedOption.value,
    }));
  };

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      setClientDetails((prev) => ({
        ...prev,
        logo: e.target.files[0],
      }));
    }
  };

  const handleSaveClient = async () => {
    console.log(clientDetails);
    const clientBody = {
      client: {
        name: clientDetails.companyName,
        address: clientDetails.address,
        state: clientDetails.state,
        city: clientDetails.city,
        zip: clientDetails.pincode,
        logo_url: clientDetails.logo,
        parent_id: null,
        user_max: clientDetails.maxUsers,
        dependent_per_user: clientDetails.dependentsPerUser,
        booking_key: "JI",
      },
    };
    const res = await dispatch(addClientAPI(clientBody));
    const resJson = JSON.parse(JSON.stringify(res));
    console.log("resJson", resJson);
    if (resJson?.error?.message) {
      toast.error(resJson?.error?.message);
      return;
    } else {
      toast.success("Client added successfully");
    }

    handleCloseModal();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const cityOptions = cities.map((city: any) => ({
    value: city.name,
    label: city.name,
  }));

  const selectClient = (client: any) => {
    setSelectedClient(client); // Store the selected client
    setShowClientModal(true); // Show the modal
  };
  const handleToNavigetWallet = (client: any) => {
    console.log(client, "client", client);

    history.push("/clients/detailsclintwallet", client);
  };
  // () => selectClient(client);
  return (
    <TableComponentStyled>
      <div className="table-container">
        <h2>Clients</h2>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Search client by name or parent name"
            aria-label="Search client"
            value={searchTerm}
            onChange={handleSearch}
          />
          {/* <FaSearch/> */}
          <Button variant="primary" onClick={handleShowModal}>
            + Client
          </Button>
        </InputGroup>

        <Table responsive className="custom-table">
          <thead>
            <tr>
              <th>ID #</th>
              <th>Client Name</th>
              <th>Parent</th>
            </tr>
          </thead>
          <tbody>
            {filteredClients.map((client: any) => (
              <tr key={client.id}>
                <td
                  style={{
                    color: "purple",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => handleToNavigetWallet(client)}
                >
                  {client.id}
                </td>

                <td>{client.name}</td>
                <td>{client.parentClient?.name || "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Modal for Adding Client Details */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Client Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="companyName">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Company Name"
                  name="companyName"
                  value={clientDetails.companyName}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  name="address"
                  value={clientDetails.address}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Select
                  options={cityOptions}
                  value={cityOptions.find(
                    (option: any) => option.value === clientDetails.city
                  )}
                  onChange={handleCityChange}
                  placeholder="Select City"
                />
              </Form.Group>

              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter State"
                  name="state"
                  value={clientDetails.state}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="pincode">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Pincode"
                  name="pincode"
                  value={clientDetails.pincode}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="maxUsers">
                <Form.Label>Max Users</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Max Users"
                  name="maxUsers"
                  value={clientDetails.maxUsers}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="dependentsPerUser">
                <Form.Label>Dependents per User</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Dependents per User"
                  name="dependentsPerUser"
                  value={clientDetails.dependentsPerUser}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="parentCompany">
                <Form.Label>Parent Company</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Parent Company"
                  name="parentCompany"
                  value={clientDetails.parentCompany}
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* <Form.Group controlId="logo">
                <Form.Label>Upload Logo</Form.Label>
                <Form.Control
                  type="file"
                  name="logo"
                  onChange={handleFileChange}
                />
              </Form.Group> */}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveClient}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showClientModal}
          onHide={() => setShowClientModal(false)}
          dialogClassName="modal-wider" // Custom class for wider modal
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedClient?.name || "Client Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                {selectedClient?.packages.map((pkg: any) => (
                  <div className="col-md-6 mb-3" key={pkg.service_code}>
                    <Card className="h-100">
                      <Card.Img
                        variant="top"
                        src={pkg.image || "https://via.placeholder.com/150"}
                        alt={pkg.service_name}
                        style={{ height: "150px", objectFit: "contain" }} // Adjust image size
                      />
                      <Card.Body>
                        <Card.Title>{pkg.service_name}</Card.Title>
                        <Card.Text>
                          Price: ₹{pkg.price.discounted_price}
                        </Card.Text>
                        <Card.Text>
                          <strong>{pkg?.tests?.length} Tests available</strong>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowClientModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </TableComponentStyled>
  );
};

export default TableComponent;
