// Ordertable.tsx
import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Profile from "../../../assets/images/profile.png";
import { ReactComponent as Pharmacy } from "../../../assets/icons/pharmacyicon.svg";
import { ReactComponent as Labtest } from "../../../assets/icons/labtesticon.svg";
import { ReactComponent as Radiology } from "../../../assets/icons/radiologyicon.svg";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { Table } from "antd";
import { AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import PreviewModal from "./Preview";

const Ordertable = () => {
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handlePreview = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAccordionSelect = (key: string | null) => {
    if (key !== null) {
      setActiveKey(key);
    }
  };

  const DetOrder = [
    {
      name: "Abhay Gupta",
      patientid: "212345",
      age: 22,
      gender: "Male",
      date: "20-08-2024",
      cat1: "Pharmacy",
      itemno1: "3",
      orderid: "32456",
      delivery: "Delivered",
      cat2: "Labtest",
      itemno2: "2",
      pending: "Pending",
      cat3: "Radiology",
      itemno3: "1",
      cancel: "Cancelled",
    },
  ];

  const dataSource = [
    {
      key: "1",
      no: "1",
      name: "Dolo",
      dose: "650 MG",
      type: "Tablet",
      qty: "10",
      frequency: "1-1-1",
      frequency2: "1-1-1",
      intake: "After Food",
      price: "19.00",
      margin: "19.00",
    },
    {
      key: "2",
      no: "2",
      name: "Dolo",
      dose: "650 MG",
      type: "Tablet",
      qty: "10",
      frequency: "1-1-1",
      frequency2: "1-1-1",
      intake: "After Food",
      price: "19.00",
      margin: "19.00",
    },
  ];

  const labtestDataSource = [
    {
      key: "1",
      no: "01",
      name: "Lipid Profile",
      type: "Lab Test",
      parameters: "3",
      price: "199.00",
      margin: "99.00",
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Dose",
      dataIndex: "dose",
      key: "dose",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
    },
    {
      title: "Frequency",
      dataIndex: "frequency2",
      key: "frequency2",
    },
    {
      title: "Intake",
      dataIndex: "intake",
      key: "intake",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Margin",
      dataIndex: "margin",
      key: "margin",
    },
  ];

  const labtestColumns = [
    {
      title: "Sl No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Lab Test Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Parameters",
      dataIndex: "parameters",
      key: "parameters",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Margin",
      dataIndex: "margin",
      key: "margin",
    },
  ];

  return (
    <div>
      {isVisible &&
        DetOrder.map((item, index) => (
          <div className="orderdetail" key={index}>
            <div className="orderdetail-header">
              <div className="orderdetail-header-left">
                <Image src={Profile} className="roundedImages profile-pic" />
                <div className="patient-info">
                  <h2>
                    {item.name}{" "}
                    <span className="patient-id">#{item.patientid}</span>
                  </h2>
                  <p>Age : {item.age}</p>
                  <p>Gender : {item.gender}</p>
                </div>
              </div>
              <div className="orderdetail-header-right">
                <AiOutlineClose
                  onClick={() => setIsVisible(false)}
                  className="close-icon"
                />
                <AiOutlineEdit
                  onClick={() => setIsEditVisible(!isEditVisible)}
                  className="edit-icon"
                />
              </div>
            </div>

            <Accordion
              activeKey={activeKey}
              onSelect={(key) => handleAccordionSelect(key as any)}
            >
              <Accordion.Item eventKey="0" className="accordionitem">
                <Accordion.Header>
                  <div className="order-item">
                    <div className="order-item-left">
                      <div className="categoryname">
                        <Pharmacy /> {item.cat1} ({item.itemno1})
                      </div>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Card.Body>
                    <Table
                      dataSource={dataSource}
                      columns={columns}
                      className="custom-table"
                      pagination={false}
                    />
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div className="order-item">
                    <div className="order-item-left">
                      <div className="categoryname">
                        <Labtest /> {item.cat2} ({item.itemno2})
                      </div>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Card.Body>
                    <Table
                      dataSource={labtestDataSource}
                      columns={labtestColumns}
                      className="custom-table"
                      pagination={false}
                    />
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <div className="order-item">
                    <div className="order-item-left">
                      <div className="categoryname">
                        <Radiology /> {item.cat3} ({item.itemno3})
                      </div>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Card.Body>
                    {/* Add content for the third accordion item here if needed */}
                  </Card.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {isEditVisible && (
              <div className="edit-section">
                <div className="searchplace">
                  <div className="search-bar">
                    <input type="text" placeholder="Search Issue" />
                    <Search />
                  </div>
                  <div className="lefttags">
                    <button>pain</button>
                    <button>fever</button>
                    <button>cold</button>
                    <button>Allergy</button>
                    <button>cold</button>
                    <button>Fodkhtkb</button>
                  </div>
                </div>

                <div className="search-medicine-section">
                  <div className="search-bar">
                    <input type="text" placeholder="Search Medicine" />
                    <Search />
                  </div>
                  <div className="medicine-details">
                    <input type="text" placeholder="Dose" />
                    <input type="text" placeholder="No of days" />
                    <select>
                      <option value="Frequency">Frequency</option>
                      {/* Add other options */}
                    </select>
                    <select>
                      <option value="Intake">Intake</option>
                      {/* Add other options */}
                    </select>
                    <input type="date" placeholder="Start Date" />
                  </div>
                  <div className="note-section">
                    <h4>Note</h4>
                    <textarea placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et"></textarea>
                    <button className="add-button">Add +</button>
                  </div>
                </div>

                <div className="search-medicine-section procedure-section">
                  <div className="search-bar">
                    <input type="text" placeholder="Procedure/test" />
                    <Search />
                  </div>
                  <div className="medicine-details">
                    <input type="text" placeholder="Diagnosis" />
                    <input type="text" placeholder="Symptoms" />
                    <input type="date" placeholder="Next Visit" />
                  </div>
                  <div className="note-section">
                    <h4>Note</h4>
                    <textarea placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et"></textarea>
                    <button className="add-button">Add +</button>
                  </div>
                </div>
                <div className="previewbtns">
                  <button className="preview" onClick={handlePreview}>
                    Preview
                  </button>
                  <button className="bookpatient">Book For Patient</button>
                </div>
              </div>
            )}
          </div>
        ))}

      <PreviewModal isVisible={isModalVisible} handleCancel={handleCancel} />
    </div>
  );
};

export default Ordertable;
