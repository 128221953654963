import React, { useState } from "react";
import { ClinicStyled } from "./Clinic.Styled";
import { FaPlus } from 'react-icons/fa';
import { Modal, Button, Form } from 'react-bootstrap';
import './style.css';

interface Clinic {
  name: string;
  address: string;
  timings: DayTiming[];
};

interface DayTiming {
  day: string;
  available: boolean;
  startTime: string;
  endTime: string;
};

const Clinics = () => {
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentClinic, setCurrentClinic] = useState<Clinic | null>(null);
  const [clinicName, setClinicName] = useState("");
  const [clinicAddress, setClinicAddress] = useState("");
  const [clinicState, setClinicState] = useState("");
  const [clinicCity, setClinicCity] = useState("");
  const [clinicPin, setClinicPin] = useState("");
  const [timings, setTimings] = useState<DayTiming[]>([]);
  const [errors, setErrors] = useState({
    clinicName: "",
    clinicAddress: "",
    clinicState: "",
    clinicCity: "",
    clinicPin: "",
    timings: ""
  });

  const handleClose = () => {
    setShow(false);
    setEditMode(false);
    setCurrentClinic(null);
    clearForm();
  };

  const handleShow = () => setShow(true);

  const handleAddClinic = () => {
    setEditMode(false);
    setCurrentClinic(null);
    clearForm();
    handleShow();
  };

  const handleEditClinic = (clinic: Clinic) => {
    setEditMode(true);
    setCurrentClinic(clinic);
    setClinicName(clinic.name);
    setClinicAddress(clinic.address);
    setTimings(clinic.timings);
   
    handleShow();
  };

  const clearForm = () => {
    setClinicName("");
    setClinicAddress("");
    setClinicState("");
    setClinicCity("");
    setClinicPin("");
    setTimings(initializeTimings());
    setErrors({
      clinicName: "",
      clinicAddress: "",
      clinicState: "",
      clinicCity: "",
      clinicPin: "",
      timings: ""
    });
  };

  const initializeTimings = () => {
    return [
      "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
    ].map(day => ({
      day,
      available: false,
      startTime: "",
      endTime: ""
    }));
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!clinicName.trim()) newErrors.clinicName = "Clinic name is required";
    if (!clinicAddress.trim()) newErrors.clinicAddress = "Clinic address is required";
    if (!clinicState.trim()) newErrors.clinicState = "State is required";
    if (!clinicCity.trim()) newErrors.clinicCity = "City is required";
    if (!clinicPin.trim()) newErrors.clinicPin = "Pin code is required";

    const hasTimingError = timings.some(
      (timing) => timing.available && (!timing.startTime || !timing.endTime)
    );

    if (hasTimingError) {
      newErrors.timings = "Please provide start and end times for available days";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveChanges = () => {
    if (validateForm()) {
      handleClose();
    }
  };

  const handleTimingChange = (index: number, field: string, value: string | boolean) => {
    const updatedTimings = [...timings];
    updatedTimings[index] = {
      ...updatedTimings[index],
      [field]: value,
    };
    setTimings(updatedTimings);
  };

  const clinics: Clinic[] = [
    {
      name: "Rapha Clinic",
      address: "VIBGYOR High school, 38/3, 6th cross, road, opp. Thomas Square, HSR Extension, Reliable Tranquil Layout, Bengaluru, Karnataka 560102",
      timings: [
        { day: "Monday", available: true, startTime: "10:00 AM", endTime: "05:00 PM" },
        { day: "Tuesday", available: true, startTime: "10:00 AM", endTime: "05:00 PM" },
        { day: "Wednesday", available: true, startTime: "10:00 AM", endTime: "05:00 PM" },
        { day: "Thursday", available: true, startTime: "10:00 AM", endTime: "05:00 PM" },
        { day: "Friday", available: true, startTime: "10:00 AM", endTime: "05:00 PM" },
        { day: "Saturday", available: true, startTime: "10:00 AM", endTime: "05:00 PM" },
        { day: "Sunday", available: false, startTime: "", endTime: "" }
      ]
    }
  ];

  return (
    <ClinicStyled>
      <div className="container">
        <div className="row">
          
          <div className="col-12 col-md-4">
            <div className="clinic-card card add-clinic-card" onClick={handleAddClinic}>
              <div className="card-body text-center">
                <FaPlus size={50} />
                <p className="add-clinic-text">Add Clinic</p>
              </div>
            </div>
          </div>

          
          {clinics.map((clinic, index) => (
            <div className="col-12 col-md-4" key={index}>
              <div className="clinic-card card">
                <div className="card-body">
                  <h5 className="card-title">{clinic.name}</h5>
                  <p className="card-text">{clinic.address}</p>
                  {clinic.timings.map((time, i) => (
                    <p className="time-text" key={i}> {time.day}: {time.available ? `${time.startTime} to ${time.endTime}` : "Closed"} </p>
                  ))}
                  <div className="card-buttons">
                    <button className="btn btn-link" onClick={() => handleEditClinic(clinic)}>Edit</button>
                    <span> | </span>
                    <button className="btn btn-link remove">Remove</button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

    
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? "Edit Clinic Details" : "Add Clinic Details"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bodymodal">
          <Form>
            <Form.Group controlId="clinicName">
              <Form.Label>Clinic Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please enter clinic name"
                value={clinicName}
                onChange={(e) => setClinicName(e.target.value)}
                isInvalid={!!errors.clinicName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.clinicName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="clinicAddress" className="mt-3">
              <Form.Label>Address *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please type & select address"
                value={clinicAddress}
                onChange={(e) => setClinicAddress(e.target.value)}
                isInvalid={!!errors.clinicAddress}
              />
              <Form.Control.Feedback type="invalid">
                {errors.clinicAddress}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="clinicState" className="mt-3">
              <Form.Label>State *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please type & select state"
                value={clinicState}
                onChange={(e) => setClinicState(e.target.value)}
                isInvalid={!!errors.clinicState}
              />
              <Form.Control.Feedback type="invalid">
                {errors.clinicState}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="row mt-3">
              <Form.Group controlId="clinicCity" className="col-6">
                <Form.Label>City *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Type & select city"
                  value={clinicCity}
                  onChange={(e) => setClinicCity(e.target.value)}
                  isInvalid={!!errors.clinicCity}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.clinicCity}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="clinicPin" className="col-6">
                <Form.Label>Pin Code *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter pin code"
                  value={clinicPin}
                  onChange={(e) => setClinicPin(e.target.value)}
                  isInvalid={!!errors.clinicPin}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.clinicPin}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <h5 className="mt-4 timings-text">Add Timings</h5>
            {timings.map((timing, index) => (
              <div className="row mt-3 align-items-center" key={index}>
                <div className="col-3 week-name">
                  <Form.Label>{timing.day}</Form.Label>
                </div>
                <div className="col-3">
                  <Form.Check
                    type="switch"
                    id={`available${timing.day}`}
                    label="Available"
                    checked={timing.available}
                    onChange={(e) => handleTimingChange(index, "available", e.target.checked)}
                  />
                </div>
                <div className="col-3">
                  <Form.Control
                    type="text"
                    placeholder="Starts at"
                    value={timing.startTime}
                    onChange={(e) => handleTimingChange(index, "startTime", e.target.value)}
                    disabled={!timing.available}
                    isInvalid={!!errors.timings && !timing.startTime && timing.available}
                  />
                </div>
                <div className="col-3">
                  <Form.Control
                    type="text"
                    placeholder="Ends at"
                    value={timing.endTime}
                    onChange={(e) => handleTimingChange(index, "endTime", e.target.value)}
                    disabled={!timing.available}
                    isInvalid={!!errors.timings && !timing.endTime && timing.available}
                  />
                </div>
              </div>
            ))}
            {errors.timings && (
              <p className="text-danger">{errors.timings}</p>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="btn-save" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </ClinicStyled>
  );
};

export default Clinics;
