// NewOrder.styled.js
import styled from "styled-components";

export const NewOrderStyled = styled.div`
  .package-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    background-color: #e8f7f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }

  .package-card:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  .client-packages h5 {
    color: #545353;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .package-image {
    width: 80px;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
  }

  .card-title {
    font-weight: bold;
    font-size: 1.2rem;
    color: #333;
  }

  .price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #f77f00;
    margin-top: 10px;
  }

  .btn-primary {
    background-color: #6f42c1;
    border-color: #6f42c1;
  }

  .btn-primary:hover {
    background-color: #5a359e;
    border-color: #5a359e;
  }

  .btn-outline-primary {
    color: #6f42c1;
    border-color: #6f42c1;
  }

  .btn-outline-primary:hover {
    background-color: #6f42c1;
    color: #fff;
  }
`;
