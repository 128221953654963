import styled from "styled-components";

export const CRHomeStyled = styled.div`
  img.userImg {
    width: 60px;
  }

  .loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }

  .chatContainer {
    height: 70vh;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
  }

  .active {
    background: #9747ff;
  }

  .fixedHeader {
    position: fixed;
    width: 100%;
    z-index: 8;
    background-color: #f6f9fd;
    margin-top: -1.2rem;
    padding: 0.5rem;
    @media (max-width: 760px) {
      position: static;
      margin-top: 0;
    }
  }

  .bubbleWrapper {
    padding: 0 1.5rem;
    position: relative;
    top: 10%;
    bottom: 0;
  }

  .chatBubble {
    display: flex;
  }

  .rightChat {
    border-radius: 1rem 1rem 0 1rem;
    width: fit-content;
    align-self: flex-end;
    background-color: #e2e2e2;
    color: black;
    padding: 0.5rem;
    margin: 0.25rem 0;
  }

  .leftChat {
    border-radius: 0 1rem 1rem 1rem;
    width: fit-content;
    border: 1px solid #007bff;
    align-self: flex-start;
    padding: 0.5rem;
    margin: 0.25rem 0;
  }

  .chatBubble:has(.rightChat) {
    justify-content: end;
  }

  .chatBubble:has(.leftChat) {
    justify-content: start;
  }

  input.form-control {
    height: 50px;
  }
  input.form-control:active,
  input.form-control:focus {
    /* border: 1px solid #9747ff; */
    outline: none;
    box-shadow: none;
  }
  .chat-div {
    /* position: fixed;
    width: 30%; */
  }
  @media (max-width: 760px) {
    .chat-wrap {
      flex-direction: column !important;
    }
  }
  @media (max-width: 760px) {
    .chat-img-div {
      flex-direction: column !important;
    }
  }
`;
