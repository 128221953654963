import React, { useState } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { useHistory } from "react-router-dom";
import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import UploadPrescriptionModal from "../../../components/UploadPrescription/UploadPrescriptionModal";

interface FormData {
  name: string;
  mobileNumber: string;
  email: string;
  employeeId: string;
  age: string;
  gender: string;
  address: string;
  state: string;
  city: string;
  pincode: string;
}

const Createsearch: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showUploadPrescriptionModal, setShowUploadPrescriptionModal] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    mobileNumber: "",
    email: "",
    employeeId: "",
    age: "",
    gender: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
  });
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const history = useHistory();

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleShowUploadPrescriptionModal = () => setShowUploadPrescriptionModal(true);
  const handleCloseUploadPrescriptionModal = () => setShowUploadPrescriptionModal(false);

  const handleCreateOrder = () => {
    history.push("/create-order");
  };

  // Function to handle input changes
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const formErrors: Partial<FormData> = {};
    if (!formData.name.trim()) formErrors.name = "Please enter Patient name";
    if (!formData.mobileNumber.trim()) formErrors.mobileNumber = "Please enter Mobile number";
    if (!formData.email.trim()) formErrors.email = "Please enter Email Id";
    if (!formData.employeeId.trim()) formErrors.employeeId = "Please enter Employee Id";
    if (!formData.age.trim()) formErrors.age = "Please enter Age";
    if (!formData.gender.trim()) formErrors.gender = "Please select Gender";
    if (!formData.address.trim()) formErrors.address = "Please enter Address";
    if (!formData.state.trim()) formErrors.state = "Please select State";
    if (!formData.city.trim()) formErrors.city = "Please select City";
    if (!formData.pincode.trim()) formErrors.pincode = "Please enter Pin code";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSaveDetails = () => {
    if (validateForm()) {
      // Submit form
      handleCloseModal();
    }
  };

  return (
    <>
      <div className="controls">
        <div className="left-controls">
          <div className="search-bar">
            <Search />
            <input type="text" placeholder="Search Order" />
          </div>
          <button className="upload-prescription-button" onClick={handleShowUploadPrescriptionModal}>
            <Upload /> Upload Prescription
          </button>
        </div>
        <div className="right-buttons">
          <button className="add-patient-button" onClick={handleShowModal}>
            Add Patient
          </button>
          {/* Uncomment the following lines if you want to use the CartIcon */}
          {/* <button className="cart-button" onClick={handleCreateOrder}>
            <CartIcon />
          </button> */}
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} className="custom-modal" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Patient Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formName">
                  <Form.Label>Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Patient name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formMobileNumber">
                  <Form.Label>Mobile Number *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Mobile number"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleInputChange}
                    isInvalid={!!errors.mobileNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mobileNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email Id *</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Please enter Email Id"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formEmployeeId">
                  <Form.Label>Employee Id *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Employee Id"
                    name="employeeId"
                    value={formData.employeeId}
                    onChange={handleInputChange}
                    isInvalid={!!errors.employeeId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.employeeId}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formAge">
                  <Form.Label>Age *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Age"
                    name="age"
                    value={formData.age}
                    onChange={handleInputChange}
                    isInvalid={!!errors.age}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.age}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formGender">
                  <Form.Label>Gender *</Form.Label>
                  <Form.Control
                    as="select"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    isInvalid={!!errors.gender}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please type & select address"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    isInvalid={!!errors.address}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formState">
                  <Form.Label>State *</Form.Label>
                  <Form.Control
                    as="select"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    isInvalid={!!errors.state}
                  >
                    <option value="">Select State</option>
                    {/* Add options for states here */}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.state}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formCity">
                  <Form.Label>City *</Form.Label>
                  <Form.Control
                    as="select"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    isInvalid={!!errors.city}
                  >
                    <option value="">Select City</option>
                    {/* Add options for cities here */}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPincode">
                  <Form.Label>Pin code *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Pin code"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleInputChange}
                    isInvalid={!!errors.pincode}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.pincode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancelbtn" onClick={handleCloseModal}>
            Close
          </button>
          <button className="save-btn" onClick={handleSaveDetails}>
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <UploadPrescriptionModal
        show={showUploadPrescriptionModal}
        handleClose={handleCloseUploadPrescriptionModal}
      />
    </>
  );
};

export default Createsearch;
