import React from "react";
import { Button } from "react-bootstrap";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from './Login.Styled';
import { useHistory } from 'react-router-dom';

const ProgressSummarytwo: React.FC = () => {
  const history = useHistory();

  const goToProfileDetails = () => {
    history.push('/register/profile-details');
  };

  const goToIdentityProof = () => {
    history.push('/register/identity-proof');
  };

  const goToMapLocation = () => {
    history.push('/register/map-location');
  };

  return (
    <LoginStyled>
      <div className="profile-page">
        <div className="page-left">
          <div className="progress-summary">
            <h2>Great Progress</h2>
            <p>Your profile is only a few steps away from being live!</p>
            <p className="secclass">Section A: Profile Details</p>
            <p>
              Doctor’s basic details, medical registration, education qualification,
              establishment details etc.
            </p>
            <Button onClick={goToProfileDetails} className="changebtn">
              Change
            </Button>
            <hr />
            <p className="secclass">Section B: Profile Verification</p>
            <p>
              Doctor identity proof, registration proof, establishment ownership
              proof etc.
            </p>
            <Button onClick={goToIdentityProof} className="changebtn">
              Change
            </Button>
            <hr />
            <p className="secclass">Section C: Start getting patients</p>
            <p className="secclass">Location, Timings, Fees</p>
            <Button className="continue-button" onClick={goToMapLocation}>
              Continue
            </Button>
          </div>
        </div>
        <div className="pageright">
          <img src={Rightimg} alt="Profile Illustration" />
        </div>
      </div>
    </LoginStyled>
  );
};

export default ProgressSummarytwo;
