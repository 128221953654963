import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { get, patch, post, put } from "../../../library/Requests/helpers";
import { listAllBookigs } from "../../../Scenes/graphqlConfig";

export const getDoctorSlotsAPI = createAsyncThunk(
  "bookingScreen/getDoctorSlotsAPI",
  async (obj: any) => await patch(`${SERVER_IP}/api/v1/timeslot/doctor`, obj)
);

export const cancelBookingAPI = createAsyncThunk(
  "bookingScreen/cancelBookingAPI",
  async (cancelBkObj: any) =>
    await patch(
      `${SERVER_IP}/api/v1/booking/${cancelBkObj.bookingId}/cancel`,
      cancelBkObj.cancelObjBody
    )
);

export const raiseIssueAPI = createAsyncThunk(
  "bookingScreen/raiseIssueAPI",
  async (raiseIssueObj: any) =>
    await put(
      `${SERVER_IP}/api/v1/booking/${raiseIssueObj.bookingId}/issue`,
      raiseIssueObj.raiseIssueObjBody
    )
);

export const bookAgainAPI = createAsyncThunk(
  "bookingScreen/bookAgainAPI",
  async (bkAgainObj: any) =>
    await put(
      `${SERVER_IP}/api/v1/booking/${bkAgainObj.bookingId}/reschedule`,
      bkAgainObj.bkAgainObjBody
    )
);

export const getVirtualCallLinkAPI = createAsyncThunk(
  "bookingScreen/getVirtualCallLinkAPI",
  async (bkId: any) =>
    await get(`${SERVER_IP}/api/v1/booking/${bkId}/videocall`)
);

export const listAllBookigsAPI = createAsyncThunk(
  "bookingScreen/listAllBookigsAPI",
  async (obj: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      listAllBookigs(obj.role, obj.count, obj.page, obj.searchText)
    )
);
