import React, { useState } from "react";
import { TeamStyled } from "./Team.styled";
import YouImg from "../../assets/you.png";
const TeamMember = ({ name, mainImage, hoverImage }: any) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="team-member"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      title={name}
    >
      <img
        src={isHovered ? hoverImage : mainImage}
        alt={name}
        className="team-member-image"
      />
    </div>
  );
};

const Team = ({ members }: any) => {
  return (
    <TeamStyled className="team">
      <h2 className="team-title">Passionate Team</h2>
      <div className="team-members">
        {members.map((member: any, index: any) => (
          <TeamMember
            key={index}
            name={member.name}
            mainImage={member.mainImage}
            hoverImage={member.hoverImage}
          />
        ))}
        <div className="team-member" title="You">
          <img src={YouImg} alt="You" className="team-member-image" />
        </div>
      </div>
    </TeamStyled>
  );
};

export default Team;
