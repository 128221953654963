import { AnyAction, createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get, patch } from "../../../library/Requests/helpers";

import {
  constructDoctorsListQuery,
  constructDoctorHospitalListQuery,
  constructHospitalListQuery,
} from "../../../Scenes/graphqlConfig";

export const getDoctorsListAPI = createAsyncThunk(
  "doctor/getDoctorsListAPI",
  async (obj: any) =>
    await post(`${SERVER_IP}/graphql`, constructDoctorsListQuery(obj))
);

export const getDoctorHospitalListAPI = createAsyncThunk(
  "doctor/getDoctorHospitalListAPI",
  async (id: string) =>
    await post(`${SERVER_IP}/graphql`, constructDoctorHospitalListQuery(id))
);

export const getHospitalListAPI = createAsyncThunk(
  "doctor/getHospitalListAPI",
  async (obj: any) =>
    await post(`${SERVER_IP}/graphql`, constructHospitalListQuery(obj))
);

export const doctorRegistration = createAsyncThunk(
  "doctor/doctorRegistration",
  async (body: any) => await post(`${SERVER_IP}/api/v1/doctor/register`, body)
);

export const doctorMedRegistration = createAsyncThunk(
  "doctor/doctorMedRegistration",
  async (body: any) =>
    await patch(
      `${SERVER_IP}/api/v1/doctor/${body.id}/doctor-medicalRegistration`,
      body.medObj
    )
);

export const doctorQualification = createAsyncThunk(
  "doctor/doctorQualification",
  async (body: any) =>
    await put(
      `${SERVER_IP}/api/v1/doctor/${body.id}/doctor-qualification`,
      body.qualificationObj
    )
);

export const doctorUserAddress = createAsyncThunk(
  "doctor/doctorUserAddress",
  async (body: any) =>
    await put(
      `${SERVER_IP}/api/v1/doctor/${body.id}/user-address`,
      body.userAddrObj
    )
);

export const getDoctorInfoAPI = createAsyncThunk(
  "doctor/getDoctorInfoAPI",
  async (phone: any) =>
    await get(`${SERVER_IP}/api/v1/doctor/doctor/by-phone/${phone}`)
);

export const getDoctorSpecializationAPI = createAsyncThunk(
  "doctor/getDoctorSpecializationAPI",
  async () =>
    await get(`${SERVER_IP}/api/v1/config/doctorspecializations`)
);

export const getDoctorOrdersAPI = createAsyncThunk(
  "doctor/getDoctorOrdersAPI",
  async () =>
    await get(`${SERVER_IP}/api/v1/doctor/orders`)
);

export const getProfileDetails = createAsyncThunk(
  "doctor/getProfileDetails",
  async () =>
    await get(`${SERVER_IP}/api/v1/doctor/me`)
);

export const instantConnectAPI = createAsyncThunk(
  "doctor/instantConnectAPI",
  async (body: any) =>
    await post(`${SERVER_IP}/api/v1/dashboard/callMasking`, body)
);