import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as Minus } from '../../assets/icons/minus.svg';
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import { LoginStyled } from './Login.Styled';
import { useHistory } from 'react-router-dom';

const ConsultantDoctor = () => {
  const history = useHistory();
  
  const [consultationFeeChat, setConsultationFeeChat] = useState('');
  const [chatTimes, setChatTimes] = useState([{ startTime: '', endTime: '' }]);

  const [consultationFeeCall, setConsultationFeeCall] = useState('');
  const [callTimes, setCallTimes] = useState([{ startTime: '', endTime: '' }]);

  const [consultationFeeVideoCall, setConsultationFeeVideoCall] = useState('');
  const [videoCallTimes, setVideoCallTimes] = useState([{ startTime: '', endTime: '' }]);

  const [consultationFeeHomeCare, setConsultationFeeHomeCare] = useState('');
  const [homeCareTimes, setHomeCareTimes] = useState([{ startTime: '', endTime: '' }]);

  const [consultationFeeInstant, setConsultationFeeInstant] = useState('');
  const [instantTimes, setInstantTimes] = useState([{ startTime: '', endTime: '' }]);

  const handleAddTime = (type: string) => {
    if (type === 'chat') setChatTimes([...chatTimes, { startTime: '', endTime: '' }]);
    if (type === 'call') setCallTimes([...callTimes, { startTime: '', endTime: '' }]);
    if (type === 'videoCall') setVideoCallTimes([...videoCallTimes, { startTime: '', endTime: '' }]);
    if (type === 'homeCare') setHomeCareTimes([...homeCareTimes, { startTime: '', endTime: '' }]);
    if (type === 'instant') setInstantTimes([...instantTimes, { startTime: '', endTime: '' }]);
  };

  const handleRemoveTime = (type: string, index: number) => {
    if (type === 'chat') setChatTimes(chatTimes.filter((_, i) => i !== index));
    if (type === 'call') setCallTimes(callTimes.filter((_, i) => i !== index));
    if (type === 'videoCall') setVideoCallTimes(videoCallTimes.filter((_, i) => i !== index));
    if (type === 'homeCare') setHomeCareTimes(homeCareTimes.filter((_, i) => i !== index));
    if (type === 'instant') setInstantTimes(instantTimes.filter((_, i) => i !== index));
  };

  const renderConsultationForm = (label: string, consultationFee: string, setConsultationFee: any, times: any, setTimes: any, type: string) => (
    <div className="consultation-form">
      <div className="initial-row">
        <Form.Check type="checkbox" label={label} className="form-check" />
        <div className="fee-input">
          <span className="currency">Rs.</span>
          <input 
            type="text" 
            placeholder="Type consultation fee" 
            className="consultation-fee" 
            value={consultationFee}
            onChange={(e) => setConsultationFee(e.target.value)}
          />
        </div>
        <div className="weekdays">
          {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map(day => (
            <div className="day" key={day}>{day}</div>
          ))}
        </div>
        <div className="time-inputs">
          <input 
            type="text" 
            placeholder="Starts at" 
            className="time-input" 
            value={times[0].startTime}
            onChange={(e) => {
              const newTimes = [...times];
              newTimes[0].startTime = e.target.value;
              setTimes(newTimes);
            }}
          />
          <input 
            type="text" 
            placeholder="Ends at" 
            className="time-input" 
            value={times[0].endTime}
            onChange={(e) => {
              const newTimes = [...times];
              newTimes[0].endTime = e.target.value;
              setTimes(newTimes);
            }}
          />
        </div>
        <div className="add-time" onClick={() => handleAddTime(type)}>
          <Plus />
        </div>
      </div>
      {times.slice(1).map((time: any, index: number) => (
        <div key={index} className="time-inputs-row">
          <div className="weekdays">
            {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map(day => (
              <div className="day" key={day}>{day}</div>
            ))}
          </div>
          <div className="time-inputs">
            <input 
              type="text" 
              placeholder="Starts at" 
              className="time-input" 
              value={time.startTime}
              onChange={(e) => {
                const newTimes = [...times];
                newTimes[index + 1].startTime = e.target.value;
                setTimes(newTimes);
              }}
            />
            <input 
              type="text" 
              placeholder="Ends at" 
              className="time-input" 
              value={time.endTime}
              onChange={(e) => {
                const newTimes = [...times];
                newTimes[index + 1].endTime = e.target.value;
                setTimes(newTimes);
              }}
            />
            <div className="remove-time" onClick={() => handleRemoveTime(type, index + 1)}>
              <Minus />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <LoginStyled>
      <div className="consultant-doctor">
        <h2>Details of consultant doctor</h2>
        <p>Dr. Jerom Bell</p>
        <h4>Virtual Consultation</h4>
        {renderConsultationForm('Chat', consultationFeeChat, setConsultationFeeChat, chatTimes, setChatTimes, 'chat')}
        {renderConsultationForm('Call', consultationFeeCall, setConsultationFeeCall, callTimes, setCallTimes, 'call')}
        {renderConsultationForm('Video Call', consultationFeeVideoCall, setConsultationFeeVideoCall, videoCallTimes, setVideoCallTimes, 'videoCall')}
        {renderConsultationForm('Home Care', consultationFeeHomeCare, setConsultationFeeHomeCare, homeCareTimes, setHomeCareTimes, 'homeCare')}
        {renderConsultationForm('Instant Consultation', consultationFeeInstant, setConsultationFeeInstant, instantTimes, setInstantTimes, 'instant')}
        <div className="button-container">
          <Button className="back-button" onClick={() => history.push('/register/map-location')}>
            <BackIcon /> Back
          </Button>
          <Button className="continue-button" onClick={() => history.push('/register/bank-account')}>
            Next
          </Button>
        </div>
      </div>
    </LoginStyled>
  );
};

export default ConsultantDoctor;
