import React, { useState, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from './Login.Styled';
import { useHistory } from 'react-router-dom';

const containerStyle = {
  width: "100%",
  height: "200px",
};

const center = {
  lat: 12.9716,
  lng: 77.5946,
};

interface Errors {
  email?: string;
  address?: string;
}

const MapLocation: React.FC = () => {
  const history = useHistory();
  
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "YOUR_GOOGLE_MAPS_API_KEY", // Replace with your API key
  });

  const [phoneNumber, setPhoneNumber] = useState("+91 9995268428");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState(center);
  const [errors, setErrors] = useState<Errors>({});

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value);
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);

  const onMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    setMarkerPosition({ lat: event.latLng!.lat(), lng: event.latLng!.lng() });
  }, []);

  const validateForm = () => {
    const newErrors: Errors = {} as any;
    if (!email) newErrors.email = "Email address is required.";
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = "Email address is invalid.";
    
    if (!address) newErrors.address = "Street address is required.";
    
    return newErrors;
  };

  const handleNextStep = () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      history.push('/register/consultant-doctor');
    } else {
      setErrors(formErrors);
    }
  };

  const handlePrevStep = () => {
    history.push('/register/progress-summary-two');
  };

  if (loadError) {
    return <div>Error loading map</div>;
  }

  return (
    <LoginStyled>
      <div className="map-location-container">
        <div className="content">
          <h2>Map Location</h2>
          <p>Cure clinic, HSR Layout</p>

          <Form>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone number</Form.Label>
              <div className="input-wrapper">
                <Form.Control
                  type="text"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  readOnly
                />
                <span className="verified">✔ Verified</span>
                <Button className="edit-button">Edit</Button>
              </div>
            </Form.Group>

            <div className="">
              <p className="secclass">
                Note: Patients calls will be directed to this number. You can update
                it later also.
              </p>
            </div>

            <Form.Group controlId="formEmailAddress">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email address"
                value={email}
                onChange={handleEmailChange}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formStreetAddress">
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type street address"
                value={address}
                onChange={handleAddressChange}
                isInvalid={!!errors.address}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>

          <div className="map-container">
            <p>Drag and drop the pin to your location</p>
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
                onClick={onMapClick}
              >
                <Marker position={markerPosition} draggable={true} />
              </GoogleMap>
            ) : (
              <p>Loading map...</p>
            )}
          </div>

          <div className="button-container">
            <Button className="back-button" onClick={handlePrevStep}>
              <BackIcon /> Back
            </Button>
            <Button className="continue-button" onClick={handleNextStep}>
              Next
            </Button>
          </div>
        </div>

        <div className="pageright">
          <img src={Rightimg} alt="Profile Illustration" />
        </div>
      </div>
    </LoginStyled>
  );
};

export default MapLocation;
