import React, { useState } from "react";
import { MarketingStyled } from "./Marketing.styled";
import historyIcon from "../../assets/icons/history-icon.svg";
import audienceIcon from "../../assets/icons/audience-icon.svg";
import communicationIcon from "../../assets/icons/communication-icon.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import History from "./History";
import Audience from "./Audience";
import Communication from "./Communication";

const Marketing = () => {
  const [activeTab, setActiveTab] = useState("history");

  const renderContent = () => {
    switch (activeTab) {
      case "history":
        return <History />;
      case "audience":
        return <Audience />;
      case "communication":
        return <Communication />;
      default:
        return null;
    }
  };

  return (
    <MarketingStyled>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="search-bar">
            <Search />
            <input
              type="text"
              placeholder="Search For Patient or Serial Number"
            />
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
          {activeTab === "communication" && (
            <select className="communication-select">
              <option value="whatsapp">WhatsApp</option>
              <option value="notification">Notification</option>
              <option value="gmail">Gmail</option>
            </select>
          )}
          <div className="tab-buttons">
            <button
              className={activeTab === "history" ? "active" : ""}
              onClick={() => setActiveTab("history")}
            >
              <img src={historyIcon} alt="History" /> History
            </button>
            <button
              className={activeTab === "audience" ? "active" : ""}
              onClick={() => setActiveTab("audience")}
            >
              <img src={audienceIcon} alt="Audience" /> Audience
            </button>
            <button
              className={activeTab === "communication" ? "active" : ""}
              onClick={() => setActiveTab("communication")}
            >
              <img src={communicationIcon} alt="Communication" /> Communication
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="tab-content">{renderContent()}</div>
      </div>
    </MarketingStyled>
  );
};

export default Marketing;
