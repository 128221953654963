import React from "react";

const pres = [
  {
    name:"Ann Mariya",
  },
  {
    name:"Ann Mariya",
  },
  {
    name:"Ann Mariya",
  },
];


const Prescription = () => {
  return (
    <div className="followup">
      {pres.map((item, index) => (
      <div className="message">
        <p>Hi {item.name},</p>
        <p>
          Dr. Syam CV has prescribed [Medication Name] for you. Please visit
          [Pharmacy Name/Location] to pick up your prescription. If you have any
          questions about the medication, feel free to contact us at [Phone
          Number].
        </p>
        <p>Thank you</p>
        <div className="check">
        <p>Apolo Clinic</p>
        <input type="checkbox" />
      </div>
      </div>
     ))}
    </div>
  );
};

export default Prescription;
