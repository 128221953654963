import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Rightimg from "../../assets/images/Login/mobile.png";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import { useHistory } from "react-router-dom";
import { LoginStyled } from './Login.Styled';

const BankAccount: React.FC = () => {
  const history = useHistory();
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [bankName, setBankName] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [errors, setErrors] = useState<{ 
    accountHolderName?: string; 
    bankName?: string; 
    accountNumber?: string; 
    ifscCode?: string;
  }>({});

  const validateForm = () => {
    const newErrors: any = {};
    if (!accountHolderName) newErrors.accountHolderName = "Account Holder Name is required.";
    if (!bankName) newErrors.bankName = "Bank Name is required.";
    if (!accountNumber) newErrors.accountNumber = "Account Number is required.";
    if (!ifscCode) newErrors.ifscCode = "IFSC Code is required.";
    return newErrors;
  };

  const handleNextStep = () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      history.push('/register/verification-profile'); // Navigate to VerificationProfile
    } else {
      setErrors(formErrors);
    }
  };

  const handlePrevStep = () => {
    history.push("/register/consultant-doctor"); // Navigate back to ConsultantDoctor
  };

  return (
    <LoginStyled>
      <div className="profile-page">
        <div className="page-left">
          <div className="bank-account">
            <h2>Add Bank Account</h2>
            <Form>
              <Form.Group controlId="accountHolderName">
                <Form.Label>Account Holder Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Full Name"
                  value={accountHolderName}
                  onChange={(e) => setAccountHolderName(e.target.value)}
                  isInvalid={!!errors.accountHolderName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.accountHolderName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="bankName">
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  as="select"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  isInvalid={!!errors.bankName}
                >
                  <option value="">Select Bank</option>
                  <option value="Bank A">Bank A</option>
                  <option value="Bank B">Bank B</option>
                  <option value="Bank C">Bank C</option>
                  {/* Add more options as needed */}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.bankName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="accountNumber">
                <Form.Label>Account Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Account Number"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  isInvalid={!!errors.accountNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.accountNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="ifscCode">
                <Form.Label>IFSC Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter IFSC Code"
                  value={ifscCode}
                  onChange={(e) => setIfscCode(e.target.value)}
                  isInvalid={!!errors.ifscCode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.ifscCode}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="button-container">
                <Button className="back-button" onClick={handlePrevStep}>
                  <BackIcon /> Back
                </Button>
                <Button className="continue-button" onClick={handleNextStep}>
                  Next
                </Button>
              </div>
            </Form>
          </div>
        </div>

        <div className="pageright">
          <img src={Rightimg} alt="Profile Illustration" />
        </div>
      </div>
    </LoginStyled>
  );
};

export default BankAccount;
