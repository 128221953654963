import React, { useState } from "react";
import { Table } from "antd";
import Pagination from "react-bootstrap/Pagination";
import { Button, Modal, Form } from "react-bootstrap";
import { ReactComponent as Plusnew } from "../../assets/icons/plusnew.svg";
import { BsUpload } from "react-icons/bs";

interface AudienceData {
  key: string;
  name: string;
  gender: string;
  phone: string;
  email: string;
  city: string;
  dob: string;
  address: string;
  groupName: string;
}

const Audience: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setStep(1); // Reset the step to initial state
  };

  const handleNextStep = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleChangeFile = () => {
    setStep(1); // Reset to the first step to allow file re-upload
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Gender", dataIndex: "gender", key: "gender" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "Email Id", dataIndex: "email", key: "email" },
    { title: "City", dataIndex: "city", key: "city" },
    { title: "DOB", dataIndex: "dob", key: "dob" },
    { title: "Address", dataIndex: "address", key: "address" },
    { title: "Group Name", dataIndex: "groupName", key: "groupName" },
  ];

  const data: AudienceData[] = [
    {
      key: "1",
      name: "Mohan Lal",
      gender: "Male",
      phone: "9074125155",
      email: "Mohanlal@gmail.com",
      city: "Bangalore",
      dob: "20-08-1994",
      address: "No 12 Salarpuriya , Hsr Layout...",
      groupName: "Bangalore Hsr",
    },
    // Other data entries...
  ];

  return (
    <div className="audiance-sec">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: "max-content" }}
      />

      <div className="row">
        <div className="col-12 col-md-8">
          <div className="pagination">
            <Pagination className="custom-pagination">
              <Pagination.First />
              <Pagination.Prev />
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Item>{3}</Pagination.Item>
              <Pagination.Item>{4}</Pagination.Item>
              <Pagination.Next />
              <Pagination.Last />
            </Pagination>
          </div>
        </div>

        <div className="col-12 col-md-4 btn-cls">
          <Button className="create-aud" onClick={handleShow}>
            Create Audience &nbsp; <Plusnew />
          </Button>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          {/* <Modal.Title>Create Audience</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {step === 1 ? (
            <>
              <div className="upload-section text-center">
                <div className="upload-icon">
                  <BsUpload size={48} color="#5856d6" />
                </div>
                <p>Upload CSV or XL Format</p>
              </div>
              <p className="text-center text-success">
                <i className="bulk-upload-icon" /> Use The Bulk Format Upload
              </p>
              <div className="audience-details mt-4">
                <Form className="popup-form">
                  <Form.Group className="col-md-2" controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" defaultValue="Pranav Mohan" />
                  </Form.Group>
                  <Form.Group className="col-md-2" controlId="formEmail">
                    <Form.Label>Email Id</Form.Label>
                    <Form.Control
                      type="email"
                      defaultValue="Pranav@gmail.com"
                    />
                  </Form.Group>
                  <Form.Group className="col-md-2" controlId="formPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="text" defaultValue="XXXXXXXXXX" />
                  </Form.Group>
                  <Form.Group className="col-md-2" controlId="formDOB">
                    <Form.Label>DOB</Form.Label>
                    <Form.Control type="text" defaultValue="20-08-1994" />
                  </Form.Group>
                  <Form.Group className="col-md-3" controlId="formAddress">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue="Prabavathi plasma ..."
                    />
                  </Form.Group>
                </Form>
              </div>
            </>
          ) : step === 2 ? (
            <div className="code-section">
              <Form.Group controlId="formFileName">
                <Form.Label>File Name</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control type="text" placeholder="Enter File Name" />
                  <Button className="ms-2 savebtn">Save</Button>
                </div>
              </Form.Group>
              <Form.Group controlId="formSearchTags" className="mt-3">
                <Form.Label>Search Tags</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control type="text" placeholder="Search Tags" />
                </div>
              </Form.Group>
            </div>
          ) : (
            <div className="preview-section">
              <p>Preview of the uploaded file:</p>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{ x: "max-content" }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleChangeFile}>
            {step === 3 ? "CHANGE FILE" : "Cancel"}
          </Button>
          <Button onClick={handleNextStep} className="savenext">
            {step === 3 ? "Save" : "Next"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Audience;
