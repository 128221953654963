import React from "react";

const remain = [
  {
    name:"Ann Mariya",
  },
  {
    name:"Ann Mariya",
  },
  {
    name:"Ann Mariya",
  },
];

const Reminder = () => {
  return (
    <div className="followup">
      {remain.map((item, index) => (
      <div className="message">
        <p>Hi {item.name},</p>
        <p>
          This is a reminder for your appointment with Dr. Syam CV at Apolo
          Clinic on [Date] at [Time]. Please arrive 10 minutes early. If you
          need to reschedule, contact us at [Phone Number].
        </p>
        <p>Thank you</p>
        <div className="check">
        <p>Apolo Clinic</p>
        <input type="checkbox" />
      </div>
      </div>
       ))}
    </div>
  );
};

export default Reminder;
