import styled from "styled-components";

export const TimeSlotsComponentStyled = styled.div`
  .time-slots {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .time-slot-section {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
  }

  .time-slot-section h3 {
    margin-bottom: 10px;
  }

  .time-slot-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .time-slot {
    padding: 10px;
    background-color: #e0ffe0;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
  }

  .time-slot.past {
    background-color: #ffcdd2; /* Highlight past times */
    text-decoration: line-through;
  }

  .slotList {
    background: rgb(234, 251, 228);
    min-height: 29px;
    white-space: nowrap;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    align-content: center;
    padding: 6px;
    cursor: pointer;
    text-transform: uppercase;
    width: max-content;
  }
`;
