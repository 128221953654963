import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import styled from "styled-components";

const DataChartWrapper = styled.div`
  display: flex;
  padding: 1rem;
  .recharts-wrapper {
    margin: auto;
  }
  .recharts-legend-wrapper {
    width: auto !important;
  }
`;

const DataChart = ({ data }: any) => {
  // Prepare the data for the Bar chart
  const chartData = [
    {
      name: "Metrics",
      "Open Count": data?.open_bookings_count,
      "Bookings Count": data?.bookings_count,
      "Cancelled Bookings": Number(data?.cancelled_bookings_count),
      "Client Not Contactable": Number(data?.client_not_contactable),
      "Client Confirmation Pending": Number(
        data?.client_confirmation_pending_count
      ),
      "Work in Progress": Number(data?.work_in_progress_count),
    },
  ];

  return (
    <DataChartWrapper>
      <BarChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Open Count" fill="#FFBB28" />
        <Bar dataKey="Bookings Count" fill="#0088FE" />
        <Bar dataKey="Cancelled Bookings" fill="#FF8042" />
        <Bar dataKey="Client Not Contactable" fill="#00C49F" />
        <Bar dataKey="Client Confirmation Pending" fill="#0076c4" />
        <Bar dataKey="Work in Progress" fill="#0024c4" />
      </BarChart>
    </DataChartWrapper>
  );
};

export default DataChart;
