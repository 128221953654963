import React from "react";
import { OrderagainStyled } from "./Orderagain.Styled";
import Ordertable from "./Ordertable";
import Createsearch from "../CreateOrder/Createsearch";

const OrderAgain = () => {
 

  return (
    <OrderagainStyled>
      <div className="order-again">
        <Createsearch />
        <Ordertable />
      </div>
    </OrderagainStyled>
  );
};

export default OrderAgain;
