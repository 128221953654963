import styled from "styled-components";

export const SideMenuStyled = styled.div`
  .sidebar-menu {
    padding: 12px;
    padding-left: 0;
  }
  .sidebar-menu div {
    margin-bottom: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.2rem;
    padding-left: 1rem;
  }
  .sidebar-menu h4 {
    color: #808080;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    display: flex;
    align-items: center;
  }
  .sidebar-menu .sidebar-icon,
  .logoutIcon {
    margin-right: 8px;
    transition: color 0.3s ease;
  }
  .sidebar-menu .active h4 {
    color: purple; // Color for the active menu item
  }
  .sidebar-menu .active .sidebar-icon {
    color: purple; // Icon color for the active menu item
  }
  .sidebar-menu .inactive .sidebar-icon {
    color: #808080; // Icon color for inactive items
  }
`;
