import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from './Login.Styled';
import { useHistory } from 'react-router-dom';

const EstablishmentProof: React.FC = ({ prevStep, nextStep }: any) => {
  const [establishmentType, setEstablishmentType] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [errorData, setErrorData] = useState({
    establishmentType: '',
    file: '',
  });

  const history = useHistory();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      if (selectedFile.size > 25 * 1024 * 1024) { // 25 MB
        setErrorData((prev) => ({ ...prev, file: 'File size exceeds 25 MB' }));
        setFile(null);
      } else if (!['image/png', 'image/jpeg', 'image/svg+xml'].includes(selectedFile.type)) {
        setErrorData((prev) => ({ ...prev, file: 'Invalid file format. Only PNG, JPG, and SVG are allowed.' }));
        setFile(null);
      } else {
        setFile(selectedFile);
        setErrorData((prev) => ({ ...prev, file: '' }));
      }
    }
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEstablishmentType(e.target.value);
    setErrorData((prev) => ({ ...prev, establishmentType: '' }));
  };

  const validateForm = () => {
    let isValid = true;
    if (!establishmentType) {
      setErrorData((prev) => ({ ...prev, establishmentType: 'Please select an establishment type.' }));
      isValid = false;
    }
    if (!file) {
      setErrorData((prev) => ({ ...prev, file: 'Please upload your establishment proof.' }));
      isValid = false;
    }
    console.log('Form validation:', isValid); // Debugging
    return isValid;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Submitting form'); // Debugging
      history.push("/register/progress-summary-two"); // Ensure this path is correctly defined in your routes
    }
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Establishment Proof</h2>
            <p>Cure Clinic</p>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className="form-label">I am</Form.Label>
                <div className="establishment-options">
                  <Form.Check
                    inline
                    type="radio"
                    label="The owner of establishment"
                    value="owner"
                    name="establishmentType"
                    onChange={handleRadioChange}
                    isInvalid={!!errorData.establishmentType}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Have rented at other establishment"
                    value="rented"
                    name="establishmentType"
                    onChange={handleRadioChange}
                    isInvalid={!!errorData.establishmentType}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="A consultant doctor"
                    value="consultant"
                    name="establishmentType"
                    onChange={handleRadioChange}
                    isInvalid={!!errorData.establishmentType}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Practicing at home"
                    value="home"
                    name="establishmentType"
                    onChange={handleRadioChange}
                    isInvalid={!!errorData.establishmentType}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {errorData.establishmentType}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="photoupd">
                <p className="textdata">Accepted Proof (Any one)</p>
                <p className="textdata">
                  Clinic Registration Proof/ Waste Disposal Proof/ Tax receipt
                </p>
                <div className="frontphoto">
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg, .svg"
                    onChange={handleFileChange}
                    className="updbtn"
                  />
                  <button  type="button">Upload</button>
                </div>
                <Form.Control.Feedback type="invalid">
                  {errorData.file}
                </Form.Control.Feedback>
                <div className="photodet">
                  <p>Supported format: PNG, SVG, JPG</p>
                  <p>Maximum size: 25 MB</p>
                </div>
                <div className="paragraph-text">
                  <p>
                    Please upload your establishment proof to verify your practice
                    location.
                  </p>
                </div>
              </div>
              <div className="button-container">
                <Button
                  className="back-button"
                  onClick={() => history.push("/register/med-register-proof")} // Navigate to MedRegisterProof component
                >
                  <BackIcon /> Back
                </Button>
                <Button type="submit" className="continue-button">
                  Next
                </Button>
              </div>
            </Form>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default EstablishmentProof;
