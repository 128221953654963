import React from "react";

const upload = [
  {
    name:"Health Tips",
  },
  {
    name:"Motivational Quote",
  },
  {
    name:"Ann Mariya",
  },
];

const UploadWA = () => {
  return (
    <div className="followup">
      {upload.map((item, index) => (
      <div className="message">
        <p>Health Tips</p>
        <div className="check">
        <p>
          Status: 🩺 Daily Health Tip: Remember to drink plenty of water today!
          Staying hydrated is key to feeling your best. 💧
        </p>
        <input type="checkbox" />
      </div>
      </div>
      ))}
    </div>
  );
};

export default UploadWA;
