// EducationQualification.js
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from './Login.Styled';

const EducationQualification = () => {
  const history = useHistory();
  const [educationDetails, setEducationDetails] = useState({
    degree: '',
    college: '',
    completionYear: '',
    experience: '',
  });

  const [errorData, setErrorData] = useState({
    degree: '',
    college: '',
    completionYear: '',
    experience: '',
  });

  const handleInputChange = (e:any) => {
    const { name, value } = e.target;
    setEducationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (value.trim() !== "") {
      setErrorData((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const errors = {} as any;

    if (!educationDetails.degree.trim()) {
      errors.degree = "Please select your Degree";
    }
    if (!educationDetails.college.trim()) {
      errors.college = "Please select your College/Institute";
    }
    if (!educationDetails.completionYear.trim()) {
      errors.completionYear = "Please select Year of Completion";
    }
    if (!educationDetails.experience.trim()) {
      errors.experience = "Please select Year of Experience";
    }

    setErrorData(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    if (validateForm()) {
      history.push("/register/connect-practice");
    }
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Education Qualification</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="degree">
                <Form.Label className="form-label">Degree</Form.Label>
                <Form.Control
                  as="select"
                  name="degree"
                  value={educationDetails.degree}
                  onChange={handleInputChange}
                  isInvalid={!!errorData.degree}
                >
                  <option value="">Type & Select degree</option>
                  <option value="Degree 1">Degree 1</option>
                  <option value="Degree 2">Degree 2</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errorData.degree}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="college">
                <Form.Label className="form-label">College/Institute</Form.Label>
                <Form.Control
                  as="select"
                  name="college"
                  value={educationDetails.college}
                  onChange={handleInputChange}
                  isInvalid={!!errorData.college}
                >
                  <option value="">Type & select College/Institute</option>
                  <option value="College 1">College 1</option>
                  <option value="College 2">College 2</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errorData.college}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="completionYear">
                <Form.Label className="form-label">Year of completion</Form.Label>
                <Form.Control
                  as="select"
                  name="completionYear"
                  value={educationDetails.completionYear}
                  onChange={handleInputChange}
                  isInvalid={!!errorData.completionYear}
                >
                  <option value="">Type year of completion</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errorData.completionYear}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="experience">
                <Form.Label className="form-label">Year of experience (between 0-70 years)</Form.Label>
                <Form.Control
                  as="select"
                  name="experience"
                  value={educationDetails.experience}
                  onChange={handleInputChange}
                  isInvalid={!!errorData.experience}
                >
                  <option value="">Type year of experience</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errorData.experience}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="button-container">
                <Button className="back-button" onClick={() => history.push("medicalregistration")}>
                  <BackIcon /> Back
                </Button>
                <Button className="continue-button" type="submit">
                  Next
                </Button>
              </div>
            </Form>
            <div className="info-box">
              On RaphaCure, doctors are classified by their specializations
              according to their academic degrees. Education and years of experience
              highlight a doctor’s expertise and are among the top three factors
              patients consider when making their decision.
            </div>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default EducationQualification;
