import TableComponent from "./TableComponent";

const Clients = () => {
  return (
    <div>
      <TableComponent />
    </div>
  );
};

export default Clients;
