import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getReportOrderAPI } from "../../../../redux/slices/generic/genericService";
import { FaCheckCircle } from "react-icons/fa";
import { PatientDetStyled } from "../../../../components/PatientDetailModal/PateintsDet.styled";

const PrescriptionModal = ({
  show,
  onHide,
  prescriptionData,
  bookingId,
  patient,
}: any) => {
  const dispatch = useDispatch();
  const [bookingLogs, setBookingLogs] = useState<any>([]);
  const [showLogs, setShowLogs] = useState(false);

  const toggleLogs = () => {
    setShowLogs((prevShowLogs) => !prevShowLogs);
  };

  console.log(patient, "patient");

  function getFormattedDateFromTimestamp(timestamp: any) {
    try {
      if (isNaN(timestamp)) {
        throw new Error("Invalid timestamp: Not a number");
      }
      const date = new Date(parseInt(timestamp));
      if (date.toString() === "Invalid Date") {
        throw new Error("Invalid Date: The timestamp is not valid");
      }
      const day = date.toLocaleString("en-US", { weekday: "long" });
      const formattedDate = date.toLocaleString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      return `${formattedDate}, ${day}`;
    } catch (error: any) {
      console.error(error.message);
      return null;
    }
  }

  useEffect(() => {
    const fetchBookingLogs = async () => {
      if (!bookingId) {
        return;
      }
      const res = await dispatch(getReportOrderAPI(bookingId));
      const resJson = JSON.parse(JSON.stringify(res));
      setBookingLogs(resJson?.payload?.data);
    };
    fetchBookingLogs();
  }, [bookingId]);

  console.log(bookingLogs, "bookingLogs");
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton></Modal.Header>
      <PatientDetStyled>
        <div>
          <div className="appointment-details">
            <div className="header">
              <h2>Order ID: {bookingId}</h2>
              <div className="header-right">
                <div className="appointment-mode">
                  <span>Status :</span>{" "}
                  <span className="mode">{patient?.status}</span>
                </div>
              </div>
            </div>
            <div className="appointment-info mt-4">
              <div className="appointment-report-item">
                <label>Order Placed on:</label>
                <span>
                  {patient?.collection_1_date
                    ? getFormattedDateFromTimestamp(patient.collection_1_date)
                    : "N/A"}
                </span>
              </div>
              <div className="appointment-report-item">
                <span>
                  {bookingLogs?.bookingLogs?.length > 0 ? (
                    bookingLogs?.bookingLogs?.map(
                      (item: any, index: number) => (
                        <div key={index}>
                          <FaCheckCircle
                            color="#45A834"
                            className="icon me-2"
                          />
                          <span className="mb-0 ml-2">{item}</span>
                        </div>
                      )
                    )
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
              </div>
              <div className="appointment-report-item">
                <label>Order Date:</label>

                {bookingLogs?.bookingComments?.map(
                  (item: any, index: number) => {
                    return (
                      <span>
                        {patient?.collection_1_date
                          ? getFormattedDateFromTimestamp(
                              patient?.collection_1_date
                            )
                          : "N/A"}
                      </span>
                    );
                  }
                )}
              </div>
              <div className="appointment-report-item">
                <label onClick={toggleLogs} style={{ cursor: "pointer" }}>
                  {`Communication Logs (${bookingLogs?.communicationLogs?.length}) : `}
                </label>

                {showLogs && (
                  <div>
                    {bookingLogs?.communicationLogs?.length > 0 ? (
                      bookingLogs.communicationLogs.map(
                        (item: any, index: number) => (
                          <div key={index}>
                            <FaCheckCircle
                              color="#45A834"
                              className="icon me-2"
                            />
                            <span className="mb-0 ml-2">
                              email sent to {item?.to} for {item?.template_id}{" "}
                              at {item?.created_at}
                            </span>
                          </div>
                        )
                      )
                    ) : (
                      <p>N/A</p>
                    )}
                  </div>
                )}
              </div>
              <div className="appointment-report-item">
                <label>Payment Mode:</label>
                <span>{"Offline" || "N/A"}</span>
              </div>
              <div className="appointment-report-item">
                <label>Customer</label>
                <br />
                <label>Bill To :</label>
                <span>
                  {patient?.first_name || patient?.name} {patient?.last_name}
                  {patient?.email} {patient?.phone}
                </span>{" "}
                <br />
                <label>Relation :</label>
                <span>{patient?.Relation || "N/A"}</span>
                <br />
                <label>Gender :</label>
                <span>{patient?.gender || "N/A"}</span>
                <br />
                <label>Age :</label>
                <span>{patient?.age || "N/A"}</span>
              </div>
              <div className="appointment-report-item">
                <label>Client :</label>
                <span>{patient?.client?.name || "N/A"}</span>
              </div>
              <div className="appointment-report-item">
                <label>Address :</label>
                <span>{patient?.address || "N/A"}</span>
              </div>
            </div>
          </div>
        </div>
      </PatientDetStyled>
    </Modal>
  );
};

export default PrescriptionModal;
