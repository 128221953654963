// Example for IdentityProof.tsx
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from './Login.Styled';
import { useHistory } from "react-router-dom";

const IdentityProof = () => {
  const [identityProof, setIdentityProof] = useState('');
  const [errorData, setErrorData] = useState('');
  const history = useHistory();

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setIdentityProof(value);
    setErrorData(''); // Clear error when user selects a valid option
  };

  const validateForm = () => {
    if (!identityProof) {
      setErrorData("Please select an identity proof");
      return false;
    }
    return true;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validateForm()) {
      history.push("/register/med-register-proof"); // Navigate to MedRegisterProof component
    }
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Identity Proof</h2>
            <p>Dr. Jerome Bell</p>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className="form-label">
                  Please select your identity proof
                </Form.Label>
                <div className="identity-proof-options">
                  <Form.Check
                    inline
                    type="radio"
                    label="Aadhar Card"
                    value="Aadhar Card"
                    name="identityProof"
                    onChange={handleInputChange}
                    isInvalid={!!errorData}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Driving License"
                    value="Driving License"
                    name="identityProof"
                    onChange={handleInputChange}
                    isInvalid={!!errorData}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Voter ID"
                    value="Voter ID"
                    name="identityProof"
                    onChange={handleInputChange}
                    isInvalid={!!errorData}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Any other Govt. ID"
                    value="Any other Govt. ID"
                    name="identityProof"
                    onChange={handleInputChange}
                    isInvalid={!!errorData}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData}
                  </Form.Control.Feedback>
                </div>
                <div className="upload-photo">
                  <div className="widthset">
                    <div className="frontphoto">
                      <h6>Front</h6>
                      <button className="updbtn">Upload</button>
                    </div>
                    <p className="support">Supported format: PNG, SVG, JPG</p>
                  </div>
                  <div className="widthset">
                    <div className="backphoto">
                      <h6>Back</h6>
                      <button className="updbtn">Upload</button>
                    </div>
                    <p className="maxi">Maximum size: 25 MB</p>
                  </div>
                </div>
                <div className="paragraph-text">
                  <p>
                    Please upload your identity proof to ensure that the ownership
                    of your profile remains with only you.
                  </p>
                  <p>
                    Ensure all text and images are clear, legible, and readable (no
                    blurriness or pixelation). Provide a clear document title and
                    description.
                  </p>
                </div>
              </Form.Group>
              <div className="button-container">
                <Button
                  type="button"
                  onClick={() => history.push("/register/progress-summary")} // Navigate to ProgressSummary component
                  className="back-button"
                >
                  <BackIcon /> Back
                </Button>
                <Button
                  type="submit"
                  className="continue-button"
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>

          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default IdentityProof;
