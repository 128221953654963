import styled from "styled-components";

export const SignUpCompStyled = styled.div`
  .signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background: linear-gradient(135deg, #6b00ff 0%, #b82cff 100%); */
  }

  .signup-box {
    /* background-color: #ffffff20; */
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    /* max-width: 400px; */
  }

  .signup-box h2 {
    margin-bottom: 20px;
    color: #fff;
    text-align: left;
  }

  .signup-box p {
    color: #fff;
    margin-bottom: 30px;
    text-align: left;
  }

  .signup-box form {
    margin-bottom: 20px;
  }

  .signup-box label {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    color: #fff;
  }

  .signup-box input[type="text"],
  .signup-box input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* background-color: #ffffff80; */
    color: #4f4f4f;
  }

  .otp-text {
    color: #fff;
    text-align: left;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .signup-box button[type="button"] {
    width: 100%;
    padding: 10px;
    background-color: #f2f2f2;
    border: none;
    border-radius: 5px;
    color: #4f4f4f;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .login-text {
    text-align: center;
    margin-bottom: 20px;
  }

  .login-text p {
    color: #fff;
  }

  .login-text a {
    color: #ffa500;
    text-decoration: none;
  }

  .or-divider {
    margin: 20px 0;
    color: #ffffff80;
    font-size: 14px;
    position: relative;
  }

  .or-divider span {
    background-color: #ffffff20;
    padding: 0 10px;
    position: relative;
    z-index: 2;
  }

  .or-divider::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ccc;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
  }

  .social-login {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .social-login button {
    padding: 10px;
    border: none;
    color: white;
    background: #5b38c5;
    border: 1px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  @media (max-width: 760px) {
    .signup-box {
      padding: 20px;
    }

    .social-login {
      flex-direction: column;
      gap: 10px;
    }
  }
`;
