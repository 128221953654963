import React, { useState } from "react";
import { ReactComponent as Searchbar } from "../../../assets/icons/search.svg";

const allMedicines = [
  {
    name: "Glocomids 500 Mg",
    pieces: "10 Pieces",
    price: "₹ 200.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Branded",
  },
  {
    name: "Generic Medicine",
    pieces: "10 Pieces",
    price: "₹ 100.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Generic",
  },
  {
    name: "Ayurveda Medicine",
    pieces: "10 Pieces",
    price: "₹ 150.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Ayurveda",
  },
  {
    name: "Homeopathy Medicine",
    pieces: "10 Pieces",
    price: "₹ 120.00",
    imageUrl: "path-to-placeholder-image.jpg",
    category: "Homeopathy",
  },
  
];

const LabtestDetails = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e:any) => {
    setSearchTerm(e.target.value);
  };

  const filteredMedicines = allMedicines.filter((medicine) =>
    medicine.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="pharmacy-det">
      <div className="search-bar-categories">
        <div className="search-barcat">
          <div className="input-container">
            <span className="search-icon">
              <Searchbar />
            </span>
            <input
              type="text"
              placeholder="Search for Blood Bank"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      <div className="medicine-grid">
        {filteredMedicines.map((medicine, index) => (
          <div key={index} className="medicine-card">
            <img src={medicine.imageUrl} alt={medicine.name} />
            <h3>{medicine.name}</h3>
            <p>{medicine.pieces}</p>
            <div className="priceandbtn">
              <p>{medicine.price}</p>
              <button>Add</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LabtestDetails;
