import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOrderClientsAPI } from "../../../redux/slices/orders/orderService";
import { NewOrderStyled } from "./NewOrder.styled";
import Centre from "../../Centre/Centre";

const NewOrder = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showCenterPage, setShowCenterPage] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  useEffect(() => {
    const getOrderClients = async () => {
      const res = await dispatch(getOrderClientsAPI());
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("clients : ", resJson.payload.data.clients);
      setClients(resJson.payload.data.clients);
    };
    getOrderClients();
  }, [dispatch]);

  const handleClientChange = (e) => {
    const clientId = e.target.value;
    const client = clients.find((client) => client.id === clientId);
    setSelectedClient(client);
    console.log("client : ", client);
  };

  const handleViewDetails = (packageId) => {
    alert(`View details for package: ${packageId}`);
  };

  const handleAddNow = (packageId) => {
    // history.push(`/centre/${packageId}`);
    setSelectedPackage(packageId);
    setShowCenterPage(true);
  };

  return (
    <NewOrderStyled>
    
      {!showCenterPage ? (
        
        <div className="container mt-4">

            
          <div className="d-flex justify-content-start align-items-center mb-3">
            <select
              className="form-select w-25 shadow-none"
              onChange={handleClientChange}
              defaultValue=""
            >
              <option value="" disabled>
                Select a client
              </option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>

          {selectedClient && (



            
            <div className="client-packages ">
                 <div className="mb-4">
      
       <span
                onClick={() => {
                 history.push("/Orders")
                }}
                className=" text-xl cursor-pointer top-category-sec font-bold mx-2 back-btn-sec opacity-75 "
              >
                <svg
                  width="21"
                  height="18"
                  viewBox="0 0 21 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6875 7.7152H4.12125L8.88563 2.10999C9.10841 1.84745 9.21559 1.50898 9.18359 1.16903C9.15159 0.829086 8.98304 0.515513 8.715 0.297298C8.44697 0.0790819 8.10141 -0.0259025 7.75435 0.00544001C7.40729 0.0367826 7.08716 0.201885 6.86437 0.464426L0.301875 8.17802C0.257723 8.23937 0.218242 8.30383 0.18375 8.37086C0.18375 8.43514 0.18375 8.47371 0.0918752 8.53799C0.0323842 8.68539 0.00123522 8.8423 0 9.0008C0.00123522 9.1593 0.0323842 9.31621 0.0918752 9.46362C0.0918752 9.5279 0.0918748 9.56647 0.18375 9.63075C0.218242 9.69778 0.257723 9.76223 0.301875 9.82359L6.86437 17.5372C6.98778 17.6823 7.14231 17.799 7.31699 17.879C7.49166 17.959 7.68218 18.0003 7.875 18C8.18167 18.0006 8.47887 17.896 8.715 17.7043C8.8479 17.5964 8.95776 17.4638 9.03829 17.3143C9.11881 17.1647 9.16842 17.001 9.18427 16.8326C9.20012 16.6643 9.1819 16.4945 9.13065 16.333C9.0794 16.1715 8.99614 16.0215 8.88563 15.8916L4.12125 10.2864H19.6875C20.0356 10.2864 20.3694 10.151 20.6156 9.90986C20.8617 9.66876 21 9.34177 21 9.0008C21 8.65984 20.8617 8.33284 20.6156 8.09175C20.3694 7.85065 20.0356 7.7152 19.6875 7.7152Z"
                    fill="#444444"
                  />
                </svg>{" "}
                &nbsp;
                {` Back`}
              </span>
     </div>
              <h5>Agreed Packages for {selectedClient.name}</h5>
              <div className="row">
                {selectedClient.packages.map((pkg) => (
                  <div className="col-md-4 mb-4" key={pkg.service_code}>
                    <div className="card package-card h-100">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h6 className="card-title">{pkg.service_name}</h6>
                            <p className="card-text">Complete Checkup</p>
                            <p className="card-text">53 Tests Included</p>
                            <p className="price">
                              ₹ {pkg.price.discounted_price.toFixed(2)}
                            </p>
                          </div>
                          <img
                            src={pkg.image || "https://via.placeholder.com/150"}
                            className="package-image"
                            alt={pkg.service_name}
                          />
                        </div>
                        <div className="mt-3">
                          {/* <button
                            className="btn btn-outline-primary me-2"
                            onClick={() => handleViewDetails(pkg.service_code)}
                          >
                            View Details
                          </button> */}
                          <button
                            className="btn btn-primary"
                            onClick={() => handleAddNow(pkg.service_code)}
                          >
                            Add Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <span
            onClick={() => {
              setShowCenterPage(null);
            }}
            className=" text-xl cursor-pointer top-category-sec font-bold mx-2 back-btn-sec opacity-75 "
          >
            <svg
              width="21"
              height="18"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.6875 7.7152H4.12125L8.88563 2.10999C9.10841 1.84745 9.21559 1.50898 9.18359 1.16903C9.15159 0.829086 8.98304 0.515513 8.715 0.297298C8.44697 0.0790819 8.10141 -0.0259025 7.75435 0.00544001C7.40729 0.0367826 7.08716 0.201885 6.86437 0.464426L0.301875 8.17802C0.257723 8.23937 0.218242 8.30383 0.18375 8.37086C0.18375 8.43514 0.18375 8.47371 0.0918752 8.53799C0.0323842 8.68539 0.00123522 8.8423 0 9.0008C0.00123522 9.1593 0.0323842 9.31621 0.0918752 9.46362C0.0918752 9.5279 0.0918748 9.56647 0.18375 9.63075C0.218242 9.69778 0.257723 9.76223 0.301875 9.82359L6.86437 17.5372C6.98778 17.6823 7.14231 17.799 7.31699 17.879C7.49166 17.959 7.68218 18.0003 7.875 18C8.18167 18.0006 8.47887 17.896 8.715 17.7043C8.8479 17.5964 8.95776 17.4638 9.03829 17.3143C9.11881 17.1647 9.16842 17.001 9.18427 16.8326C9.20012 16.6643 9.1819 16.4945 9.13065 16.333C9.0794 16.1715 8.99614 16.0215 8.88563 15.8916L4.12125 10.2864H19.6875C20.0356 10.2864 20.3694 10.151 20.6156 9.90986C20.8617 9.66876 21 9.34177 21 9.0008C21 8.65984 20.8617 8.33284 20.6156 8.09175C20.3694 7.85065 20.0356 7.7152 19.6875 7.7152Z"
                fill="#444444"
              />
            </svg>{" "}
            &nbsp;
            {` Back`}
          </span>
          <Centre id={selectedPackage} clientId={selectedClient?.id} />
        </>
      )}
    </NewOrderStyled>
  );
};

export default NewOrder;
