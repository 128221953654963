import React, { useState, forwardRef, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
// import Button from "react-bootstrap/Button";
import { ReactComponent as Report } from "../../assets/icons/report.svg";
import { ReactComponent as Dots } from "../../assets/icons/threedots.svg";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Call } from "../../assets/icons/call.svg";
import { ReactComponent as Whatsapp } from "../../assets/icons/whatsapp.svg";
import { ReactComponent as Mail } from "../../assets/icons/mail.svg";
import PatientDetailModal from "../../components/PatientDetailModal/Patientmodal";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientsAPI } from "../../redux/slices/myPatients/myPatientsService";

interface CustomToggleProps {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CustomToggle = forwardRef<HTMLButtonElement, CustomToggleProps>(
  ({ children, onClick }, ref) => (
    <button
      type="button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn btn-link p-0"
    >
      {children}
    </button>
  )
);

const PatientsTable = () => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [order, setOrder] = useState([]);
  const dispatch = useDispatch();
  const { patientsList } = useSelector(
    (ReduxState: any) => ReduxState.myPatients
  );

  useEffect(() => {
    if (patientsList) {
      const mappedOrder = patientsList?.patients?.map((patient: any) => ({
        serialno: `#${patient.id}`,
        name: `${patient.first_name} ${patient.last_name}`,
        age: patient.age,
        mobilenum: patient.phone,
        email: patient.email,
        gender:
          patient.gender.charAt(0).toUpperCase() + patient.gender.slice(1), // Capitalizing the first letter
        city: "Bangalore", // Assuming static city value as it's not provided
        pincode: "560098", // Assuming static pincode value as it's not provided
      }));
      setOrder(mappedOrder);
    }
  }, [patientsList]);

  useEffect(() => {
    dispatch(fetchPatientsAPI());
  }, [dispatch]);

  const handleShowModal = (patient: any) => {
    setSelectedPatient(patient);
    setModalShow(true);
  };

  const handleEdit = (patient: any) => {
    console.log("Edit", patient);
  };

  const handleDelete = (patient: any) => {
    console.log("Delete", patient);
  };

  return (
    <div className="patient-table">
      <Table responsive="sm">
        <thead>
          <tr>
            <th>Serial No</th>
            <th>Name</th>
            <th>Age</th>
            <th>Mobile Number</th>
            <th>Email Id</th>
            <th>Gender</th>
            <th>City</th>
            <th>Pin Code</th>
            <th className="action-report">Report</th>
            <th className="action-report">Actions</th>
          </tr>
        </thead>
        <tbody>
          {order?.map((item: any, index: any) => (
            <tr key={index}>
              <td>{item.serialno} </td>
              <td>
                <button
                  className="btn btn-link"
                  onClick={() => handleShowModal(item)}
                >
                  {item.name}
                </button>
              </td>
              <td>{item.age} </td>
              <td>{item.mobilenum} </td>
              <td>{item.email} </td>
              <td>{item.gender} </td>
              <td>{item.city}</td>
              <td>{item.pincode}</td>
              <td className="action-report">
                <Report />
              </td>
              <td className="actions">
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle}>
                    <Share />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <button className="whatsappbtn">
                        <Whatsapp /> WhatsApp
                      </button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <button className="mailbtn">
                        <Mail /> Send Email
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <span className="icon-space"></span>
                <Call />
                <span className="icon-space"></span>
                <Download />
                <span className="icon-space"></span>
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle}>
                    <Dots />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleEdit(item)}
                      className="edit-text"
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleDelete(item)}
                      className="edit-text"
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="pagination">
        <Pagination className="custom-pagination">
          <Pagination.First />
          <Pagination.Prev />
          <Pagination.Item active>{1}</Pagination.Item>

          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Item>{3}</Pagination.Item>

          <Pagination.Item>{4}</Pagination.Item>
          <Pagination.Next />
          <Pagination.Last />
        </Pagination>
      </div>

      {selectedPatient && (
        <PatientDetailModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          patient={selectedPatient}
        />
      )}
    </div>
  );
};

export default PatientsTable;
