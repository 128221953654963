import React from "react";
import { LoginStyled } from "./Login.Styled";
import ProfileDetails from "./ProfileDetails";
import MedicalRegistration from "./MedicalRegistration";
import EducationQualification from "./EducationQualification";
import ConnectPractice from "./ConnectPractice";
import EstablishmentDetails from "./EstablishmentDetails";
import ProgressSummary from "./ProgressSummary";
import IdentityProof from "./IdentityProof";
import MedRegisterProof from "./MedRegisterProof";
import EstablishmentProof from "./EstablishmentProof";
import MapLocation from "./MapLocation";
import ProgressSummarytwo from "./ProgressSummarytwo";
import ConsultantDoctor from "./ConsultantDoctor";
import BankAccount from "./BankAccount";
import VerificationProfile from "./VerificationProfile";

const Login = (props: any) => {
  const { component } = props.match?.params;
  const renderContent = () => {
    switch (component) {
      case "profile-details":
        return <ProfileDetails />;
      case "medicalregistration":
        return <MedicalRegistration />;
      case "education-qualification":
        return <EducationQualification />;
      case "connect-practice":
        return <ConnectPractice />;
      case "establishment-details":
        return <EstablishmentDetails />;
      case "progress-summary":
        return <ProgressSummary />;
      case "identity-proof":
        return <IdentityProof />;
      case "med-register-proof":
        return <MedRegisterProof />;
      case "establishment-proof":
        return <EstablishmentProof />;
      case "map-location":
        return <MapLocation />;
      case "progress-summary-two":
        return <ProgressSummarytwo />;
      case "consultant-doctor":
        return <ConsultantDoctor />;
      case "bank-account":
        return <BankAccount />;
      case "verification-profile":
        return <VerificationProfile />;
      default:
        return <h1>Coming Soon</h1>;
    }
  };
  return (
    <div className="Login-page">
      <LoginStyled>{renderContent()}</LoginStyled>
    </div>
  );
};

export default Login;
