import React from "react";
import { CreateOrderStyled } from "./CreateOrder.Styled"
import Createsearch from "./Createsearch";
import OrderCategories from "./Categories";


const CreateOrders = () => {
    return (
      <CreateOrderStyled>
        <div className="order-page">
          <Createsearch />
          <OrderCategories />
        </div>
      </CreateOrderStyled>
    );
  };
  
  export default CreateOrders;